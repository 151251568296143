module.exports = {
  baseUrl: "https://int-api-aqua-quickship-dev.azure-api.net/v2",
  SubscriptionKey: "",
  tokenApi:
    "https://int-api-aqua-quickship-dev.azure-api.net/oauth/authorize/getinttoken",
  client_id: "708508bd-4ed7-4b98-a87a-de74c3d76d30",
  client_secret: "lWa8Q~DKDEtwiUwRsyFhGW5Qed~Z7yWLphu-vaYs",
};

// module.exports = {
//   baseUrl: "https://aquaship-fulfilmet.azure-api.net/v1",
//   SubscriptionKey: "db198db751d6489785b51c7929f54e22",
// };
// module.exports = {
//   baseUrl: "https://getsalesorderapi.azurewebsites.net",
//   SubscriptionKey: "12feafbc473d4185b2ad2c4cd653f260",
// };;
