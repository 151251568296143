import React from "react";
import { useState, useEffect, useRef } from "react";
import Button from "@material-ui/core/Button";
import toast from "react-hot-toast";
import {
    baseUrl,
    SubscriptionKey,
    client_id,
    client_secret,
    tokenApi,
} from "../config";
import "./style.css";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Pagination from "@material-ui/lab/Pagination";
import { useHistory, useParams } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import Multiselect from "multiselect-react-dropdown";
import { makeStyles } from "@material-ui/core";
import axios from "axios";
import { DownloadOrderLists } from "../../../dataservices/action/marketplace/marketplace";
import { Loadermessage, CreateinitialLoadermessage } from "../../../dataservices/model/loadermessage";
import Loader from "../../loader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'
import { event } from "jquery";
const useStyles = makeStyles({
    paper: {
        background: "#FFFF",
    },
});

const label = { inputProps: { "aria-label": "Checkbox demo" } };

interface OrderProps {
    pageNumber: number;
}

const Order: React.FC<OrderProps> = ({ pageNumber }) => {
    const classes = useStyles();
    const [warehouseList, setWarehouseList] = useState([
        { value: "", title: "" },
    ]);
    const [status, setStatusList] = useState([{ value: "", title: "" }]);
    const [customerCodeList, setCustomerCodeList] = useState([
        { value: "", title: "" },
    ]);
    const [partnerList, setPartnerList] = useState([
        { value: "", title: ""},
    ]);
    const [customerOrderIdList, setCustomerOrderIdList] = useState([]);
    const [warehouse, setWarehouse] = useState("");
    const [customerOrderId, setCustomerOrderId] = useState({
        title: "",
        value: "",
    });
    const { OLT }: any = useParams();
    const [customerCode, setCustomerCode] = useState("");
    const [statusCode, setStatusCode] = useState("");
    const [partner, setPartner] = useState("");
    const [startDate, setStartDate] = useState<any>("");
    const [endDate, setEndDate] = useState<any>("");
    const [statusStartDate, setStatusStartDate] = useState<any>("");
    const [statusEndDate, setStatusEndDate] = useState<any>("");
    const [startDateErr, setStartDateErr] = useState<any>(false);
    const [endDateErr, setEndDateErr] = useState<any>(false);
    const [statusStartDateErr, setStatusStartDateErr] = useState<any>(false);
    const [statusEndDateErr, setStatusEndDateErr] = useState<any>(false)
    const [lastDays, setLastDays] = useState("none");
    const [jsonData, setJsonData] = useState<any>([]);
    const [pageSize, setPageSize] = useState(0);
    const [checkAll, setCheckAll] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecordCount, setTotalRecordCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [nodatafound, setNodataFound] = useState(false);
    const [clear, setClear] = useState(false);
    const [isloader, Setisloader] = useState(CreateinitialLoadermessage());
    const inputFile: any = useRef();
    const [PaginationProps, setPaginationProps] = useState<any>({
        start: 0,
        end: 0,
    });
    const [arrowDirection, setArrowDirection] = useState(true);
    const [selectedHeader, setSelectedHeader] = useState("");
    const [filename, setFilename] = useState();
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedOrderId, setSelectedOrderId] = useState("");
    const navigate = useHistory();
    function formatDate(date: any) {
        var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [month, day, year].join("-");
    }

    const handleStartDate = (e: any) => {
        setLastDays("");
        setStartDate(e.target.value);
    };
    const handleEndDate = (e: any) => {
        setLastDays("");
        setEndDate(e.target.value);
    };
    const handleStatusStartDate = (e: any) => {
        setLastDays("");
        setStatusStartDate(e.target.value);
    };
    const handleStatusEndDate = (e: any) => {
        setLastDays("");
        setStatusEndDate(e.target.value);
    };
    const HandleDateValidate = (e: any, type: any) => {
        if (type === "start") {
            if (new Date(e.target.value) > new Date()) {
                setStartDateErr(true);
            } else {
                setStartDateErr(false);
            }
        }
        if (type === "end") {
            if (new Date(e.target.value) > new Date()) {
                setEndDateErr(true);
            } else {
                setEndDateErr(false);
            }
        }
    };

    const HandleStatusDateValidate = (e: any, type: any) => {
        if (type === "start") {
            if (new Date(e.target.value) > new Date()) {
                setStatusStartDateErr(true);
            } else {
                setStatusStartDateErr(false);
            }
        }
        if (type === "end") {
            if (new Date(e.target.value) > new Date()) {
                setStatusEndDateErr(true);
            } else {
                setStatusEndDateErr(false);
            }
        }
    };

    const handleClickDetails = (item: any) => {
        let date = item.transactionDate.split(" ");
        let mydata = {
            transactionId: item.transactionId,
            date: date[0],
            path: "",
        };
        localStorage.setItem("orderItem", JSON.stringify(mydata));

        let orderdetailsAPIfun = "";

        if (OLT == "F") {
            navigate.push("/orderdetails/F");

        }
        else {
            navigate.push("/orderdetails/D");

        }

        // navigate.push("/orderdetails/" + accid);
    };
    // const handleClickTrack = (item: any) => {
    //   let date = item.transactionDate.split(" ");
    //   let mydata = {
    //     transactionId: item.transactionId,
    //     date: date[0],
    //     path: "track",
    //   };
    //   localStorage.setItem("orderItem", JSON.stringify(mydata));
    //   // navigate.push("/orderdetails/" + accid);
    //   navigate.push("/orderdetails");
    // };

    const handleChangeWarehouse = (event: any) => {
        setWarehouse(event.target.value);
    };

    const handleChangeYourOrder = (event: any) => {
        setCustomerOrderId({
            title: event.target.value,
            value: event.target.value,
        });
        if (event.target.value.length > 2) {
            const headers = {
                "Content-Type": "application/json",
                Authorization: `${localStorage.getItem("Authorization")}`,
            };
            axios
                .get(`${baseUrl}/GetCustomerOrderId?searchText=${event.target.value}`, {
                    headers,
                })
                .then((response) => {
                    let orders: any = [];
                    response.data.map((x: any) => {
                        orders.push({
                            title: x.customerOrderId,
                            value: x.customerOrderId,
                        });
                    });
                    setCustomerOrderIdList(orders);
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        } else {
            setCustomerOrderIdList([]);
        }
    };
    const handleChangeOrderId = (event: any, value: any) => {
        if (value === null) {
            setCustomerOrderId({
                title: "",
                value: "",
            });
        }
        setCustomerOrderId(value);
    };

    const handleChangeCustomerCode = (event: any) => {
        setCustomerCode(event.target.value);
    };

    const handleChangeLastDays = (event: any) => {
        setLastDays(event.target.value);
        if (event.target.value === "7") {
            let date = new Date();
            date.setDate(date.getDate() - 7);
            setStartDate(formatDate(date));
            setEndDate(formatDate(new Date()));
        }
        if (event.target.value === "30") {
            let date = new Date();
            date.setDate(date.getDate() - 30);
            setStartDate(formatDate(date));
            setEndDate(formatDate(new Date()));
        }
        if (event.target.value === "90") {
            let date = new Date();
            date.setDate(date.getDate() - 90);
            setStartDate(formatDate(date));
            setEndDate(formatDate(new Date()));
        }
        if (event.target.value === "none") {
            setStartDate("");
            setEndDate("");
        }
    };

    const handlePageChange = (data: any, page: number) => {
        getOrderlistApi(page);
    };

    const initialApis = () => {
        const headers = {
            "Content-Type": "application/json",
            Authorization: `${localStorage.getItem("Authorization")}`,
        };
        axios
            .get(`${baseUrl}/GetOrderSatus`, { headers })
            .then((response) => {
                let status: any = [];
                response.data.map((x: any) => {
                    status.push({
                        title: x.statusDescription,
                        value: x.statusCode,
                    });
                    setStatusList(status);
                });
            })
            .catch((error) => {
                console.error("Error:", error);
            });

        let orderwarehouse = "";
        if (OLT == "F") {
            orderwarehouse = "GetWarehouse"

        }
        else {
            orderwarehouse = "GetNFWarehouse"

        }

        axios
            .get(`${baseUrl}/${orderwarehouse}`, { headers })
            .then((response) => {
                let ware: any = [];
                response.data.map((x: any) => {
                    if (ware.find((y: any) => y.title === x.wareHouseId)) {
                    } else {
                        ware.push({
                            title: x.wareHouseId,
                            value: x.wareHouseId,
                        });
                    }
                });
                setWarehouseList(ware);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
        let customercode = "";
        if (OLT == "F") {
            customercode = "GetCustomerCode"

        }
        else {
            customercode = "GetNFCustomerCode"

        }

        axios
            .get(`${baseUrl}/${customercode}`, { headers })
            .then((response) => {
                let cust: any = [];
                response.data.map((x: any) => {
                    cust.push({
                        title: x.customerCode,
                        value: x.customerCode,
                    });
                    setCustomerCodeList(cust);
                });
            })
            .catch((error) => {
                console.error("Error:", error);
            });

        let partner = "GetPartnerType"

        axios
            .get(`${baseUrl}/${partner}`, { headers })
            .then((response) => {
                let part: any = [];
                response.data.map((x: any) => {
                   
                    part.push({
                        title: x.partnerType,
                        value: x.partnerType,
                    });
                    setPartnerList(part);
                });
            })
            .catch((error) => {
                console.error("Error:", error);
            });


    };

    function getOrderlistApi(page: any) {
        let token = localStorage.getItem("Authorization");
        //console.log("token....", token)
        //console.log(OLT);
        setCurrentPage(page);
        setLoading(true);
        let transactionDate = "";
        if (startDate && endDate) {
            transactionDate = formatDate(startDate) + "," + formatDate(endDate);
        } else {
            transactionDate = "";
        }

        let statusDateRange = "";
        if (statusStartDate && statusEndDate) {
            statusDateRange = formatDate(statusStartDate) + "," + formatDate(statusEndDate);
        } else {
            statusDateRange = "";
        }
        let orderlistingAPIfun = "";
        if (OLT == "F") {
            orderlistingAPIfun = "GetSalesOrdersData"
        }
        else {
            orderlistingAPIfun = "GetNFSalesOrdersData"
        }

        const headers = {
            "Content-Type": "application/json",
            Authorization: `${localStorage.getItem("Authorization")}`,
        };
        console.log("baseUrl....", baseUrl + "/" + orderlistingAPIfun + "?pageNum=" + page + "&customerCode=" + customerCode + "&wareHouseId=" + warehouse + "&customerOrderId=" + customerOrderId.value + "&DateRange=" + transactionDate + "&OrderStatusCode=" + statusCode + "&StatusDateRange=" + statusDateRange + "&orderbycolumn=" + selectedHeader);
        axios
            .get(
                `${baseUrl}/${orderlistingAPIfun}?pageNum=${page}&customerCode=${customerCode}&wareHouseId=${warehouse}&customerOrderId=${customerOrderId ? customerOrderId.value : ""
                }&DateRange=${transactionDate}&OrderStatusCode=${statusCode}&StatusDateRange=${statusDateRange}&orderbycolumn=${selectedHeader}&PartnerType=${partner}`,
                { headers }
            )
            .then((response) => {
                if (response.data.salesOrder.length === 0) {
                    setNodataFound(true);
                } else {
                    setNodataFound(false);
                }
                setLoading(false);
                var merged: any[] = [];
                response.data.salesOrder.forEach((x: any) => {
                    merged.push({ ...x, isChecked: false });
                });
                setJsonData(merged);
                console.log("setJsonData....", merged)
                setPageSize(parseInt(response.data.pageSize));
                setTotalRecordCount(parseInt(response.data.totalRecordCount));
            })
            .catch((error) => {
                setLoading(false);
                if (error.response.status !== 200) {
                    setJsonData([]);
                    setNodataFound(true);
                    // toast.error(error.response.data.errorMessage, { duration: 50000000 });
                    if (error.response.data.errorMessage.slice(0, 5) === "DateR") {
                        toast.error(
                            "Invalid date format. Please use the format MM-DD-YYYY for Start Date and End Date",
                            { duration: 50000000 }
                        );
                    }
                }
            });
    }
    const handleCheckAll = (value: any) => {
        // e.stopPropagation();
        setCheckAll(value);
        var merged: any[] = [];
        jsonData.forEach((x: any) => {
            merged.push({ ...x, isChecked: value });
        });
        setJsonData(merged);
        var checkedItems = merged.filter((item: any) => item.isChecked == true)
        //console.log("checkedItems....", checkedItems)
        const orderIdsArr = checkedItems.map((obj: any) => obj.customerOrderId);
        const result = orderIdsArr.join(',');
        setSelectedOrderId(result);
        // console.log("allselect....",result)
    };

    const handleSearch = () => {
        if (!startDateErr && !endDateErr) {
            getOrderlistApi(1);
        }
    };

    useEffect(() => {
        axios.post("api/MP/gettoken").then((response: any) => {
            console.log(response);
            localStorage.setItem("Authorization", response.data);
            getOrderlistApi(currentPage);
            initialApis();
        });
    }, []);
    function formatAMPM(date: any) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? "PM" : "AM";
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? "0" + minutes : minutes;
        var strTime = hours + ":" + minutes + " " + ampm;
        return strTime;
    }

    const dateConverter = (x: any) => {
        var today = new Date(x);
        var month = today.toLocaleString("default", { month: "short" });
        let time = formatAMPM(today);
        let date = `${month} ${today.getDate()}, ${today.getFullYear()} ${time}`;
        return date;
    };
    const ShippingDate = (x: any) => {
        var today = new Date(x);
        var month = today.toLocaleString("default", { month: "short" });
        let date = `${month} ${today.getDate()}, ${today.getFullYear()}`;
        return date;
    };

    const findstatus = (item: any) => {
        if (item) {
            let result: any = status.find((x) => x.value === item);
            return result.title;
        }
    };
    const handleClear = () => {
        setStartDateErr(false);
        setEndDateErr(false);
        setWarehouse("");
        setCustomerCode("");
        setCustomerOrderId({
            title: "",
            value: "",
        });
        setStartDate("");
        setEndDate("");
        setLastDays("");
        setStatusCode("");
        setClear(true);
        setCheckAll(false);
        window.location.reload();
    };
    useEffect(() => {
        if (clear === true) {
            getOrderlistApi(1);
            setClear(false);
        }
    }, [clear]);

    const handleChangeStatus = (event: any) => {
        setStatusCode(event.target.value);
    };

    const handleChangeCheckbox = (e: any, index: any) => {
        let arr = jsonData;
        arr[index].isChecked = e.target.checked;
        setJsonData([...arr]);
        var checkedItems = arr.filter((item: any) => item.isChecked == true)
        //console.log("checkedItems....", checkedItems)
        const orderIdsArr = checkedItems.map((obj: any) => obj.customerOrderId);
        const result = orderIdsArr.join(',');
        setSelectedOrderId(result);
        //console.log("orderIds....", result)
        if (e.target.checked === false) {
            setCheckAll(false);
        }
        const allValuesAreTrue = arr.every((obj: any) => obj.isChecked === true);
        if (allValuesAreTrue) {
            setCheckAll(true);
        }
    };

    const DownloadOrderList = (page: any) => {

        try {

            debugger;
            let token = localStorage.getItem("Authorization");
            let transactionDate = "";
            if (startDate && endDate) {
                transactionDate = formatDate(startDate) + "," + formatDate(endDate);
            } else {
                transactionDate = "";
            }
            let orderlistingAPIfun = "";
            if (OLT == "F") {
                orderlistingAPIfun = "GetSalesOrdersData"
            }
            else {
                orderlistingAPIfun = "GetNFSalesOrdersData"
            }

            let statusDateRange = "";
            if (statusStartDate && statusEndDate) {
                statusDateRange = formatDate(statusStartDate) + "," + formatDate(statusEndDate);
            } else {
                statusDateRange = "";
            }
            const filterobj = {
                //orderlistingAPIfun: orderlistingAPIfun,
                pageNum: page,
                customerCode: customerCode,
                wareHouseId: warehouse,
                customerOrderId: customerOrderId.value,
                DateRange: transactionDate,
                OrderStatusCode: statusCode,
                StatusDateRange: statusDateRange,
                OrderId: selectedOrderId,
                //token: token
            }
            // var hasThreeValidProperties = function (filterObj: any) {
            //     const keysToCheck = ['customerCode', 'wareHouseId', 'customerOrderId', 'DateRange', 'OrderStatusCode','StatusDateRange'];
            //     let validCount = 0;

            //     // Loop over the specified keys and check for valid (non-null and non-empty) values
            //     for (const key of keysToCheck) {
            //         if (filterObj[key] !== null && filterObj[key] !== undefined && filterObj[key] !== '') {
            //             validCount++;
            //             if (validCount >= 3) {
            //                 return true; // Return true as soon as we find at least three valid properties
            //             }
            //         }
            //     }

            //     return false; // Return false if less than three valid properties are found
            // }
            // const isValid = hasThreeValidProperties(filterobj);
            //if (isValid) {

            const data = new FormData();
            data.append('filters', JSON.stringify(filterobj));
            setLoading(true);
            const response = axios({
                method: "post",
                url: "api/MP/DownloadOrderLists",
                data: data,
                headers: { "Content-Type": "multipart/form-data" },
                responseType: 'blob',
            }).then((result) => {



                if (result != null && result.status == 200) {
                    debugger;
                    const url = window.URL.createObjectURL(new Blob([result.data]));
                    const a = document.createElement('a');
                    a.href = url;
                    a.setAttribute('download', "OrderList.csv");
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                    URL.revokeObjectURL(url);

                    setLoading(false);

                } else {
                    toast.error(
                        "No Records Found",
                        { duration: 50000000 }
                    );
                    setLoading(false);
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                }


            });
            // } else {
            //     toast.error(
            //         "Select at least three filters",
            //         { duration: 50000000 }
            //     );

            // }

        } catch (error) {
            console.log(error)
        }

    }
    const OpenFileExplore = () => {
        inputFile.current.click();
    };
    const handleFileUpload = (e: any) => {

        e.preventDefault();
        const { files } = e.target;
        if (files && files.length) {
            setFilename(files[0].name);
            setSelectedFile(files[0]);
            const formData = new FormData();
            formData.append('File', files[0]);

            setLoading(true);
            try {
                const response = axios({
                    method: "post",
                    url: "api/MP/UploadOrderFile",
                    data: formData,
                    headers: { "Content-Type": "multipart/form-data" },
                }).then(result => {
                    toast.error(
                        "File Uploaded Successfully",
                        { duration: 50000000 }
                    );
                    setLoading(false);
                    console.log(result);
                });



            } catch (error) {
                console.log(error)
            }
        }
    }

    const DownloadInvoice = (page: any) => {
        try {
            let transactionDate = "";
            if (startDate && endDate) {
                transactionDate = formatDate(startDate) + "," + formatDate(endDate);
            } else {
                transactionDate = "";
            }
            let orderlistingAPIfun = "";
            if (OLT == "F") {
                orderlistingAPIfun = "GetSalesOrdersData"
            }
            else {
                orderlistingAPIfun = "GetNFSalesOrdersData"
            }

            const filterobj = {
                orderlistingAPIfun: orderlistingAPIfun,
                pageNum: page,
                customerCode: customerCode,
                wareHouseId: warehouse,
                customerOrderId: customerOrderId.value,
                DateRange: transactionDate,
                OrderStatusCode: statusCode,
                OrderId: selectedOrderId,
                //token: token
            }
            const data = new FormData();
            data.append('filters', JSON.stringify(filterobj));
            setLoading(true);
            const response = axios({
                method: "post",
                url: "api/MP/DownloadInvoice",
                data: data,
                headers: { "Content-Type": "multipart/form-data" },
                responseType: 'blob',
            }).then((result) => {
                if (result != null && result.status == 200) {
                    debugger;
                    const url = window.URL.createObjectURL(new Blob([result.data]));
                    const a = document.createElement('a');
                    a.href = url;
                    a.setAttribute('download', "Invoice.csv");
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                    URL.revokeObjectURL(url);

                    setLoading(false);

                } else {
                    toast.error(
                        "No Records Found",
                        { duration: 50000000 }
                    );
                    setLoading(false);
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                }
                //resultmes.isLoader = false;
                //Setisloader(resultmes);
            });

        } catch (error) {
            console.log(error)

        }


    }
    const DownloadShipping = (page: any) => {
        try {
            let transactionDate = "";
            if (startDate && endDate) {
                transactionDate = formatDate(startDate) + "," + formatDate(endDate);
            } else {
                transactionDate = "";
            }
            let orderlistingAPIfun = "";
            if (OLT == "F") {
                orderlistingAPIfun = "GetSalesOrdersData"
            }
            else {
                orderlistingAPIfun = "GetNFSalesOrdersData"
            }
            const filterobj = {
                orderlistingAPIfun: orderlistingAPIfun,
                pageNum: page,
                customerCode: customerCode,
                wareHouseId: warehouse,
                customerOrderId: customerOrderId.value,
                DateRange: transactionDate,
                OrderStatusCode: statusCode,
                OrderId: selectedOrderId,
                //token: token
            }

            const data = new FormData();
            data.append('filters', JSON.stringify(filterobj));
            setLoading(true);
            const response = axios({
                method: "post",
                url: "api/MP/DownloadShipping",
                data: data,
                headers: { "Content-Type": "multipart/form-data" },
                responseType: 'blob',
            }).then((result) => {
                if (result != null && result.status == 200) {
                    debugger;
                    const url = window.URL.createObjectURL(new Blob([result.data]));
                    const a = document.createElement('a');
                    a.href = url;
                    a.setAttribute('download', "Shipping.csv");
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                    URL.revokeObjectURL(url);

                    setLoading(false);

                } else {
                    toast.error(
                        "No Records Found",
                        { duration: 50000000 }
                    );
                    setLoading(false);
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                }
                //resultmes.isLoader = false;
                //Setisloader(resultmes);
            });

        } catch (error) {
            console.log(error)

        }

    }

    // const [downloadData, setDownloadData] = useState([]);
    // const DownloadOrderList = (page: any) => {
    //   let transactionDate = "";
    //   if (startDate && endDate) {
    //     transactionDate = formatDate(startDate) + "," + formatDate(endDate);
    //   } else {
    //     transactionDate = "";
    //   }
    //   let orderlistingAPIfun = "";
    //   if (OLT == "F") {
    //     orderlistingAPIfun = "GetSalesOrdersData"
    //   }
    //   else {
    //     orderlistingAPIfun = "GetNFSalesOrdersData"
    //   }
    //   let token = localStorage.getItem("Authorization");
    //   DownloadOrderLists(orderlistingAPIfun, page, customerCode, warehouse, customerOrderId.value, transactionDate, statusCode, token, function (result: any) {
    //     debugger;
    //     //console.log(result);
    //     const url = window.URL.createObjectURL(new Blob([result.data]));
    //     const a = document.createElement('a');
    //     a.href = url;
    //     a.setAttribute('download', "ProductRule.csv");
    //     document.body.appendChild(a);
    //     a.click();
    //     a.remove();
    //     URL.revokeObjectURL(url);
    //   })






    //}

    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectedWarhouse, setSelectedWarhouse] = useState("");
    //const [statusList,setStatussList]=useState("");
    const handleSelect = (selectedList: any, selectedItem: any) => {
        // console.log("selectedList....", selectedList)
        let selectedstatus: string = selectedList.map((option: any) => option.value).join(',');
        setStatusCode(selectedstatus);
        //console.log("selectedststus..", selectedstatus)
    }

    const handleRemove = (selectedList: any, selectedItem: any) => {
        let selectedstatus: string = selectedList.map((option: any) => option.value).join(',');
        setStatusCode(selectedstatus);
        //console.log("afterremovedststus..", selectedstatus)
    }

    const handleSelectWarhouse = (selectedList: any, selectedItem: any) => {
        //console.log("selectedList....", selectedList)
        let selectedWarhouse: string = selectedList.map((option: any) => option.value).join(',');
        setWarehouse(selectedWarhouse);
        //console.log("selectedWarhouse..", selectedWarhouse)
    }

    const handleRemoveWarhouse = (selectedList: any, selectedItem: any) => {
        let selectedWarhouse: string = selectedList.map((option: any) => option.value).join(',');
        setWarehouse(selectedWarhouse);
        //console.log("afterRemovedWarhouse..", selectedWarhouse)
    }

    const handleSelectCustomerCode = (selectedList: any, selectedItem: any) => {
        //console.log("selectedList....", selectedList)
        let selectedCustomerCode: string = selectedList.map((option: any) => option.value).join(',');
        setCustomerCode(selectedCustomerCode);
        //console.log("selectedCustomerCode..", selectedCustomerCode)
    }

    const handleRemoveCustomerCode = (selectedList: any, selectedItem: any) => {
        let selectedCustomerCode: string = selectedList.map((option: any) => option.value).join(',');
        setCustomerCode(selectedCustomerCode);
        //console.log("afterRemovedCustomerCode..", selectedCustomerCode)
    }
    const handleSelectPartnerType = (selectedList: any, selectedItem: any) => {
        //console.log("selectedList....", selectedList)
        let selectedPartner: string = selectedList.map((option: any) => option.value).join(',');
        setPartner(selectedPartner);
        //console.log("selectedCustomerCode..", selectedCustomerCode)
    }
    const handleRemovePartnerType = (selectedList: any, selectedItem: any) => {
        let selectedPartner: string = selectedList.map((option: any) => option.value).join(',');
        setPartner(selectedPartner);
        //console.log("afterRemovedCustomerCode..", selectedCustomerCode)
    }


    const onRemove = (selectedList: any, removedItem: any) => {
        setSelectedOptions(selectedList);
    };

    const requestSort = (header: string) => {
        console.log("header...", header);
        setArrowDirection(!arrowDirection);

        // if(header=="orderdate"){
        //   let hname="date(`order`.CreatedDate),"+arrowDirection;
        //   setSelectedHeader(hname);

        // }else if(header=="status"){
        //   let hname="`orderacknowledgement`.StatusCode,"+arrowDirection;
        //   setSelectedHeader(hname);

        // }else{
        //   let hname="`Order`.CustomerAccountId,"+arrowDirection;
        //   setSelectedHeader(hname);
        // }
        let hname = "";
        if (header === "orderdate") {
            hname = `date,${arrowDirection}`;
        } else if (header === "status") {
            hname = `status,${arrowDirection}`;
        } else {
            hname = `customeraccount,${arrowDirection}`;
        }
        //getOrderlistApi(1);
        setSelectedHeader(hname);

    };
    // Effect to call API when selectedHeader changes
    useEffect(() => {
        if (selectedHeader !== "") {
            console.log("Calling API with header:", selectedHeader);
            getOrderlistApi(1);
        }
    }, [selectedHeader]); // Only re-run the effect if selectedHeader changes.

    const getSortDirection = () => {

        return arrowDirection ?
            <FontAwesomeIcon icon={faCaretUp} /> :
            <FontAwesomeIcon icon={faCaretDown} />;


    };


    return (
        <>
            {loading && (
                <div className="loader_orderlisting">
                    <CircularProgress style={{ color: "#000000" }} />
                </div>
            )}

            {/* <div className="row">
        <div className="col-md-12">
          <div className="mpc-menu">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/userdashboard">
                    <i className="bi bi-house-door-fill pe-1"></i>Home
                  </a>
                </li>
                <li className="breadcrumb-item" aria-current="page">
                  <span>Order Listing</span>
                </li>
                 <li className="breadcrumb-item active" aria-current="page">
                  <div className="head-accno">
                    Account No:
                    <span>{accname}</span>
                  </div>
                </li> 
              </ol>
            </nav>
            <MPSideBar id={accid} />
          </div>
        </div>
      </div> */}
            <div className="row">
                <div className="col-sm-12 col-md-12">
                    {

                        OLT == "F" ? <div className="heading-section"><h1>Fulfillment Order Listing</h1></div> : <div className="heading-section"><h1>Non Fulfillment Order Listing</h1></div>


                    }

                    <div className="orderlisting_container border-0">
                        <div className="filterItems">
                            <InputLabel>Status</InputLabel>
                            {/* <FormControl size="small" fullWidth style={{ marginTop: 10 }}>
                <Select
                  fullWidth
                  variant="outlined"
                  value={statusCode}
                  onChange={handleChangeStatus}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {Array.isArray(status) &&
                    status.map((x, i) => (
                      <MenuItem key={i} value={x.value}>
                        {x.title}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl> */}
                            {Array.isArray(status) &&

                                <Multiselect
                                    options={status}
                                    selectedValues={selectedOptions}
                                    onSelect={(selectedList, selectedItem) => handleSelect(selectedList, selectedItem)}
                                    onRemove={(selectedList, selectedItem) => handleRemove(selectedList, selectedItem)}
                                    displayValue="title"
                                />

                            }
                        </div>
                        <div className="filterItems">
                            <InputLabel>Warehouse</InputLabel>
                            {
                                Array.isArray(warehouseList) &&
                                <Multiselect
                                    options={warehouseList}
                                    //selectedValues={selectedWarhouse}
                                    onSelect={(selectedList, selectedItem) => handleSelectWarhouse(selectedList, selectedItem)}
                                    onRemove={(selectedList, selectedItem) => handleRemoveWarhouse(selectedList, selectedItem)}
                                    displayValue="title"
                                />

                            }
                            {/* <FormControl size="small" fullWidth style={{ marginTop: 10 }}>
                <Select
                  fullWidth
                  variant="outlined"
                  value={warehouse}
                  onChange={handleChangeWarehouse}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {Array.isArray(warehouseList) &&
                    warehouseList.map((x, i) => (
                      <MenuItem key={i} value={x.value}>
                        {x.title}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl> */}
                        </div>
                        <div className="filterItems">
                            <InputLabel>Customer Code</InputLabel>
                            <Multiselect
                                options={customerCodeList}
                                //selectedValues={selectedWarhouse}
                                onSelect={(selectedList, selectedItem) => handleSelectCustomerCode(selectedList, selectedItem)}
                                onRemove={(selectedList, selectedItem) => handleRemoveCustomerCode(selectedList, selectedItem)}
                                displayValue="title"
                            />
                            {/* <FormControl size="small" fullWidth style={{ marginTop: 10 }}>
                <Select
                  fullWidth
                  variant="outlined"
                  value={customerCode}
                  label="Customer Code"
                  onChange={handleChangeCustomerCode}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {customerCodeList.map((x, i) => (
                    <MenuItem key={i} value={x.value}>
                      {x.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}
                        </div>
                        <div className="filterItems">
                            <InputLabel>Partner Type</InputLabel>
                            <Multiselect
                                options={partnerList}
                                //selectedValues={selectedWarhouse}
                                onSelect={(selectedList, selectedItem) => handleSelectPartnerType(selectedList, selectedItem)}
                                onRemove={(selectedList, selectedItem) => handleRemovePartnerType(selectedList, selectedItem)}
                                displayValue="title"
                            />

                        </div>
                        <div className="filterItems">
                            <InputLabel id="">Your Order/Transaction ID/AgilityOrder ID</InputLabel>
                            <FormControl size="small" fullWidth style={{ marginTop: 10 }}>
                                <Autocomplete
                                    size="small"
                                    value={customerOrderId}
                                    onChange={handleChangeOrderId}
                                    options={customerOrderIdList}
                                    getOptionLabel={(option: any) => option.title}
                                    fullWidth
                                    renderInput={(params: any) => (
                                        <TextField
                                            {...params}
                                            onChange={handleChangeYourOrder}
                                            variant="outlined"
                                            value={customerOrderId && customerOrderId.value}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    handleSearch();
                                                }
                                            }}
                                        />
                                    )}
                                />
                            </FormControl>
                        </div>
                    </div>
                    <div className="orderlisting_container pb-4">
                        <div className="filterItems">
                            <InputLabel id="">Start Date</InputLabel>
                            <FormControl size="small" fullWidth style={{ marginTop: 10 }}>
                                <TextField
                                    variant="outlined"
                                    id="date"
                                    size="small"
                                    type="date"
                                    error={startDateErr}
                                    helperText={startDateErr && "Start Date cannot be in the future"}
                                    onBlur={(e) => HandleDateValidate(e, "start")}
                                    onChange={handleStartDate}
                                    value={startDate}
                                    inputProps={{
                                        max: endDate ? endDate : "",
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </FormControl>
                        </div>
                        <div className="filterItems">
                            <InputLabel id="">End Date</InputLabel>
                            <FormControl size="small" fullWidth style={{ marginTop: 10 }}>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    type="date"
                                    value={endDate}
                                    error={endDateErr}
                                    helperText={endDateErr && "End Date cannot be in the future"}
                                    onBlur={(e) => HandleDateValidate(e, "end")}
                                    onChange={handleEndDate}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        min: startDate ? startDate : new Date(),
                                    }}
                                />
                            </FormControl>
                        </div>
                        <div className="filterItems">
                            <InputLabel id="">Latest Status Start Date</InputLabel>
                            <FormControl size="small" fullWidth style={{ marginTop: 10 }}>
                                <TextField
                                    variant="outlined"
                                    id="date"
                                    size="small"
                                    type="date"
                                    error={statusStartDateErr}
                                    helperText={statusStartDateErr && "Status Start Date cannot be in the future"}
                                    onBlur={(e) => HandleStatusDateValidate(e, "start")}
                                    onChange={handleStatusStartDate}
                                    value={statusStartDate}
                                    inputProps={{
                                        max: statusEndDate ? statusEndDate : "",
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </FormControl>
                        </div>
                        <div className="filterItems">
                            <InputLabel id="">Latest Status End Date</InputLabel>
                            <FormControl size="small" fullWidth style={{ marginTop: 10 }}>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    type="date"
                                    value={statusEndDate}
                                    error={statusEndDateErr}
                                    helperText={statusEndDateErr && "Status End Date cannot be in the future"}
                                    onBlur={(e) => HandleStatusDateValidate(e, "end")}
                                    onChange={handleStatusEndDate}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        min: statusStartDate ? statusStartDate : new Date(),
                                    }}
                                />
                            </FormControl>
                        </div>
                        <div className="filterItems">
                            <InputLabel>Days</InputLabel>
                            <FormControl size="small" fullWidth style={{ marginTop: 10 }}>
                                <Select
                                    variant="outlined"
                                    value={lastDays}
                                    label="Days"
                                    onChange={handleChangeLastDays}
                                >
                                    <MenuItem value="none">None</MenuItem>
                                    <MenuItem value={"7"}>Last 7 Days</MenuItem>
                                    <MenuItem value={"30"}>Last 30 Days</MenuItem>
                                    <MenuItem value={"90"}>Last 90 Days</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className="filterItems">
                            <div className="filterbtgroup">
                                <Button
                                    variant="contained"
                                    className="searchButton"
                                    onClick={handleSearch}
                                >
                                    Search
                                </Button>
                                <Button
                                    onClick={handleClear}
                                    // fullWidth
                                    className="searchButtonOutlined"
                                    variant="outlined"
                                >
                                    Reset
                                </Button>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
            <br />
            <div className="mb-6">
                <div className="d-flex justify-content-end align-items-center" >
                    <form>
                        <div>
                            <a target="_blank" type="button" className="btn btn-primary float-end ms-2" onClick={OpenFileExplore} >Import Order</a>
                        </div>
                        <input
                            ref={inputFile}
                            style={{ display: "none" }} onChange={handleFileUpload}
                            type="file"
                        />
                    </form>
                    <a target="_blank" type="button" className="btn  btn-primary float-end ms-2" onClick={() => DownloadInvoice(currentPage)} >Download Invoices</a>
                    <a target="_blank" type="button" className="btn  btn-primary float-end ms-2" onClick={() => DownloadShipping(currentPage)} >Download Shipment</a>
                    <a target="_blank" type="button" className="btn  btn-primary float-end ms-2" onClick={() => DownloadOrderList(currentPage)} >Download Order List</a>
                </div>
            </div>
            <br />
            <div className="pagination_container">
                <div>Items Per Page:{pageSize}</div>
                <div>
                    Showing {Math.min((currentPage - 1) * pageSize + 1, totalRecordCount)}{" "}
                    - {Math.min(currentPage * pageSize, totalRecordCount)} of{" "}
                    {totalRecordCount} results
                </div>
                <div>
                    <Pagination
                        count={Math.ceil(totalRecordCount / pageSize)}
                        page={currentPage}
                        variant="outlined"
                        className="customize_pagination"
                        shape="rounded"
                        onChange={handlePageChange}
                    />
                </div>
            </div>
            <table className="orders-table">
                <thead>
                    <tr>
                        <th className="th-checkbox-text-style" onClick={() => requestSort('orderdate')}>
                            <div onClick={(e) => e.stopPropagation()}>
                                <Checkbox
                                    onChange={(e) => handleCheckAll(e.target.checked)}
                                    {...label}
                                    checked={checkAll}
                                ></Checkbox>
                            </div>
                            Order Date  {getSortDirection()}
                        </th>
                        {/* <th onClick={() => requestSort('OrderDetails')}>Order Details {getSortDirection('OrderDetails')}</th>  */}
                        <th>Order Details</th>
                        <th>Shipping</th>
                        <th onClick={() => requestSort('status')}>Status  {getSortDirection()}</th>
                        <th onClick={() => requestSort('customeraccount')}>Customer Account  {getSortDirection()}</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {Array.isArray(jsonData) &&
                        jsonData.map((item: any, index: number) => (
                            <tr key={index}>
                                <td width={170}>
                                    <div className="td-checkbox-text-style">
                                        <div>
                                            <Checkbox
                                                checked={item.isChecked}
                                                {...label}
                                                onChange={(e) => handleChangeCheckbox(e, index)}
                                            ></Checkbox>
                                        </div>
                                        <div>
                                            <b>
                                                {dateConverter(item.customerOrderDateTime).substring(
                                                    0,
                                                    3
                                                )}
                                            </b>
                                            {dateConverter(item.customerOrderDateTime).substring(
                                                3,
                                                12
                                            )}
                                        </div>
                                    </div>
                                </td>
                                <td style={{ width: 470 }}>


                                    <div style={{ paddingBottom: "15px" }}>
                                        <b>Order # {item.customerOrderId}</b>
                                    </div>
                                    <div style={{ paddingBottom: "15px" }}>
                                        <b>Transaction Id : {item.transactionId}</b>
                                    </div>

                                    {item.agilityOrderId && (
                                        <div style={{ paddingBottom: "15px" }}>
                                            <b>Agility Order Id: {item.agilityOrderId}</b>
                                        </div>
                                    )}
                                    {Array.isArray(item.orderLineItems) &&
                                        item.orderLineItems.map((x: any, i: any) => (
                                            <React.Fragment key={i}>
                                                <div className="orderlineitem">
                                                    <div style={{ paddingBottom: 5 }}>
                                                        <b>{x.productName}</b>
                                                    </div>
                                                    <div style={{ paddingBottom: 10 }}>
                                                        {x.productDescription}
                                                    </div>
                                                    <div>
                                                        <b>QTY:{x.quantity}</b>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        ))}
                                    {/* {item.transactionDate && (
                    <div style={{ paddingBottom: "15px" }}>
                      <b>Order Received Date: {ShippingDate(item.transactionDate).substring(0,3)}</b>
                      {ShippingDate(item.transactionDate).substring(3,20)}
                    </div>
                  )} */}
                                </td>

                                <td width={200}>
                                    <b>{item.orderDetails.shippingMethod}</b>
                                    <br />
                                    <b>
                                        {item.orderDetails.expectedDeliveryDate &&
                                            "Expected Ship Date"}
                                    </b>
                                    <br />
                                    <b>
                                        {item.orderDetails.expectedDeliveryDate &&
                                            ShippingDate(
                                                item.orderDetails.expectedDeliveryDate
                                            ).substring(0, 3)}
                                    </b>
                                    {item.orderDetails.expectedDeliveryDate &&
                                        ShippingDate(
                                            item.orderDetails.expectedDeliveryDate
                                        ).substring(3, 20)}
                                    <br />
                                    <br />
                                    <b>Received On</b>
                                    <br />

                                    <b>{dateConverter(item.transactionDate).substring(0, 3)}</b>
                                    {dateConverter(item.transactionDate).substring(3, 50)}

                                </td>
                                <td width={200}>{findstatus(item.statusCode)}

                                    {item.acknowledgementDate && (
                                        <div style={{ paddingBottom: "15px" }}>
                                            <b>Last Updated On: <br />{dateConverter(item.acknowledgementDate).substring(0, 3)}</b>
                                            {dateConverter(item.acknowledgementDate).substring(3, 50)}
                                        </div>
                                    )}
                                </td>
                                <td width={200}>{item.accountNumber}</td>
                                <td width={155}>
                                    <div className="orderlisting_actions">
                                        {/* <Button
                       variant="outlined"
                      className="searchButtonOutlined"
                      fullWidth
                      onClick={() => handleClickTrack(item)}
                    >
                      Track Order
                    </Button> */}

                                        {/* <Button
                      variant="contained"
                      className="searchButton"
                      onClick={() => handleClickDetails(item)}
                      fullWidth
                    >
                      Order Details
                    </Button> */}
                                        <button
                                            onClick={() => handleClickDetails(item)}
                                            className="button-order"
                                        >
                                            Order Details
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
            {nodatafound && (
                <div className="no_data_found">
                    <h2>No data found !</h2>
                </div>
            )}
            <div className="pagination_container">
                <div>Items Per Page:{pageSize}</div>
                <div>
                    Showing {Math.min((currentPage - 1) * pageSize + 1, totalRecordCount)}{" "}
                    - {Math.min(currentPage * pageSize, totalRecordCount)} of{" "}
                    {totalRecordCount} results
                </div>
                <div>
                    <Pagination
                        count={Math.ceil(totalRecordCount / pageSize)}
                        page={currentPage}
                        variant="outlined"
                        className="customize_pagination"
                        shape="rounded"
                        onChange={handlePageChange}
                    />
                </div>
            </div>
        </>
    );
};

export default Order;
