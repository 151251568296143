import * as React from "react";
import { Route } from "react-router";
import Layout from "./components/Layout";
import "./custom.css";
import { Redirect } from "react-router-dom";
import Login from "./components/Login";
import ForgotPassword from "./components/account/ForgotPassword";
import LoadUsers from "./components/Users/usersindex";
import Connector from "./components/magento/connector";
import JobList from "./components/sync/joblist";
import SyncConnector from "./components/sync/connector";
import SyncSpecificEntity from "./components/sync/syncentities";
import JobDetails from "./components/sync/jobdetails";
import Dashboard from "./components/dashboard/dashboard";
import AddWebsite from "./components/dashboard/addwebsite";
import Globals from "./dataservices/global";
import TakDetails from "./components/sync/taskdetails";
import ScheduleComponent from "./components/sync/schedule";
import loginLayout from "./components/loginLayout";
import AddAttribute from "./components/attribute/addattribute";
import MagentoSyncConnector from "./components/magentosync/connector";
import MagentoJobList from "./components/magentosync/jobList";
import MagentoJobDetails from "./components/magentosync/jobdetails";
import MagentoTakDetails from "./components/magentosync/taskdetails";
import MagentoScheduleComponent from "./components/magentosync/schedule";
import UserDashboard from "./components/userdashboard/userdashboard";
import usersList from "./components/userdashboard/usersList";
import roleList from "./components/userdashboard/roleslist";
import roleEdit from "./components/userdashboard/editRole";
import AddRoleComponent from "./components/userdashboard/addRole";
import setup from "./components/setting/setup";
import Productindex from "./components/marketplace/productsindex";
import inventoryRules from "./components/marketplace/inventoryRules";
import webhook from "./components/userdashboard/webhook";
import mpjobList from "./components/marketplace/mpjobList";
import MPTaskDetails from "./components/marketplace/mptaskdetails";
import agilitytaskdetails from "./components/marketplace/agilitytaskdetails";
import agilityjobList from "./components/marketplace/agilityjobList";
import MPSyncSchedule from "./components/marketplace/mpschedule";
import AgilitySchedule from "./components/marketplace/agilityschedule";
import ProductRules from "./components/marketplace/productrules";
import CustomModal from "./components/userdashboard/assignRole";
import { useSelector } from "react-redux";
import Order from "./components/Order/orderlisting";
import OrderDetails from "./components/Order/OrderDetails";
import { toast, Toaster, ToastBar } from "react-hot-toast";
import { useState } from "react";
import UserSideBar from "./components/usersidebar";

//import nfinventoryRules from "./components/marketplace/nfinventoryRules";
import NFProductRules from "./components/marketplace/nfproductrules";

import nfinventoryRules from "./components/marketplace/nfinventoryRules";
import nfproductlisting from "./components/marketplace/nfproductlisting";
import nfsetup from "./components/setting/nfsetup";
import NFCustomerList from "./components/marketplace/nfCustomerlist";



const PrivateRoute = ({
    component: Component,
    isAuthenticated,
    ...rest
}: any) => {
    return (
        <Route
            {...rest}
            render={(props) =>
                isAuthenticated == "true" || isAuthenticated ? (
                    <Component {...props} />
                ) : (
                    <Redirect to="/login" />
                )
            }
        />
    );
};

export default () => {
    const isAuntheicationFromStore = useSelector((state: any) => {
        return state.isatuthendicated;
    });
    var isAuthenticated = isAuntheicationFromStore || localStorage.getItem("IsAuthendicated");
    if (isAuntheicationFromStore && localStorage.getItem("IsAuthendicated")) {
        localStorage.setItem('IsAuthendicated', "true");
        isAuthenticated = true;
    } else if (localStorage.getItem("IsAuthendicated") == "true") {
        localStorage.setItem('IsAuthendicated', "true");
        isAuthenticated = true;

    }
    else {
        localStorage.setItem('IsAuthendicated', "false");
        isAuthenticated = false;
    }
    return (
        <Layout>
            <Toaster>
                {(t) => (
                    <ToastBar toast={t}>
                        {({ icon, message }) => (
                            <>
                                {/* {icon} */}
                                {message}
                                {t.type !== "loading" && (
                                    <button
                                        className="Global_react_toat"
                                        onClick={() => toast.dismiss(t.id)}
                                    >
                                        X
                                    </button>
                                )}
                            </>
                        )}
                    </ToastBar>
                )}
            </Toaster>
            <Route exact path="/forgotPassword" component={ForgotPassword} />
            <Route exact path="/" component={Login} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/usersidebar" component={UserSideBar} />

            <PrivateRoute
                exact
                path="/userdashboard"
                component={UserDashboard}
                isAuthenticated={isAuthenticated}
            />
            <PrivateRoute
                exact
                path="/userslist"
                component={usersList}
                isAuthenticated={isAuthenticated}
            />
            <PrivateRoute
                exact
                path="/roleslist"
                component={roleList}
                isAuthenticated={isAuthenticated}
            />
            <PrivateRoute
                exact
                path="/editRole"
                component={roleEdit}
                isAuthenticated={isAuthenticated}
            />
            <PrivateRoute
                exact
                path="/addRole"
                component={AddRoleComponent}
                isAuthenticated={isAuthenticated}
            />
            <PrivateRoute
                exact
                path="/assignRole"
                component={CustomModal}
                isAuthenticated={isAuthenticated}
            />
            <PrivateRoute
                exact
                path="/apiSettings"
                component={Connector}
                isAuthenticated={isAuthenticated}
            />
            <PrivateRoute
                exact
                path="/joblist"
                component={JobList}
                isAuthenticated={isAuthenticated}
            />
            <PrivateRoute
                exact
                path="/synchistory"
                component={SyncConnector}
                isAuthenticated={isAuthenticated}
            />
            <PrivateRoute
                exact
                path="/syncentities"
                component={SyncSpecificEntity}
                isAuthenticated={isAuthenticated}
            />
            <PrivateRoute
                exact
                path="/jobDetails"
                component={JobDetails}
                isAuthenticated={isAuthenticated}
            />
            <PrivateRoute
                exact
                path="/dashboard"
                component={Dashboard}
                isAuthenticated={isAuthenticated}
            />
            <PrivateRoute
                exact
                path="/addwebsite"
                component={AddWebsite}
                isAuthenticated={isAuthenticated}
            />
            <PrivateRoute
                exact
                path="/addattribute"
                component={AddAttribute}
                isAuthenticated={isAuthenticated}
            />
            <PrivateRoute
                exact
                path="/tasklist"
                component={TakDetails}
                isAuthenticated={isAuthenticated}
            />
            <PrivateRoute
                exact
                path="/synschedule"
                component={ScheduleComponent}
                isAuthenticated={isAuthenticated}
            />
            <PrivateRoute
                exact
                path="/userindex"
                component={LoadUsers}
                isAuthenticated={isAuthenticated}
            />
            <PrivateRoute
                exact
                path="/magentosynchistory"
                component={MagentoSyncConnector}
                isAuthenticated={isAuthenticated}
            />
            <PrivateRoute
                exact
                path="/magentojoblist"
                component={MagentoJobList}
                isAuthenticated={isAuthenticated}
            />
            <PrivateRoute
                exact
                path="/magentojobdetails"
                component={MagentoJobDetails}
                isAuthenticated={isAuthenticated}
            />
            <PrivateRoute
                exact
                path="/magentotakdetails"
                component={MagentoTakDetails}
                isAuthenticated={isAuthenticated}
            />
            <PrivateRoute
                exact
                path="/MagentoSyncSchedule"
                component={MagentoScheduleComponent}
                isAuthenticated={isAuthenticated}
            />
            <PrivateRoute
                exact
                path="/setup/:accid"
                component={setup}
                isAuthenticated={isAuthenticated}
            />
            <PrivateRoute
                exact
                path="/nfsetup/:accid"
                component={nfsetup}
                isAuthenticated={isAuthenticated}
            />
            <PrivateRoute
                exact
                path="/productsindex/:accid"
                component={Productindex}
                isAuthenticated={isAuthenticated}
            />
            <PrivateRoute
                exact
                path="/nfproductlisting/:accid"
                component={nfproductlisting}
                isAuthenticated={isAuthenticated}
            />

            <PrivateRoute
                exact
                path="/inventoryrules"
                component={inventoryRules}
                isAuthenticated={isAuthenticated}
            />
            <PrivateRoute
                exact
                path="/webhook"
                component={webhook}
                isAuthenticated={isAuthenticated}
            />
            <PrivateRoute
                exact
                path="/mpjoblist/:accid"
                component={mpjobList}
                isAuthenticated={isAuthenticated}
            />
            <PrivateRoute
                exact
                path="/mptaskdetails"
                component={MPTaskDetails}
                isAuthenticated={isAuthenticated}
            />
            <PrivateRoute
                exact
                path="/agilityjoblist"
                component={agilityjobList}
                isAuthenticated={isAuthenticated}
            />
            <PrivateRoute
                exact
                path="/agilitytaskdetails"
                component={agilitytaskdetails}
                isAuthenticated={isAuthenticated}
            />
            <PrivateRoute
                exact
                path="/mpschedule/:accid"
                component={MPSyncSchedule}
                isAuthenticated={isAuthenticated}
            />
            <PrivateRoute
                exact
                path="/agilityschedule"
                component={AgilitySchedule}
                isAuthenticated={isAuthenticated}
            />
            <PrivateRoute
                exact
                path="/productrules"
                component={ProductRules}
                isAuthenticated={isAuthenticated}
            />
            <PrivateRoute
                exact
                path="/orderlisting/:OLT"
                component={Order}
                isAuthenticated={isAuthenticated}
            />
            <PrivateRoute
                exact
                path="/orderdetails/:OLT"
                component={OrderDetails}
                isAuthenticated={isAuthenticated}
            />
            <PrivateRoute
                exact
                path="/nfproductrules"
                component={NFProductRules}
                isAuthenticated={isAuthenticated}
            />
            <PrivateRoute
                exact
                path="/nfinventoryrules"
                component={nfinventoryRules}
                isAuthenticated={isAuthenticated}
            />
            <PrivateRoute
                exact
                path="/nfCustomerlist/:accid"
                component={NFCustomerList}
                isAuthenticated={isAuthenticated}

            />
            {/* <Route path='/counter' component={Counter} />
        <Route path='/fetch-data/:startDateIndex?' component={FetchData} /> */}
        </Layout>
    );
};
