import React, { useState, useEffect, useRef, Fragment } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { SSL_OP_COOKIE_EXCHANGE } from "constants";
import SideBar from "../sidebar";
import { useHistory, useParams } from "react-router";
import { CreateinitialLoadermessage, Loadermessage } from "../../dataservices/model/loadermessage";
import { APISettings } from "../../dataservices/model/apisettings";
import { GetSettingsByID, SaveMPApiSettings, GetShippingMethods, EditandSaveShippingMethod, GetMpAccountSettingValue, SaveNFApiSettings } from "../../dataservices/action/users/getUserApiSettins";
import Loader from "../loader";
import { moduleinfo } from "../../dataservices/model/user";
import { GetEditPermission } from "../../dataservices/action/account/register";
import axios from "axios";
import { channellist } from "../../dataservices/model/channellist";
import { DeleteUOM, GetAllMarketplaces, GetAProductsLoad, GetAProductsLoadByFilters, GetFilters, GetFiltersjson, GetMarketplaceAccountNumbers, GetMpLoad, GetmpLocationsANDagilityBranch, GetProductsLoad, Getrolepermissionbyuserid, GetSuppliers, GetUOMLoad, GetValuesFromMpProducts, Inactivempproduct, SaveEntitybyAPID, SaveUOMSettings, SaveWarehouseSettings, UpdateActiveProduct } from "../../dataservices/action/marketplace/marketplace";
import { products } from "../../dataservices/model/mpproducts";
import { PaginationValues } from "../pagenation/pageflow";
import AFailed from "../alretbox/failed";
import ASuccess from "../alretbox/success";
import Globals from "../../dataservices/global";
import { confirmAlert } from "react-confirm-alert";
import moment from "moment";
import MPSideBar from "../mpsidebar";
import { jobDetails } from "../../dataservices/model/jobDetails";
import ReadOnlyRow from "../marketplace/ReadOnlyRow";
import EditableRow from "../marketplace/EditableRow";
import { filters, filtersobjs } from "../../dataservices/model/filters";
import { shippingMethodModel } from "../../dataservices/model/shippingmethods";
import Multiselect from "multiselect-react-dropdown";
import InfoIcon from "@material-ui/icons/Info";
import Popover from "@material-ui/core/Popover";
import nfSideBar from "../nfsidebar";
import NFSideBar from "../nfsidebar";

const nfsetup = (props: any) => {
    const [awsregion, setawsregion] = useState("");
    const [awsrolearn, setawsrolearn] = useState("");
    const [awsaccesskey, setawsaccesskey] = useState("");
    const [awssecretkey, setawssecretkey] = useState("");
    const [refreshtoken, setrefreshtoken] = useState("");
    const [amazonclientid, setamazonclientid] = useState("");
    const [amazonsecretkey, setamazonsecretkey] = useState("");
    const [marketplaceid, setmarketplaceid] = useState("");
    const [apiurl, setapiurl] = useState("");
    const [apioauthurl, setapioauthurl] = useState("");
    const [apiouthpath, setapiouthpath] = useState("");
    const [batchsize, setbatchsize] = useState("");
    const [isfromuom, setisfromuom] = useState("");
    const [istouom, setistouom] = useState("");
    const [fsku, setfsku] = useState("");
    const [tsku, settsku] = useState("");
    const [ismapped, setismapped] = useState("");
    const [nforder, setnforder] = useState("");
    const changeFilterTSku = (e: any) => {
        settsku(e.target.value);

    }
    const changeFilterSku = (e: any) => {
        setfsku(e.target.value);

    }
    //const accid = Globals.accountid;
    const { accid } = useParams();
    //const [accid, setIsMPACCID] = useState(Globals.accountid);
    const history = useHistory();
    
    const initialSettings: APISettings[] = new Array<APISettings>();
    const [apiSettings, SetApiSettings] = useState(initialSettings);

    const initialAPISet: channellist[] = new Array<channellist>();
    const [apiNFSettings, SetapiNFSettings] = useState(initialAPISet);

    const newSettings: APISettings[] = new Array<APISettings>();

    const [isEdit, setIsEdit] = useState(false);

    const [isloader, Setisloader] = useState(CreateinitialLoadermessage());
    const store = useSelector((state: any) => { return state })
    const dispatch = useDispatch();
    var vperList: moduleinfo[] = new Array<moduleinfo>();
    const [VPList, setVPList] = useState(vperList);


    const [ECategoryAttributes, setVCategoryAttributes] = useState(false);
    const [EAPIsettings, setVAPIsettings] = useState(false);
    const [EMagentoSync, setVMagentoSync] = useState(false);
    const [EInriverSync, setVInriverSync] = useState(false);
    const [EProducts, setVProducts] = useState(false);
    const [EInventoryRules, setVInventoryRules] = useState(false);
    const [Elogs, setVlogs] = useState(false);
    const [ESettings, setVSettings] = useState(false);
    const [isDisabled, setisDisabled] = useState("disabled");

    const [VAssProduct, setVAssProduct] = useState(false);

    const [AAssProduct, setAAssProduct] = useState(false);
    const [EAssProduct, setEAssProduct] = useState(false);
    const [DAssProduct, setDAssProduct] = useState(false);

    const [VWHMapping, setVWHMapping] = useState(false);

    const [AWHMapping, setAWHMapping] = useState(false);

    const [VUOMMapping, setVUOMMapping] = useState(false);
    const [AUOMMapping, setAUOMMapping] = useState(false);
    const [DUOMMapping, setDUOMMapping] = useState(false);

    const [EApi, setEApi] = useState(false);
    const [VApi, setVApi] = useState(false);

    const [VSMMapping, setVSMMapping] = useState(false);
    const [ESMMapping, setESMMapping] = useState(false);

    const [ASMMapping, setASMMapping] = useState(false);
    const [DSMMapping, setDSMMapping] = useState(false);

    const [Page, setPage] = useState(1);
    const [PageSize, setPageSize] = useState(50);
    const [ANFSettings, setANFSettings] = useState(false);
    const [ENFSettings, setENFSettings] = useState(false);
    const [DNFSettings, setDNFSettings] = useState(false);
    useEffect(() => {
        Getrolepermissionbyuserid(1, function (result: any) {
            if (result != null) {
                if (result != null) {
                    for (var i = 0; i < result.length; i++) {
                        console.log(result[i].name);
                        var ipermission = [...result];
                        switch (result[i].permissionid) {
                            
                            case 14:
                                if (result[i].canview == "1") {

                                    setVAssProduct(true);
                                    setglobalUOM(true);
                                    setactiveimportclass(true);
                                    setactivesetupclass(false);
                                    setactivelocclass(false);
                                    setactiveuomclass(false);
                                    setactiveguomclass(false);
                                    setactiveShippingClass(false);
                                }
                                if (result[i].canadd == "1") {
                                    setAAssProduct(true);
                                }
                                if (result[i].canedit == "1") {
                                    setEAssProduct(true);
                                }
                                if (result[i].candelete == "1") {
                                    setDAssProduct(true);
                                }


                                break;
                            case 15:
                                if (result[i].canadd == "1") {
                                    setAWHMapping(true);
                                }
                                if (result[i].canview == "1") {
                                    ipermission.forEach(element => {
                                        if (element.permissionid === 14 && element.canview == "1") {
                                            setglobalUOM(true);
                                            setactiveimportclass(true);
                                            setactivesetupclass(false);
                                            setactivelocclass(false);
                                            setactiveuomclass(false);
                                            setactiveguomclass(false);
                                            setactiveShippingClass(false);
                                        }
                                        if (element.permissionid === 14 && element.canview == "0") {
                                            setglobalUOM(false);
                                            setactiveimportclass(false);
                                            setactivesetupclass(false);
                                            setactivelocclass(true);
                                            setactiveuomclass(false);
                                            setactiveguomclass(false);
                                            setactiveShippingClass(false);
                                        }
                                    });
                                    setVWHMapping(true);
                                }
                                break;
                            case 16:
                                if (result[i].canadd == "1") {
                                    setAUOMMapping(true);
                                }
                                if (result[i].candelete == "1") {
                                    setDUOMMapping(true);
                                }
                                if (result[i].canview == "1") {
                                    setVUOMMapping(true);
                                    ipermission.forEach(element => {
                                        if (element.permissionid === 14 && element.canview == "1") {
                                            setglobalUOM(true);
                                            setactiveimportclass(true);
                                            setactivesetupclass(false);
                                            setactivelocclass(false);
                                            setactiveuomclass(false);
                                            setactiveguomclass(false);
                                            setactiveShippingClass(false);
                                        }
                                        if (element.permissionid === 14 && element.canview == "0") {
                                            setglobalUOM(true);
                                            setactiveimportclass(false);
                                            setactivesetupclass(false);
                                            setactivelocclass(false);
                                            setactiveuomclass(true);
                                            setactiveguomclass(true);
                                            setactiveShippingClass(false);
                                        }
                                    });
                                }
                                break;
                            case 17:
                                if (result[i].canedit == "1") {
                                    setEApi(true);
                                }
                                if (result[i].canview == "1") {
                                    setVApi(true);

                                    ipermission.forEach(element => {
                                        if (element.permissionid === 14 && element.canview == "1") {
                                            setglobalUOM(true);
                                            setactiveimportclass(true);
                                            setactivesetupclass(false);
                                            setactivelocclass(false);
                                            setactiveuomclass(false);
                                            setactiveguomclass(false);
                                            setactiveShippingClass(false);
                                        }
                                        if (element.permissionid === 14 && element.canview == "0") {
                                            setglobalUOM(false);
                                            setactiveimportclass(false);
                                            setactivesetupclass(true);
                                            setactivelocclass(false);
                                            setactiveuomclass(false);
                                            setactiveguomclass(false);
                                            setactiveShippingClass(false);
                                        }
                                    });

                                }

                                break;
                            case 34:
                                if (result[i].canadd == "1") {
                                    setANFSettings(true);
                                }
                                if (result[i].canedit == "1") {
                                    setENFSettings(true);
                                }
                                if (result[i].candelete == "1") {
                                    setDNFSettings(true);
                                }
                                break;
                                case 33:
                                if (result[i].canadd == "1") {
                                    setANFSettings(true);
                                }
                                if (result[i].canedit == "1") {
                                    setENFSettings(true);
                                }
                                if (result[i].candelete == "1") {
                                    setDNFSettings(true);
                                }
                                break;
                            case 25:
                                if (result[i].canedit == "1") {
                                    setESMMapping(true);
                                }
                                if (result[i].canadd == "1") {
                                    setASMMapping(true);
                                }
                                if (result[i].candelete == "1") {
                                    setDSMMapping(true);
                                }
                                if (result[i].canview == "1") {
                                    setVSMMapping(true);

                                    ipermission.forEach(element => {
                                        if (element.permissionid === 14 && element.canview == "1") {
                                            setglobalUOM(true);
                                            setactiveimportclass(true);
                                            setactivesetupclass(false);
                                            setactivelocclass(false);
                                            setactiveuomclass(false);
                                            setactiveguomclass(false);
                                            setactiveShippingClass(false);
                                        }
                                        if (element.permissionid === 14 && element.canview == "0") {
                                            setglobalUOM(false);
                                            setactiveimportclass(false);
                                            setactivesetupclass(false);
                                            setactivelocclass(false);
                                            setactiveuomclass(false);
                                            setactiveguomclass(false);
                                            setactiveShippingClass(true);
                                        }
                                    });

                                }

                                break;
                        }
                    }
                }
            }
        })

    }, [])
    //useEffect(() => {
    //    let load: Loadermessage = {
    //        message: "",
    //        isLoader: true,
    //        isSuccess: false,
    //        isfailed: false
    //    }
    //    setisLoader(true);
    //    GetSettingsByID(accid.toString(), function (result: APISettings[]) {
    //        if (result != null) {
    //            setawsregion(result.filter(x => x.id == 1)[0].name);
    //            //setawsrolearn(result.filter(x => x.id == 2)[0].name);
    //            //setawsaccesskey(result.filter(x => x.id == 3)[0].name);
    //            //setawssecretkey(result.filter(x => x.id == 4)[0].name);
    //            //setamazonclientid(result.filter(x => x.id == 5)[0].name);
    //            //setamazonsecretkey(result.filter(x => x.id == 6)[0].name);
    //            setmarketplaceid(result.filter(x => x.id == 7)[0].name);
    //            // setrefreshtoken(result.filter(x => x.id == 8)[0].name);
    //            setapiurl(result.filter(x => x.id == 9)[0].name);
    //            setapioauthurl(result.filter(x => x.id == 10)[0].name);
    //            setapiouthpath(result.filter(x => x.id == 11)[0].name);
    //            setbatchsize(result.filter(x => x.id == 12)[0].name);
    //            SetApiSettings(result);
    //            if (result.length > 0) {
    //                result.forEach(element => {
    //                    element.accountid = accid.toString();
    //                })
    //            };
    //        }
    //        let loads: Loadermessage = {
    //            message: "",
    //            isLoader: false,
    //            isSuccess: false,
    //            isfailed: false
    //        };
    //        setisLoader(false);
    //    })

    //}, [])

    const [isLoader, setisLoader] = useState(false);
    const [maid, setMaid] = useState(0);
    var shippingMethod: shippingMethodModel[] = new Array<shippingMethodModel>();

    const [shippingData, setShippingData] = useState(shippingMethod);

    var shippingMethod1: shippingMethodModel[] = new Array<shippingMethodModel>();

    const [shippingData1, setShippingData1] = useState(shippingMethod1);
    const [reload, setReload] = useState(false);



    useEffect(() => {
        //SetisLoader(true);
        setMaid(accid);
        GetMpAccountSettingValue(accid, function (result: channellist[]) {
            if (result != null) {
                SetapiNFSettings(result);
                debugger;

                setismapped(result.filter(x => x.resultid == 3)[0].message);
                setnforder(result.filter(x => x.resultid == 5)[0].message);

                //setTotalCount(result[0].totalRecord)
                //setPage(result[0].Index);
                //setPageSize(result[0].RecordPerPage)
                //setActivePage(Page);
                //  SetisLoader(false);
            }
            // console.log("shipmethods....", result)
        })
        //GetShippingMethods(Page, PageSize, accid, function (result: shippingMethodModel[]) {
        //    if (result != null) {
        //        setShippingData(result);
        //        setTotalCount(result[0].totalRecord)
        //        setPage(result[0].Index);
        //        setPageSize(result[0].RecordPerPage)
        //        //setActivePage(Page);
        //        //  SetisLoader(false);
        //    }
        //    // console.log("shipmethods....", result)
        //})
    }, [reload])

    // shippingData = [{ "aquaShipMethod": "UPS GRD", "customerShipMethod": "Ground" }, { "aquaShipMethod": "UPS 2DAY", "customerShipMethod": "2 Day" }, { "aquaShipMethod": "UPS 3DAY", "customerShipMethod": "3 Day" }, { "aquaShipMethod": "UPS ND AIR", "customerShipMethod": "Next Day Air" }, { "aquaShipMethod": "COMMON CARRI", "customerShipMethod": "Truck" }, { "aquaShipMethod": "UPS STD INTL", "customerShipMethod": "Standard" }, { "aquaShipMethod": "UPS ND EARLY", "customerShipMethod": "Next Day Air Morning" }]

    const Savewarehoues = () => {
        let warwhs: moduleinfo = {
            moduleid: 0,
            maid:0,
            appname: accountid.toString(),
            modulename: locationid.toString(),
            permission: branchid
        }
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        if (accountid != "" && locationid != "" && branchid != "") {
            SaveWarehouseSettings(warwhs, function (result: boolean) {
                let mesg = CreateinitialLoadermessage();
                if (result) {
                    mesg.isLoader = false;
                    mesg.message = "Updated successfully";
                    mesg.isSuccess = true
                    // Setisloader(mesg);
                } else {
                    mesg.isLoader = false;
                    mesg.message = "Update failed";
                    mesg.isfailed = true
                    //  Setisloader(mesg);
                }
                GetMpLoad(accid.toString(), Page, PageSize, function (result: products[]) {
                    let load: Loadermessage = {
                        message: "",
                        isLoader: false,
                        isSuccess: false,
                        isfailed: false
                    }
                    if (result != null && result.length > 0) {
                        setProducts(result);
                        setTotalCount(result[0].totalRecord)
                        setPage(result[0].index);
                        setPageSize(result[0].recordPerPage)
                        setActivePage(Page);

                    }
                    //setisLoader(false);

                })
                Setisloader(mesg);

                //setisLoader(false);
            })
        } else {
            // alert("select account number , Warehouse and Branch");
            let load: Loadermessage = {
                message: "select account number , Warehouse and Branch",
                isLoader: false,
                isSuccess: false,
                isfailed: true
            }
            Setisloader(load);
            // setisLoader(false);
        }
    }
    const SaveSettings = () => {
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        setisLoader(true);
        SaveNFApiSettings(apiNFSettings, function (result: boolean) {
            let mesg = CreateinitialLoadermessage();
            if (result) {
                mesg.isLoader = false;
                mesg.message = "Updated successfully";
                mesg.isSuccess = true
                Setisloader(mesg);
            } else {
                mesg.isLoader = false;
                mesg.message = "Update failed";
                mesg.isfailed = true
                Setisloader(mesg);
            }
            setisLoader(false);
        })
    }
    const Changefromuom = (e: any) => {
        setisfromuom(e.target.value);
    }
    const Changetouom = (e: any) => {
        setistouom(e.target.value);
    }

    const ChangeNfSettings = (e: any) => {
        const { value, checked } = e.target;
        if (checked) {
            setismapped("Yes");

        }
        else {
            setismapped("No");
        }
        console.log(e.target.value);
        //const newSettings: APISettings[] = new Array<APISettings>();
        debugger;
        if (apiNFSettings.length > 0 && apiNFSettings.filter(x => x.resultid == 3).length > 0) {
            apiNFSettings.forEach(element => {
                if (element.resultid == 3) {
                    element.message = checked?"Yes":"No";
                    element.customerId = accid;
                }
            })
            SetapiNFSettings(apiNFSettings);
        } 
        //SetApiSettings(newSettings);
    }

    const ChangeNfOSettings = (e: any) => {
        setnforder(e.target.value);
        debugger;
        if (apiNFSettings.length > 0 && apiNFSettings.filter(x => x.resultid == 5).length > 0) {
            apiNFSettings.forEach(element => {
                if (element.resultid == 5) {
                    element.message = e.target.value;
                    element.customerId = accid;
                }
            })
            SetapiNFSettings(apiNFSettings);
        }
        //SetApiSettings(newSettings);
    }


    const ChangeAwsRegion = (e: any) => {
        setawsregion(e.target.value);
        //const newSettings: APISettings[] = new Array<APISettings>();
        if (apiSettings.length > 0 && apiSettings.filter(x => x.id == 1).length > 0) {
            apiSettings.forEach(element => {
                if (element.id == 1) {
                    element.name = e.target.value;
                    element.accountid = accid.toString();
                }
            })
            SetApiSettings(apiSettings);
        } else {
            let api: APISettings = {
                id: 1,
                url: "",
                isMagentoApi: 0,
                isMagentotoApi: false,
                token: "",
                name: e.target.value,
                accountid: accid.toString()
            }
            apiSettings.push(api);
            SetApiSettings(apiSettings);
        }
        //SetApiSettings(newSettings);
    }
    const changeawsrolearn = (e: any) => {
        debugger;
        setawsrolearn(e.target.value);
        //const newSettings: APISettings[] = new Array<APISettings>();
        if (apiSettings.length > 0 && apiSettings.filter(x => x.id == 2).length > 0) {
            apiSettings.forEach(element => {
                if (element.id == 2) {
                    element.name = e.target.value;
                    element.accountid = accid.toString();
                }
            })
            SetApiSettings(apiSettings);
        } else {
            let api: APISettings = {
                id: 2,
                url: "",
                isMagentoApi: 0,
                isMagentotoApi: false,
                token: "",
                name: e.target.value,
                accountid: accid.toString()
            }
            apiSettings.push(api);
            SetApiSettings(apiSettings);
        }

    }
    const changeawsaccesskey = (e: any) => {
        debugger;
        setawsaccesskey(e.target.value);
        if (apiSettings.length > 0 && apiSettings.filter(x => x.id == 3).length > 0) {
            apiSettings.forEach(element => {
                if (element.id == 3) {
                    element.name = e.target.value;
                    element.accountid = accid.toString();
                }
            })
            SetApiSettings(apiSettings);
        } else {
            let api: APISettings = {
                id: 3,
                url: "",
                isMagentoApi: 0,
                isMagentotoApi: false,
                token: "",
                name: e.target.value,
                accountid: accid.toString()
            }
            apiSettings.push(api);
            SetApiSettings(apiSettings);
        }
    }
    const changeawssecretkey = (e: any) => {
        setawssecretkey(e.target.value);
        // const newSettings: APISettings[] = new Array<APISettings>();
        if (apiSettings.length > 0 && apiSettings.filter(x => x.id == 4).length > 0) {
            apiSettings.forEach(element => {
                if (element.id == 4) {
                    element.name = awssecretkey;
                    element.accountid = accid.toString();

                }

            })
            SetApiSettings(apiSettings);
        } else {
            let api: APISettings = {
                id: 4,
                url: "",
                isMagentoApi: 0,
                isMagentotoApi: false,
                token: "",
                name: e.target.value,
                accountid: accid.toString()
            }
            apiSettings.push(api);
            SetApiSettings(apiSettings);
        }

    }
    const changerefreshtoken = (e: any) => {
        setrefreshtoken(e.target.value);
        const newSettings: APISettings[] = new Array<APISettings>();
        if (apiSettings.length > 0 && apiSettings.filter(x => x.id == 8).length > 0) {
            apiSettings.forEach(element => {
                if (element.id == 8) {
                    element.name = refreshtoken;
                    element.accountid = accid.toString();

                }
            })
            SetApiSettings(apiSettings);
        } else {
            let api: APISettings = {
                id: 8,
                url: "",
                isMagentoApi: 0,
                isMagentotoApi: false,
                token: "",
                name: e.target.value,
                accountid: accid.toString()
            }
            apiSettings.push(api);
            SetApiSettings(apiSettings);
        }
    }
    const changeamazonclientid = (e: any) => {
        setamazonclientid(e.target.value);
        const newSettings: APISettings[] = new Array<APISettings>();
        if (apiSettings.length > 0 && apiSettings.filter(x => x.id == 5).length > 0) {
            apiSettings.forEach(element => {
                if (element.id == 5) {
                    element.name = amazonclientid;
                    element.accountid = accid.toString();

                }
            })
            SetApiSettings(apiSettings);
        }
        else {
            let api: APISettings = {
                id: 5,
                url: "",
                isMagentoApi: 0,
                isMagentotoApi: false,
                token: "",
                name: e.target.value,
                accountid: accid.toString()
            }
            apiSettings.push(api);
            SetApiSettings(apiSettings);
        }
    }
    const changeamazonsecretkey = (e: any) => {
        setamazonsecretkey(e.target.value);
        const newSettings: APISettings[] = new Array<APISettings>();
        if (apiSettings.length > 0 && apiSettings.filter(x => x.id == 6).length > 0) {
            apiSettings.forEach(element => {
                if (element.id == 6) {
                    element.name = amazonsecretkey;
                    element.accountid = accid.toString();

                }
            })
            SetApiSettings(apiSettings);
        } else {
            let api: APISettings = {
                id: 6,
                url: "",
                isMagentoApi: 0,
                isMagentotoApi: false,
                token: "",
                name: e.target.value,
                accountid: accid.toString()
            }
            apiSettings.push(api);
            SetApiSettings(apiSettings);
        }
    }
    const changemarketplaceid = (e: any) => {
        setmarketplaceid(e.target.value);
        const newSettings: APISettings[] = new Array<APISettings>();
        if (apiSettings.length > 0 && apiSettings.filter(x => x.id == 7).length > 0) {
            apiSettings.forEach(element => {
                if (element.id == 7) {
                    element.name = marketplaceid;
                    element.accountid = accid.toString();

                }
            })
            SetApiSettings(apiSettings);
        } else {
            let api: APISettings = {
                id: 7,
                url: "",
                isMagentoApi: 0,
                isMagentotoApi: false,
                token: "",
                name: e.target.value,
                accountid: accid.toString()
            }
            apiSettings.push(api);
            SetApiSettings(apiSettings);
        }
    }
    const changeapi = (e: any) => {
        setapiurl(e.target.value);
        const newSettings: APISettings[] = new Array<APISettings>();
        if (apiSettings.length > 0 && apiSettings.filter(x => x.id == 9).length > 0) {
            apiSettings.forEach(element => {
                if (element.id == 9) {
                    element.name = apiurl;
                    element.accountid = accid.toString();

                }
            })
            SetApiSettings(apiSettings);
        } else {
            let api: APISettings = {
                id: 9,
                url: "",
                isMagentoApi: 0,
                isMagentotoApi: false,
                token: "",
                name: e.target.value,
                accountid: accid.toString()
            }
            apiSettings.push(api);
            SetApiSettings(apiSettings);
        }
    }
    const changeapioauth = (e: any) => {
        setapioauthurl(e.target.value);
        const newSettings: APISettings[] = new Array<APISettings>();
        if (apiSettings.length > 0 && apiSettings.filter(x => x.id == 10).length > 0) {
            apiSettings.forEach(element => {
                if (element.id == 10) {
                    element.name = apioauthurl;
                    element.accountid = accid.toString();

                }
            })
            SetApiSettings(apiSettings);
        } else {
            let api: APISettings = {
                id: 10,
                url: "",
                isMagentoApi: 0,
                isMagentotoApi: false,
                token: "",
                name: e.target.value,
                accountid: accid.toString()
            }
            apiSettings.push(api);
            SetApiSettings(apiSettings);
        }
    }
    const changeapioauthpath = (e: any) => {
        setapiouthpath(e.target.value);
        const newSettings: APISettings[] = new Array<APISettings>();
        if (apiSettings.length > 0 && apiSettings.filter(x => x.id == 11).length > 0) {
            apiSettings.forEach(element => {
                if (element.id == 11) {
                    element.name = apiouthpath;
                    element.accountid = accid.toString();

                }
            })
            SetApiSettings(apiSettings);
        } else {
            let api: APISettings = {
                id: 11,
                url: "",
                isMagentoApi: 0,
                isMagentotoApi: false,
                token: "",
                name: e.target.value,
                accountid: accid.toString()
            }
            apiSettings.push(api);
            SetApiSettings(apiSettings);
        }
    }
    const changebatchsize = (e: any) => {
        setbatchsize(e.target.value);
        const newSettings: APISettings[] = new Array<APISettings>();
        if (apiSettings.length > 0 && apiSettings.filter(x => x.id == 12).length > 0) {
            apiSettings.forEach(element => {
                if (element.id == 12) {
                    element.name = batchsize;
                    element.accountid = accid.toString();

                }
            })
            SetApiSettings(apiSettings);
        } else {
            let api: APISettings = {
                id: 12,
                url: "",
                isMagentoApi: 0,
                isMagentotoApi: false,
                token: "",
                name: e.target.value,
                accountid: accid.toString()
            }
            apiSettings.push(api);
            SetApiSettings(apiSettings);
        }
    }
    const Back = () => {
        history.push(`/userdashboard`);
    }
    const [activesetupclass, setactivesetupclass] = useState(true);
    const [activeimportclass, setactiveimportclass] = useState(false);
    const [activelocclass, setactivelocclass] = useState(false);
    const [activeuomclass, setactiveuomclass] = useState(false);
    const [activeguomclass, setactiveguomclass] = useState(false);
    const [activeShippingClass, setactiveShippingClass] = useState(false);
    const [activeeditView, setactiveEditView] = useState(false);
    const [addview, setaddView] = useState(false);

    const [globalUOM, setglobalUOM] = useState(false);

    var activeclassname = "nav-link active";
    var inactiveclassname = "nav-link";
    const [filename, setFilename] = useState();
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileType, setFileType] = useState();
    const inputFile: any = useRef();
    var intMPAcclist: channellist[] = new Array<channellist>();
    const [mpacclist, setmpacclist] = useState(intMPAcclist);

    var intlocationlist: channellist[] = new Array<channellist>();
    const [loclist, setloclist] = useState(intlocationlist);

    var intbranchlist: channellist[] = new Array<channellist>();
    const [branchlist, setbranchlist] = useState(intbranchlist);

    const [accountid, setaccid] = useState(accid);
    const [locationid, setlocid] = useState("");
    const [branchid, setbranchid] = useState("");
    const [accname, setaccname] = useState("");
    var intbAcclist: channellist[] = new Array<channellist>();
    const [bacclist, setbacclist] = useState(intbAcclist);
    var intsupplist: jobDetails[] = new Array<jobDetails>();
    const [suppList, setsuppList] = useState(intsupplist);
    const [supplierId, setsupplierId] = useState("");
    //useEffect(() => {
    //    setisLoader(true);
    //    //supplierList
    //    GetSuppliers(function (result: any) {
    //        setsuppList(result);
    //        setisLoader(false);
    //    })
    //}, [])
    useEffect(() => {
        // setisLoader(true);
        GetAllMarketplaces(function (result: any) {
            if (result.length > 0) {
                setbacclist(result);
                for (var i = 0; i < result.length; i++) {
                    if (result[i].customerId == Number(accid)) {
                        setaccname(result[i].message);
                    }
                }

                //setisLoader(false);
            }
        })
    }, [])
    useEffect(() => {
        //setisLoader(true);
        GetmpLocationsANDagilityBranch(1, function (result: any) {
            setloclist(result);
            // setisLoader(false);

        })
    }, [])

    useEffect(() => {
        //setisLoader(true);
        GetmpLocationsANDagilityBranch(2, function (result: any) {
            setbranchlist(result);
            //setisLoader(false);

        })
    }, [])

    useEffect(() => {
        //setisLoader(true);
        GetAllMarketplaces(function (result: channellist[]) {
            setmpacclist(result.filter(x => x.customerId == accid));
            //  setisLoader(false);

        })
    }, [])

    const handleChange = (e: any) => {
        debugger;
        if (e.target.id == 1) {
            setglobalUOM(false);
            setactivesetupclass(true);
            setactiveimportclass(false);
            setactivelocclass(false);
            setactiveuomclass(false);
            setactiveguomclass(false);
            setactiveShippingClass(false);

        }
        if (e.target.id == 2) {
            setglobalUOM(true);
            setactivesetupclass(false);
            setactiveimportclass(true);
            setactivelocclass(false);
            setactiveuomclass(false);
            setactiveguomclass(false);
            setactiveShippingClass(false);

        }
        if (e.target.id == 3) {
            setglobalUOM(false);
            setactivesetupclass(false);
            setactiveimportclass(false);
            setactivelocclass(true);
            setactiveuomclass(false);
            setactiveguomclass(false);
            setactiveShippingClass(false);
        }
        if (e.target.id == 4) {
            setglobalUOM(true);
            setactivesetupclass(false);
            setactiveimportclass(false);
            setactivelocclass(false);
            setactiveuomclass(true);
            setactiveguomclass(true);
            setactiveShippingClass(false);
        }
        if (e.target.id == 5) {
            setglobalUOM(false);
            setactivesetupclass(false);
            setactiveimportclass(false);
            setactivelocclass(false);
            setactiveuomclass(false);
            setactiveguomclass(false);
            setactiveShippingClass(true);
            setaddView(false);
            setactiveEditView(false);

        }

    }
    const handleFileUpload = (e: any) => {
        debugger;
        e.preventDefault();
        const { files } = e.target;
        if (files && files.length) {
            setFilename(files[0].name);
            setSelectedFile(files[0]);
            const formData = new FormData();
            formData.append('File', files[0]);
            formData.append('ACCID', accid);


            let load: Loadermessage = {
                message: "",
                isLoader: true,
                isSuccess: false,
                isfailed: false
            }
            Setisloader(load);

            try {
                const response = axios({
                    method: "post",
                    url: "api/MP/UploadProductFile",
                    data: formData,
                    headers: { "Content-Type": "multipart/form-data" },
                }).then(result => {
                    GetAProductsLoadByFilters(accid, filterobj, Page2, PageSize2, function (result: products[]) {
                        //setisLoader(true);
                        let load: Loadermessage = {
                            message: "",
                            isLoader: false,
                            isSuccess: false,
                            isfailed: false
                        }
                        if (result != null && result.length > 0) {
                            setAProducts(result);
                            setTotalCount2(result[0].totalRecord)
                            setPage2(result[0].index);
                            setPageSize2(result[0].recordPerPage)
                            setActivePage2(Page2)
                            Setisloader(load);

                        }
                        else {
                            setAProducts(result);
                            setTotalCount2(0)
                            Setisloader(load);
                        }
                        //setisLoader(false);


                    })

                    //setFilename();
                    // history.go(0);
                });

            } catch (error) {
                console.log(error)
            }
        }
    }

    const OpenFileExplore = () => {
        inputFile.current.click();
    };
    var intuom: products[] = new Array<products>()
    const [uoms, setUOMs] = useState(intuom);

    var intproducts: products[] = new Array<products>()
    const [products, setProducts] = useState(intproducts);
    const [TotalCount, setTotalCount] = useState(0);
    //const [Page, setPage] = useState(1);
    //const [PageSize, setPageSize] = useState(50);
    const [PageSizeList, setPageSizeList] = useState([50, 100, 200]);
    const [activePage, setActivePage] = useState(0);
    //useEffect(() => {
    //    let load: Loadermessage = {
    //        message: "",
    //        isLoader: true,
    //        isSuccess: false,
    //        isfailed: false
    //    }
    //    setisLoader(true);
    //    GetUOMLoad(accid.toString(), Page, PageSize, function (result: products[]) {
    //        if (result != null) {
    //            setUOMs(result);
    //            //setTotalCount(result[0].totalRecord)
    //            //setPage(result[0].index);
    //            //setPageSize(result[0].recordPerPage)
    //            //setActivePage(Page)
    //            let load: Loadermessage = {
    //                message: "",
    //                isLoader: false,
    //                isSuccess: false,
    //                isfailed: false
    //            }
    //            setisLoader(false);
    //        }
    //    })
    //}, [])
    //useEffect(() => {
    //    let load: Loadermessage = {
    //        message: "",
    //        isLoader: true,
    //        isSuccess: false,
    //        isfailed: false
    //    }
    //    setisLoader(true);
    //    GetMpLoad(accid.toString(), Page, PageSize, function (result: products[]) {
    //        let load: Loadermessage = {
    //            message: "",
    //            isLoader: false,
    //            isSuccess: false,
    //            isfailed: false
    //        }
    //        if (result != null && result.length > 0) {
    //            setProducts(result);
    //            setTotalCount(result[0].totalRecord)
    //            setPage(result[0].index);
    //            setPageSize(result[0].recordPerPage)
    //            setActivePage(Page);

    //        }
    //        setisLoader(false);

    //    })
    //}, [])
    const Saveuom = () => {
        let warwhs: moduleinfo = {
            moduleid: Number(accid),
            maid:0,
            appname: isfromuom,
            modulename: istouom,
            permission: ""
        }
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        debugger;
        if (accountid != "" && isfromuom != "" && istouom != "") {
            SaveUOMSettings(warwhs, function (result: boolean) {
                let mesg = CreateinitialLoadermessage();
                if (result) {
                    mesg.isLoader = false;
                    mesg.message = "Saved successfully";
                    mesg.isSuccess = true
                    Setisloader(mesg);
                } else {
                    mesg.isLoader = false;
                    mesg.message = "Update failed";
                    mesg.isfailed = true
                    Setisloader(mesg);
                }
                RefreshUOM();
                Setisloader(mesg);
                setisfromuom("");
                setistouom("");
            })
        } else {
            //alert("Enter FromUOM - ToUOM");
            let load: Loadermessage = {
                message: "Enter FromUOM - ToUOM",
                isLoader: false,
                isSuccess: false,
                isfailed: true
            }
            Setisloader(load);
            //setisLoader(false);
        }
    }
    const RefreshUOM = () => {
        debugger;
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        GetUOMLoad(accid.toString(), Page, PageSize, function (result: products[]) {
            if (result != null) {
                setUOMs(result);
                let load: Loadermessage = {
                    message: "",
                    isLoader: false,
                    isSuccess: false,
                    isfailed: false
                }
                Setisloader(load);
            }
        })
    }
    const Pagenation = () => {
        let Pagination = new PaginationValues();
        Pagination.TotalItemCount = TotalCount;
        Pagination.CurrentPage = Page;
        Pagination.PageSlide = 2;
        Pagination.IsSort = true;
        Pagination.PageSize = PageSize;

        return (
            <div className="pagination-section">
                <div className="row">
                    <div className="col-sm-4 col-md-4 col-lg-4">
                        <div className="item-page-results">
                            Items Per Page:50
                            {/* {
                                PageSizeList.map((x, i) => (
                                    x == PageSize ? (
                                        <span key={i}> {x} |</span>
                                    ) : (
                                        <span key={i}>   <a onClick={() => SetPageSize(x)} > {x}</a> | </span>
                                    )
                                ))
                            } */}
                        </div>
                    </div>
                    {
                        <div className="col-sm-4 col-md-4 col-lg-4">
                            <div className="sorting-results">
                                Showing {((Page - 1) * PageSize) + 1}    - {((((Page - 1) * PageSize) + 1) + PageSize) > TotalCount ? TotalCount : ((((Page - 1) * PageSize)) + PageSize)} of {TotalCount} results
                            </div>
                        </div>
                    }
                    <div className="col-sm-4 col-md-4 col-lg-4">

                        <nav aria-label="Page navigation example">
                            <ul className="pagination justify-content-end mb-0">
                                {
                                    Pagination.HasPrevPage() ?
                                        (<li className="page-item">
                                            <a className="page-link" onClick={() => SetPage(Page - 1)}>
                                                &#8592;</a>
                                        </li>
                                        )
                                        : ("")
                                }
                                {
                                    Pagination.Pages().map((x, i) =>
                                        x == Page ?
                                            (
                                                <li className={`page-item  ${activePage == x ? 'active' : null}`} key={i}><a className="page-link" >{x}</a></li>)
                                            : (
                                                <li className="page-item" key={i}>
                                                    <a className="page-link" onClick={() => SetPage(x)}>{x}
                                                    </a>
                                                </li>
                                            )
                                    )
                                }
                                {
                                    Pagination.HasNextPage() ?
                                        (<li className="page-item">
                                            <a className="page-link" onClick={() => SetPage(Page + 1)}>
                                                &#8594;</a>
                                        </li>) : ("")
                                }
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        )
    }

    const SetPageSize = (size: number) => {
        setPageSize(size);
        //RefreshProducts();
        setisLoader(true);
        GetMpLoad(accid.toString(), Page, size, function (result: products[]) {
            if (result != null) {
                setProducts(result);
                setTotalCount(result[0].totalRecord)
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage)
                setActivePage(Page)
                setisLoader(false);

            }
        })
    }

    const SetPage = (page: number) => {
        setPage(page);
        //setActivePage(page)
        //RefreshProducts();
        //setisLoader(true);
        GetMpLoad(accid.toString(), page, PageSize, function (result: products[]) {
            if (result != null) {
                setProducts(result);
                setTotalCount(result[0].totalRecord);
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage);
                setActivePage(page);
                // setisLoader(false);

            }
        })
    }

    const RefreshProducts = () => {
        debugger;
        setisLoader(true);
        GetMpLoad(accid.toString(), Page, PageSize, function (result: products[]) {
            if (result != null) {
                setProducts(result);
                setTotalCount(result[0].totalRecord)
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage)
                setActivePage(Page)
                setisLoader(false);

            }
        })
    }
    const changelocation = (e: any) => {
        let locid = e.target.value;
        setlocid(locid);

    }
    const changeacc = (e: any) => {
        setaccid(e.target.value);

    }
    const changebranch = (e: any) => {
        setbranchid(e.target.value);

    }
    const ClosePopup = () => {
        let loads: Loadermessage = {
            message: "",
            isLoader: false,
            isSuccess: false,
            isfailed: false
        };
        Setisloader(loads);
    }
    const RemoveUOM = (id: number) => {
        confirmAlert({
            title: 'Delete UOM',
            message: 'Are you sure you want to delete this UOM ?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => Delete(id)
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    }
    const Delete = (id: number) => {
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        DeleteUOM(id, function (result: any) {
            if (result.isSuccess) {
                let result = CreateinitialLoadermessage();
                result.isSuccess = true;
                result.message = "entity deleted successfully"
                setisLoader(false);
                RefreshUOM();
            }
        })
    }


    //Product Listing


    const [addFormData, setAddFormData] = useState({
        id: 0,
        sku: "",
        uom: "",
        displayDescription: "",
        msku: "",
        asin: "",
        touom: "",
        brand: "",
        pgroup: "",
        pmax: "",
        pmin: "",
        pmfg: ""

    });
    const [editFormData, setEditFormData] = useState({
        id: 0,
        sku: "",
        uom: "",
        displayDescription: "",
        msku: "",
        asin: "",
        touom: "",
        brand: "",
        pgroup: "",
        pmax: "",
        pmin: "",
        pmfg: ""
    });


    var intproducts: products[] = new Array<products>()

    const [aproducts, setAProducts] = useState(intproducts);
    const [activestatus, setactivestatus] = useState("2");

    const [TotalCount2, setTotalCount2] = useState(0);
    const [Page2, setPage2] = useState(1);
    const [PageSize2, setPageSize2] = useState(50);
    const [PageSizeList2, setPageSizeList2] = useState([50, 100, 200]);
    const [activePage2, setActivePage2] = useState(0)
    const [magentoConnect, setmagentoConnect] = useState(false);
    const [amazonMp, setamazonMp] = useState(false);
    const [sku, setsku] = useState("");

    var intlocationlist: channellist[] = new Array<channellist>();
    const [loclist1, setloclist1] = useState(intlocationlist);

    var intbranchlist: channellist[] = new Array<channellist>();
    const [branchlist1, setbranchlist1] = useState(intbranchlist);

    const [alocationid, setalocationid] = useState(0);
    const [abranchid, setabranchid] = useState(0);
    const [issearchcheck, setissearchcheck] = useState(false);
    var intPrdgrplist: channellist[] = new Array<channellist>();
    const [Prdgrplist, setPrdgrplist] = useState(intPrdgrplist);

    var intPricemaxlist: channellist[] = new Array<channellist>();
    const [Pricemaxlist, setPricemaxlist] = useState(intPricemaxlist);

    var intPriceminlist: channellist[] = new Array<channellist>();
    const [Priceminlist, setPriceminlist] = useState(intPriceminlist);

    const [editContactId, setEditContactId] = useState(null);

    var intmfglist: channellist[] = new Array<channellist>();
    const [mfglist, setmfglist] = useState(intmfglist);

    const [prdgrp, setprdgrp] = useState("");
    const [pricemax, setpricemax] = useState("");
    const [pricemin, setpricemin] = useState("");
    const [mfg, setmfg] = useState("");
    //useEffect(() => {
    //    setisLoader(true);
    //    GetValuesFromMpProducts(1, function (result: any) {
    //        setPrdgrplist(result);
    //        setisLoader(false);

    //    })
    //}, [])
    //useEffect(() => {
    //    setisLoader(true);
    //    GetValuesFromMpProducts(2, function (result: any) {
    //        setPricemaxlist(result);
    //        setisLoader(false);

    //    })
    //}, [])
    //useEffect(() => {
    //    setisLoader(true);
    //    GetValuesFromMpProducts(3, function (result: any) {
    //        setPriceminlist(result);
    //        setisLoader(false);

    //    })
    //}, [])
    //useEffect(() => {
    //    setisLoader(true);
    //    GetValuesFromMpProducts(4, function (result: any) {
    //        setmfglist(result);
    //        setisLoader(false);

    //    })
    //}, [])
    useEffect(() => {
        //setisLoader(true);
        GetmpLocationsANDagilityBranch(1, function (result: any) {
            setloclist1(result);
            // setisLoader(false);

        })
    }, [])
    useEffect(() => {
        // setisLoader(true);
        GetmpLocationsANDagilityBranch(2, function (result: any) {
            setbranchlist1(result);
            //setisLoader(false);

        })
    }, [])
    var intfilterlist: filters[] = new Array<filters>();
    const [filter, setfilterlist] = useState(intfilterlist);

    var intfilterobj: filtersobjs[] = new Array<filtersobjs>();
    const [filterobj, setfilterobj] = useState(intfilterobj);
    //useEffect(() => {

    //    let load: Loadermessage = {
    //        message: "",
    //        isLoader: true,
    //        isSuccess: false,
    //        isfailed: false
    //    }
    //    Setisloader(load);
    //    GetFilters(2, function (result: any) {

    //        setfilterlist(result);
    //        let load: Loadermessage = {
    //            message: "",
    //            isLoader: false,
    //            isSuccess: false,
    //            isfailed: false
    //        }
    //        Setisloader(load);

    //    })
    //}, [])
    const resetPage = () => {
        history.go(0);
    }
    //useEffect(() => {

    //    let load: Loadermessage = {
    //        message: "",
    //        isLoader: true,
    //        isSuccess: false,
    //        isfailed: false
    //    }
    //    Setisloader(load);
    //    GetFilters(2, function (result: any) {
    //        setfilterlist(result);
    //        // setisLoader(true);

    //        GetFiltersjson(2, function (result: any) {
    //            // setisLoader(true);

    //            setfilterobj(result);
    //            GetAProductsLoadByFilters(accid, result, Page2, PageSize2, function (result: products[]) {
    //                //setisLoader(true);
    //                let load: Loadermessage = {
    //                    message: "",
    //                    isLoader: false,
    //                    isSuccess: false,
    //                    isfailed: false
    //                }
    //                if (result != null && result.length > 0) {
    //                    setAProducts(result);
    //                    setTotalCount2(result[0].totalRecord)
    //                    setPage2(result[0].index);
    //                    setPageSize2(result[0].recordPerPage)
    //                    setActivePage2(Page2)
    //                    Setisloader(load);

    //                }
    //                else {
    //                    setAProducts(result);
    //                    setTotalCount2(0)
    //                    Setisloader(load);
    //                }
    //                //setisLoader(false);


    //            })

    //        })
    //    })

    //}, [])

    //useEffect(() => {
    //    let load: Loadermessage = {
    //        message: "",
    //        isLoader: true,
    //        isSuccess: false,
    //        isfailed: false
    //    }
    //    setisLoader(true);
    //    GetAProductsLoad(accid, sku, alocationid, abranchid, activestatus, supplierId, prdgrp, pricemax, pricemin, mfg, Page, PageSize, function (result: products[]) {
    //        let load: Loadermessage = {
    //            message: "",
    //            isLoader: false,
    //            isSuccess: false,
    //            isfailed: false
    //        }
    //        if (result != null && result.length > 0) {
    //            setAProducts(result);
    //            setTotalCount2(result[0].totalRecord)
    //            setPage2(result[0].index);
    //            setPageSize2(result[0].recordPerPage)
    //            setActivePage2(Page2)


    //        }
    //        setisLoader(false);
    //    })
    //}, [])


    const Pagenation3 = () => {
        let Pagination3 = new PaginationValues();
        Pagination3.TotalItemCount = TotalCount2;
        Pagination3.CurrentPage = Page2;
        Pagination3.PageSlide = 2;
        Pagination3.IsSort = true;
        Pagination3.PageSize = PageSize2;

        return (
            <div className="pagination-section">
                <div className="row">
                    <div className="col-sm-4 col-md-4 col-lg-4">
                        <div className="item-page-results">
                            Items Per Page:50
                            {/* {
                                PageSizeList.map((x, i) => (
                                    x == PageSize ? (
                                        <span key={i}> {x} |</span>
                                    ) : (
                                        <span key={i}>   <a onClick={() => SetPageSize(x)} > {x}</a> | </span>
                                    )
                                ))
                            } */}
                        </div>
                    </div>
                    {
                        <div className="col-sm-4 col-md-4 col-lg-4">
                            <div className="sorting-results">
                                Showing {((Page2 - 1) * PageSize2) + 1}    - {((((Page2 - 1) * PageSize2) + 1) + PageSize2) > TotalCount2 ? TotalCount2 : ((((Page2 - 1) * PageSize2)) + PageSize2)} of {TotalCount2} results
                            </div>
                        </div>
                    }
                    <div className="col-sm-4 col-md-4 col-lg-4">

                        <nav aria-label="Page navigation example">
                            <ul className="pagination justify-content-end mb-0">
                                {
                                    Pagination3.HasPrevPage() ?
                                        (<li className="page-item">
                                            <a className="page-link" onClick={() => SetPage2(Page2 - 1)}>
                                                &#8592;</a>
                                        </li>
                                        )
                                        : ("")
                                }
                                {
                                    Pagination3.Pages().map((x, i) =>
                                        x == Page ?
                                            (
                                                <li className={`page-item  ${activePage2 == x ? 'active' : null}`} key={i}><a className="page-link" >{x}</a></li>)
                                            : (
                                                <li className="page-item" key={i}>
                                                    <a className="page-link" onClick={() => SetPage2(x)}>{x}
                                                    </a>
                                                </li>
                                            )
                                    )
                                }
                                {
                                    Pagination3.HasNextPage() ?
                                        (<li className="page-item">
                                            <a className="page-link" onClick={() => SetPage2(Page + 1)}>
                                                &#8594;</a>
                                        </li>) : ("")
                                }
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        )
    }

    const SetPageSize2 = (size: number) => {
        setPageSize(size);
        //RefreshProducts();
        setisLoader(true);
        GetAProductsLoad(accid, sku, alocationid, abranchid, activestatus, supplierId, prdgrp, pricemax, pricemin, mfg, Page2, size, function (result: products[]) {

            if (result != null) {
                setAProducts(result);
                setTotalCount2(result[0].totalRecord)
                setPage2(result[0].index);
                setPageSize2(result[0].recordPerPage)
                setActivePage2(Page2)
                setisLoader(false);

            }
        })
    }

    const SetPage2 = (page: number) => {
        setPage(page);
        //setActivePage(page)
        //RefreshProducts();
        //setisLoader(true);
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        GetAProductsLoadByFilters(accid, filterobj, page, PageSize2, function (result: products[]) {
            if (result != null && result.length > 0) {
                setAProducts(result);
                setTotalCount2(result[0].totalRecord);
                setPage2(result[0].index);
                setPageSize2(result[0].recordPerPage);
                setActivePage2(page);
                // setisLoader(false);

            }
            else {
                setAProducts(result);
                setTotalCount2(0);
            }

            let load: Loadermessage = {
                message: "",
                isLoader: false,
                isSuccess: false,
                isfailed: false
            }
            Setisloader(load);
        })
    }

    const RefreshAProducts = () => {
        debugger;
        setisLoader(true);
        GetAProductsLoad(accid, sku, alocationid, abranchid, activestatus, supplierId, prdgrp, pricemax, pricemin, mfg, 1, 50, function (result: products[]) {
            if (result != null) {
                setAProducts(result);
                setTotalCount2(result[0].totalRecord)
                setPage2(result[0].index);
                setPageSize2(result[0].recordPerPage)
                setActivePage2(Page2);
                setisLoader(false);

            }
        })
    }


    const backUserDB = () => {
        setmagentoConnect(false);
        setamazonMp(false);
        Globals.IsAmazon = false;
        Globals.IsMagento = false;
        history.push('/userdashboard');
    }
    const changeSearchSku = (e: any) => {
        setsku(e.target.value);
        console.log(sku);

    }
    const changelocation1 = (e: any) => {
        let locid = e.target.value;
        setabranchid(locid);
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        GetAProductsLoad(accid, sku, e.target.value, abranchid, activestatus, supplierId, prdgrp, pricemax, pricemin, mfg, Page2, PageSize2, function (result: products[]) {

            if (result != null) {
                setAProducts(result);
                setTotalCount2(result[0].totalRecord)
                setPage2(result[0].index);
                setPageSize2(result[0].recordPerPage)
                setActivePage2(Page2)
                let load: Loadermessage = {
                    message: "",
                    isLoader: false,
                    isSuccess: false,
                    isfailed: false
                }
                Setisloader(load);
            }
        })

    }
    const changebranch1 = (e: any) => {
        let branchid = e.target.value;
        setabranchid(branchid);
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        GetAProductsLoad(accid, sku, alocationid, e.target.value, activestatus, supplierId, prdgrp, pricemax, pricemin, mfg, Page2, PageSize2, function (result: products[]) {

            if (result != null) {
                setAProducts(result);
                setTotalCount2(result[0].totalRecord)
                setPage2(result[0].index);
                setPageSize2(result[0].recordPerPage)
                setActivePage2(Page2)
                let load: Loadermessage = {
                    message: "",
                    isLoader: false,
                    isSuccess: false,
                    isfailed: false
                }
                Setisloader(load);
            }
        })

    }
    const SearchValues = (e: any) => {
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        setisLoader(true);
        GetAProductsLoad(accid, sku, alocationid, abranchid, activestatus, supplierId, prdgrp, pricemax, pricemin, mfg, Page2, PageSize2, function (result: products[]) {
            let load: Loadermessage = {
                message: "",
                isLoader: false,
                isSuccess: false,
                isfailed: false
            }
            if (result != null && result.length > 0) {
                setAProducts(result);
                setTotalCount2(result[0].totalRecord)
                setPage2(result[0].index);
                setPageSize2(result[0].recordPerPage)
                setActivePage2(Page2)

            }
            else {
                setAProducts(result);
                setTotalCount2(result[0].totalRecord);
            }
            setisLoader(false);
        })
    }

    const handleActiveClick = (id: number, e: any) => {
        const { value, checked } = e.target;
        confirmAlert({
            title: 'Update Product Status',
            message: 'Pls confirm to Update Status ?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => Inactiveproduct(id, checked)
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    }
    const Inactiveproduct = (id: number, ischk: boolean) => {

        let tmpproduct = products.map((mp) => mp.id === Number(id) ? { ...mp, isActive: ischk } : mp);
        setProducts(tmpproduct);

        debugger;
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        Inactivempproduct(id, ischk, function (result: any) {
            if (result.isSuccess) {
                let result = CreateinitialLoadermessage();
                result.isSuccess = true;
                result.message = "product active status updated successfully"
                Setisloader(result);
                RefreshAProducts();
            }
        })
    }
    const handleAllClick = (e: any) => {
        const { value, checked } = e.target;
        let tmpproduct = products.map((mp) => {
            return { ...mp, isActive: checked };
        });
        setProducts(tmpproduct);
        confirmAlert({
            title: 'Update Product Status',
            message: 'Pls confirm to Update Status ?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => Inactiveallproduct(checked)
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });

    }


    const Inactiveallproduct = (ischk: boolean) => {
        debugger;
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        UpdateActiveProduct(accid, sku, alocationid, abranchid, activestatus, ischk, Page2, PageSize2, function (result: any) {
            let result1 = CreateinitialLoadermessage();
            result1.isSuccess = true;
            result1.message = "product active status updated successfully"
            Setisloader(result1);
            RefreshAProducts();

        })
    }
    const searchisactive = (e: any) => {
        const { value, checked } = e.target;
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        setactivestatus(e.target.value);
        GetAProductsLoad(accid, sku, alocationid, abranchid, e.target.value, supplierId, prdgrp, pricemax, pricemin, mfg, Page2, PageSize2, function (result: products[]) {

            if (result != null) {
                setAProducts(result);
                setTotalCount2(result[0].totalRecord)
                setPage2(result[0].index);
                setPageSize2(result[0].recordPerPage)
                setActivePage2(Page2)
                let load: Loadermessage = {
                    message: "",
                    isLoader: false,
                    isSuccess: false,
                    isfailed: false
                }
                Setisloader(load);
            }
        })

    }
    const changeSupplierId = (e: any) => {
        debugger;
        let supplierid = e.target.value;
        setsupplierId(supplierid);
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        setisLoader(true);
        GetAProductsLoad(accid, sku, alocationid, abranchid, activestatus, e.target.value, prdgrp, pricemax, pricemin, mfg, Page2, PageSize2, function (result: products[]) {
            let load: Loadermessage = {
                message: "",
                isLoader: false,
                isSuccess: false,
                isfailed: false
            }
            if (result != null && result.length > 0) {
                setAProducts(result);
                setTotalCount2(result[0].totalRecord)
                setPage2(result[0].index);
                setPageSize2(result[0].recordPerPage)
                setActivePage2(Page2);
                let load: Loadermessage = {
                    message: "",
                    isLoader: false,
                    isSuccess: false,
                    isfailed: false
                }
                setisLoader(false);
            }
            else {
                setAProducts(result);
                setTotalCount2(0);
            }
            setisLoader(false);
        })
    }
    const changeprdgrp = (e: any) => {
        setprdgrp(e.target.value);
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        setisLoader(true);
        GetAProductsLoad(accid, sku, alocationid, abranchid, activestatus, supplierId, e.target.value, pricemax, pricemin, mfg, Page2, PageSize2, function (result: products[]) {
            let load: Loadermessage = {
                message: "",
                isLoader: false,
                isSuccess: false,
                isfailed: false
            }
            if (result != null && result.length > 0) {
                setAProducts(result);
                setTotalCount2(result[0].totalRecord)
                setPage2(result[0].index);
                setPageSize2(result[0].recordPerPage)
                setActivePage2(Page2);
                let load: Loadermessage = {
                    message: "",
                    isLoader: false,
                    isSuccess: false,
                    isfailed: false
                }
                setisLoader(false);
            }
            else {
                setAProducts(result);
                setTotalCount2(0);
            }
            setisLoader(false);
        })
    }
    const changeprimax = (e: any) => {
        setpricemax(e.target.value);
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        setisLoader(true);
        GetAProductsLoad(accid, sku, alocationid, abranchid, activestatus, supplierId, prdgrp, e.target.value, pricemin, mfg, Page2, PageSize2, function (result: products[]) {
            let load: Loadermessage = {
                message: "",
                isLoader: false,
                isSuccess: false,
                isfailed: false
            }
            if (result != null && result.length > 0) {
                setAProducts(result);
                setTotalCount2(result[0].totalRecord)
                setPage2(result[0].index);
                setPageSize2(result[0].recordPerPage)
                setActivePage2(Page2);
                let load: Loadermessage = {
                    message: "",
                    isLoader: false,
                    isSuccess: false,
                    isfailed: false
                }
                setisLoader(false);
            }
            else {
                setAProducts(result);
                setTotalCount2(0);
            }
            setisLoader(false);
        })
    }
    const changeprimin = (e: any) => {
        setpricemin(e.target.value);
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        setisLoader(true);
        GetAProductsLoad(accid, sku, alocationid, abranchid, activestatus, supplierId, prdgrp, pricemax, e.target.value, mfg, Page2, PageSize2, function (result: products[]) {
            let load: Loadermessage = {
                message: "",
                isLoader: false,
                isSuccess: false,
                isfailed: false
            }
            if (result != null && result.length > 0) {
                setAProducts(result);
                setTotalCount2(result[0].totalRecord)
                setPage2(result[0].index);
                setPageSize2(result[0].recordPerPage)
                setActivePage2(Page2);
                let load: Loadermessage = {
                    message: "",
                    isLoader: false,
                    isSuccess: false,
                    isfailed: false
                }
                setisLoader(false);
            }
            else {
                setAProducts(result);
                setTotalCount2(0);
            }
            setisLoader(false);
        })
    }
    const changemfgname = (e: any) => {
        setmfg(e.target.value);
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        setisLoader(true);
        GetAProductsLoad(accid, sku, alocationid, abranchid, activestatus, supplierId, prdgrp, pricemax, pricemin, e.target.value, Page2, PageSize2, function (result: products[]) {
            let load: Loadermessage = {
                message: "",
                isLoader: false,
                isSuccess: false,
                isfailed: false
            }
            if (result != null && result.length > 0) {
                setAProducts(result);
                setTotalCount2(result[0].totalRecord)
                setPage2(result[0].index);
                setPageSize2(result[0].recordPerPage)
                setActivePage2(Page2);
                let load: Loadermessage = {
                    message: "",
                    isLoader: false,
                    isSuccess: false,
                    isfailed: false
                }
                setisLoader(false);
            }
            else {
                setAProducts(result);
                setTotalCount2(0);
            }
            setisLoader(false);
        })
    }


    const handleEditClick = (e: any, item: any) => {
        e.preventDefault();
        setEditContactId(item.id);

        const formValues = {
            id: item.id,
            sku: item.sku,
            uom: item.uom,
            displayDescription: item.displayDescription,
            msku: item.msku,
            asin: item.asin,
            touom: item.touom,
            brand: item.brand,
            pgroup: item.pgroup,
            pmax: item.pmax,
            pmin: item.pmin,
            pmfg: item.pmfg
        };

        setEditFormData(formValues);
    };


    const handleEditFormSubmit = (e: any) => {
        e.preventDefault();
        const editedContact = {
            id: editContactId,
            sku: editFormData.sku,
            uom: editFormData.uom,
            displayDescription: editFormData.displayDescription,
            msku: editFormData.msku,
            asin: editFormData.asin,
            touom: editFormData.touom,
            brand: editFormData.brand,
            pgroup: editFormData.pgroup,
            pmax: editFormData.pmax,
            pmin: editFormData.pmin,
            pmfg: editFormData.pmfg
        };
        setisLoader(true);
        SaveEntitybyAPID(accid, Number(editedContact.id), editFormData.sku, editFormData.uom, editFormData.displayDescription, editFormData.msku, editFormData.asin, editFormData.touom, editFormData.brand, editFormData.pgroup, editFormData.pmax, editFormData.pmin, editFormData.pmfg, function (result: any) {
            setisLoader(false);

        });
        aproducts.forEach(element => {
            if (element.id === editContactId) {

                element.uom = editFormData.uom;
                element.touom = editFormData.touom;
            }
        });
        // setintRole(iroles);
        //newContacts[index] = editedContact;

        setAProducts(aproducts);
        setEditContactId(null);
        setisLoader(false);
    };

    const handleCancelClick = () => {
        setEditContactId(null);
    };

    const handleDeleteClick = (id: number) => {
        //const newContacts = [...contacts];

        //const index = contacts.findIndex((contact) => contact.id === contactId);

        //newContacts.splice(index, 1);

        //setContacts(newContacts);
    };
    const handleEditFormChange = (e: any) => {
        e.preventDefault();

        const fieldName = e.target.getAttribute("name");
        const fieldValue = e.target.value;

        const newFormData = { ...editFormData };
        if (fieldName == "sku") {
            newFormData.sku = fieldValue;
        }
        else if (fieldName == "uom") {
            newFormData.uom = fieldValue;
        }
        else if (fieldName == "description") {
            newFormData.displayDescription = fieldValue;
        }
        else if (fieldName == "mfg") {
            newFormData.msku = fieldValue;

        }
        else if (fieldName == "asin") {
            newFormData.asin = fieldValue;

        }
        else if (fieldName == "touom") {
            newFormData.touom = fieldValue;

        }
        else if (fieldName == "brand") {
            newFormData.brand = fieldValue;

        }
        else if (fieldName == "pgroup") {
            newFormData.pgroup = fieldValue;

        }
        else if (fieldName == "pmax") {
            newFormData.pmax = fieldValue;

        }
        else if (fieldName == "pmin") {
            newFormData.pmin = fieldValue;

        }
        else if (fieldName == "pmfg") {
            newFormData.pmfg = fieldValue;
        }
        setEditFormData(newFormData);
    };
    const handleAddFormSubmit = (e: any) => {
        e.preventDefault();
        const addForm = {
            id: 0,
            sku: addFormData.sku,
            uom: addFormData.uom,
            asin: addFormData.asin,
            touom: addFormData.touom
        };
        setisLoader(true);
        if (addFormData.sku == "") {
            let result = CreateinitialLoadermessage()
            result.isfailed = true;
            result.message = "Please enter the Sku"
            Setisloader(result);
            setisLoader(false);
        }
        else if (addFormData.uom == "") {
            let result = CreateinitialLoadermessage()
            result.isfailed = true;
            result.message = "Please enter the uom"
            Setisloader(result);
            setisLoader(false);
        }
        else if (addFormData.asin == "") {
            let result = CreateinitialLoadermessage()
            result.isfailed = true;
            result.message = "Please enter the Customer ReferenceId"
            Setisloader(result);
            setisLoader(false);
        }
        else if (addFormData.touom == "") {
            let result = CreateinitialLoadermessage()
            result.isfailed = true;
            result.message = "Please enter the ToUom"
            Setisloader(result);
            setisLoader(false);
        }
        else if (addFormData.sku != "" && addFormData.uom != "" && addFormData.asin != "" && addFormData.touom != "") {
            SaveEntitybyAPID(accid, 0, addFormData.sku, addFormData.uom, "", "", addFormData.asin, addFormData.touom, "", "", "", "", "", function (result: any) {
                GetAProductsLoad(accid, sku, alocationid, abranchid, activestatus, supplierId, prdgrp, pricemax, pricemin, mfg, Page, PageSize, function (result: products[]) {
                    let load: Loadermessage = {
                        message: "",
                        isLoader: false,
                        isSuccess: false,
                        isfailed: false
                    }
                    if (result != null && result.length > 0) {
                        setAProducts(result);
                        setTotalCount2(result[0].totalRecord)
                        setPage2(result[0].index);
                        setPageSize2(result[0].recordPerPage)
                        setActivePage2(Page2)

                    }
                    setisLoader(false);
                    SetisShowpopup(!isShowpopup);

                })

            });
            //setisLoader(false);
        }

        let result = addFormData
        result.sku = "";
        result.uom = "";
        result.asin = "";
        result.touom = "";
        setAddFormData(result);

    };
    const [isShowpopup, SetisShowpopup] = useState(false);
    const OpenClosePopUp = (id: number) => {
        SetisShowpopup(!isShowpopup);
    }
    const handleAddFormChange = (e: any) => {
        e.preventDefault();
        const fieldName = e.target.getAttribute("name");
        const fieldValue = e.target.value;

        const newFormData = { ...addFormData };
        if (fieldName == "sku") {
            newFormData.sku = fieldValue;
        }
        else if (fieldName == "uom") {
            newFormData.uom = fieldValue;
        }
        else if (fieldName == "ToUOM") {
            newFormData.touom = fieldValue;
        }
        else if (fieldName == "CustRefID") {
            newFormData.asin = fieldValue;

        }
        //newFormData[fieldName] = fieldValue;

        setAddFormData(newFormData);
    };
    const changeFilters = (id: number, name: string, e: any) => {
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);

        debugger;
        var ifilter = [...filterobj];
        ifilter.forEach(element => {
            if (element.id === id) {
                if (name == "SKU") {
                    element.value1 = fsku

                } else {
                    element.value1 = e.target.value;
                }
            }
        });

        setfilterobj(ifilter);
        //GetProductsLoadbyFilter(accid, filterobj, Page, PageSize, function (result: products[]) {
        //    debugger;
        //    let load: Loadermessage = {
        //        message: "",
        //        isLoader: false,
        //        isSuccess: false,
        //        isfailed: false
        //    }
        //    if (result != null && result.length>0) {
        //        setProducts(result);
        //        setTotalCount(result[0].totalRecord)
        //        setPage(result[0].index);
        //        setPageSize(result[0].recordPerPage)
        //        setActivePage(Page)
        //    }
        //    else {
        //        setProducts(result);
        //        setTotalCount(0);
        //        let load: Loadermessage = {
        //            message: "",
        //            isLoader: false,
        //            isSuccess: false,
        //            isfailed: false
        //        }
        //        Setisloader(load);
        //    }
        //    Setisloader(load);
        //})
        let load1: Loadermessage = {
            message: "",
            isLoader: false,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load1);
    }
    const ApplyRules = (e: any) => {
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);


        var ifilter = [...filterobj];
        ifilter.forEach(element => {
            if (element.code === "SKU") {
                element.value1 = fsku;
                element.value2 = tsku;
            }
        });
        setfilterobj(ifilter);
        GetAProductsLoadByFilters(accid, filterobj, Page2, PageSize2, function (result: products[]) {
            let load: Loadermessage = {
                message: "",
                isLoader: false,
                isSuccess: false,
                isfailed: false
            }
            if (result != null && result.length > 0) {
                setAProducts(result);
                setTotalCount2(result[0].totalRecord)
                setPage2(result[0].index);
                setPageSize2(result[0].recordPerPage)
                setActivePage2(Page2)
                Setisloader(load);


            } else {
                setAProducts(result);
                setTotalCount2(0);
                Setisloader(load);

            }
        })

    }

    const SyncAllOpenClosePopUp = (id: number) => {
        //GetMPLatestSyncDate(function (result: Date) {
        //    if (result != null) {
        //        var momentDate = moment(result);
        //        // alert(new Date(result));
        //        SetselectdeltaDate(new Date(result));
        //        let syncTime = moment(result).format("HH:mm");
        //        setSelectedTime(syncTime);
        //        setBindDeltaDate(true);
        //    }
        //    else {
        //        setBindDeltaDate(false);
        //    }

        //})

        SetisShowpopup(!isShowpopup);
    }

    const DownloadAProduct = () => {
        if (filterobj != null) {
            try {
                let load: Loadermessage = {
                    message: "",
                    isLoader: true,
                    isSuccess: false,
                    isfailed: false
                }
                Setisloader(load);
                debugger;
                const data = new FormData();
                data.append('filters', JSON.stringify(filterobj));
                data.append('ACCID', accid);

                const response = axios({
                    method: "post",
                    url: "api/MP/DownloadAProduct",
                    data: data,
                    headers: { "Content-Type": "multipart/form-data" },
                    responseType: 'blob',
                }).then((result) => {

                    let resultmes = CreateinitialLoadermessage();
                    if (result != null) {
                        debugger;
                        const url = window.URL.createObjectURL(new Blob([result.data]));
                        const a = document.createElement('a');
                        a.href = url;
                        a.setAttribute('download', "AssociateProducts.csv");
                        document.body.appendChild(a);
                        a.click();
                        a.remove();
                        URL.revokeObjectURL(url);


                        // const url = URL.createObjectURL(result.data);
                        // const a = document.createElement('a');
                        // a.href = url;
                        // a.setAttribute('download', "AssociateProducts.xlsx");
                        // document.body.appendChild(a);
                        // a.click();
                        // document.body.removeChild(a);
                        // URL.revokeObjectURL(url);

                    }
                    resultmes.isLoader = false;
                    Setisloader(resultmes);
                });
                //Setisloader(load);
            } catch (error) {
                console.log(error)
            }
        }
        else {
            let load: Loadermessage = {
                message: "Select Atleast one filter and download",
                isLoader: false,
                isSuccess: false,
                isfailed: true
            }
            Setisloader(load);
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

        }
    }
    const [aquaShipMethod, setAquaShipMethod] = useState("");
    const [oldCustShip, setOldCustShip] = useState("");
    const [customerShipMethod, setCustomerShipMethod] = useState("");
    const [newAquaShip, setNewAquaShip] = useState("");
    const [newCustomerShip, setNewCustomerShip] = useState("");
    const refreshShipping = () => {
        GetShippingMethods(Page, PageSize, accid, function (result: shippingMethodModel[]) {
            if (result != null) {
                setShippingData(result);
                setTotalCount(result[0].totalRecord)
                setPage(result[0].Index);
                setPageSize(result[0].RecordPerPage)
                //setActivePage(Page);
                //  SetisLoader(false);
            }
            // console.log("shipmethods....", result)
        })

    }
    const EditClick = (aquaShip: any, customerShip: any) => {
        setactivesetupclass(false);
        setactiveimportclass(false);
        setactivelocclass(false);
        setactiveuomclass(false);
        setactiveguomclass(false);
        setglobalUOM(false);
        setactiveShippingClass(false);
        setactiveEditView(true);
        setaddView(false);
        setAquaShipMethod(aquaShip);
        setCustomerShipMethod(customerShip);

        console.log("editClicked....", activeeditView);

    }
    const CloseEditClick = () => {
        setactivesetupclass(false);
        setactiveimportclass(false);
        setactivelocclass(false);
        setactiveuomclass(false);
        setactiveguomclass(false);
        setglobalUOM(false);
        setactiveShippingClass(true);
        setactiveEditView(false);
        setaddView(false);
    }
    const AddViewClick = () => {
        setactivesetupclass(false);
        setactiveimportclass(false);
        setactivelocclass(false);
        setactiveuomclass(false);
        setactiveguomclass(false);
        setglobalUOM(false);
        setactiveShippingClass(false);
        setactiveEditView(false);
        setaddView(true);
    }
    const ChangeCustomerShipMethod = (e: any) => {

        console.log("ChangeCustomerShipMethod....", e.target.value);
        setCustomerShipMethod(e.target.value);

    }

    const EditSaveShippingMethod = () => {
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }

        const updatedData = shippingData;
        updatedData.map((item, index) => (
            item.MAID = accid
        ))

        updatedData.map((item, index) => (
            item.aquaShipMethod === aquaShipMethod ? item.customerShipMethod = customerShipMethod : null,
            item.aquaShipMethod === aquaShipMethod ? item.MAID = accid : null
        ))

        console.log("updatedD....", updatedData)

        // if (customerShipMethod != "") {
        setisLoader(true);
        EditandSaveShippingMethod(updatedData, function (result: any) {
            console.log(result)
            if (result != null || result != "") {
                let results = CreateinitialLoadermessage();
                results.isSuccess = true;
                results.message = "saved successfully";
                setEditShipName("");
                // setAquaShipMethod("");
                //setCustomerShipMethod("");
                Setisloader(results);
                // setactiveEditView(false);
                // setactiveShippingClass(true)
                refreshShipping();


            }

        })

        //}
        //else {
        //    let results = CreateinitialLoadermessage();
        //    results.isSuccess = true;
        //    results.message = "Please enter customer ship method"
        //    Setisloader(results);

        //}
    }

    const AddNewAquaship = (e: any) => {
        setNewAquaShip(e.target.value);

    }
    const AddnewCustomerShip = (e: any) => {
        setNewCustomerShip(e.target.value);
    }
    const SaveNewShippingMethods = (e: any) => {
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        const NewShippingObj = {
            MAID: accid,
            aquaShipMethod: newAquaShip,
            customerShipMethod: newCustomerShip,
            Index: 0,
            RecordPerPage: 0,
            totalRecord: 0,
        };
        console.log("NewShippingObj....", NewShippingObj)


        const NewShippingData = shippingData.map(item => item); // Create a shallow copy of the existing array

        NewShippingData.map((item, index) => (
            item.MAID = accid
        ))
        const isAquaShipMethodAvailable = NewShippingData.some(item => {
            return item.aquaShipMethod === NewShippingObj.aquaShipMethod;
        });
        const isCustomerShipMethodAvailable = NewShippingData.some(item => {
            return item.customerShipMethod === NewShippingObj.customerShipMethod;
        });

        if (isAquaShipMethodAvailable) {
            alert("Aqua ship already available")

        } else if (isCustomerShipMethodAvailable) {
            alert("customer ship already available")
        } else {
            NewShippingData.push(NewShippingObj);
        }
        console.log("NewShippingData..", NewShippingData)
        if (newAquaShip != "" && newCustomerShip != "") {

            setisLoader(true);
            EditandSaveShippingMethod(NewShippingData, function (result: any) {
                console.log(result)
                if (result != null || result != "") {
                    let results = CreateinitialLoadermessage();
                    results.isSuccess = true;
                    results.isfailed = false
                    results.message = "saved successfully"
                    Setisloader(results);
                    setaddView(false);
                    setactiveShippingClass(true);
                    setNewAquaShip("");
                    setNewCustomerShip("");
                    //  window.location.reload();
                    refreshShipping();
                    setisLoader(false);

                }


            })


        } else {
            let results = CreateinitialLoadermessage();
            results.isSuccess = false;
            results.isfailed = true;
            results.message = "Fields should not be empty";
            Setisloader(results);

        }

    }
    const DeleteAlert = (aquaShip: any, customerShip: any) => {
        confirmAlert({
            title: 'Delete Shipping Method',
            message: 'Are you sure you want to delete shipping method?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => DeleteShippingMethods(aquaShip)
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    }

    const DeleteShippingMethods = (aquaShip: any) => {


        shippingData.map((item, index) => (
            item.MAID = accid
        ))

        const filteredData = shippingData.filter(item => item.aquaShipMethod !== aquaShip);


        console.log("afterDelete....", filteredData)

        setisLoader(true);
        debugger;

        if (filteredData.length == 0) {
            let ship: shippingMethodModel = {
                MAID: accid,
                aquaShipMethod: "",
                customerShipMethod: "",
                Index: 0,
                RecordPerPage: 0,
                totalRecord: 0
            };
            var shippingMethod2: shippingMethodModel[] = new Array<shippingMethodModel>();
            shippingMethod2.push(ship);
            // setShippingData1(shippingMethod2);
            EditandSaveShippingMethod(shippingMethod2, function (result: any) {
                console.log(result)
                if (result != null || result != "") {
                    let results = CreateinitialLoadermessage();
                    results.isSuccess = true;
                    results.isfailed = false
                    results.message = "deleted successfully"
                    Setisloader(results);
                    refreshShipping();
                    // setisLoader(false)
                    // setactiveShippingClass(true);
                    // setaddView(false);
                }


            })

        }
        else {
            EditandSaveShippingMethod(filteredData, function (result: any) {
                console.log(result)
                if (result != null || result != "") {
                    let results = CreateinitialLoadermessage();
                    results.isSuccess = true;
                    results.isfailed = false
                    results.message = "deleted successfully"
                    Setisloader(results);
                    refreshShipping();
                    // setisLoader(false)
                    // setactiveShippingClass(true);
                    // setaddView(false);
                }


            })

        }



    }
    const [isshipPopUp, setIsShipPopUp] = useState(false);
    const OpenCloseAddShippingPopup = () => {
        setIsShipPopUp(!isshipPopUp);

    }
    const handleAddShipFormChange = (e: any) => {
        e.preventDefault();
        const fieldName = e.target.getAttribute("name");
        const fieldValue = e.target.value;



        if (fieldName == "aqua") {
            setNewAquaShip(fieldValue)
            console.log("fieldValueaqua....", fieldValue)
        }
        else if (fieldName == "cust") {

            setNewCustomerShip(fieldValue);
            console.log("custfieeld....", fieldValue)
        }

    }
    const handleAddShippingFormSubmit = (e: any) => {
        e.preventDefault();
        const NewShippingObj = {
            MAID: accid,
            aquaShipMethod: newAquaShip,
            customerShipMethod: newCustomerShip,
            Index: 0,
            RecordPerPage: 0,
            totalRecord: 0,
        };
        setisLoader(true);
        if (NewShippingObj.aquaShipMethod == "") {
            let result = CreateinitialLoadermessage()
            result.isfailed = true;
            result.message = "Please enter the Aqua Ship Method"
            Setisloader(result);
            setisLoader(false);
        }
        // else if (NewShippingObj.customerShipMethod == "") {
        //     let result = CreateinitialLoadermessage()
        //     result.isfailed = true;
        //     result.message = "Please enter the Customer Ship Method"
        //     Setisloader(result);
        //     setisLoader(false);
        // }
        const NewShippingData = shippingData.map(item => item); // Create a shallow copy of the existing array

        NewShippingData.map((item, index) => (
            item.MAID = accid
        ))
        const isAquaShipMethodAvailable = NewShippingData.some(item => {
            return item.aquaShipMethod === NewShippingObj.aquaShipMethod;
        });
        const isCustomerShipMethodAvailable = NewShippingData.some(item => {
            return item.customerShipMethod === NewShippingObj.customerShipMethod;
        });

        if (isAquaShipMethodAvailable) {
            alert("Aqua ship already available");
            return;


        } else if (isCustomerShipMethodAvailable) {
            alert("customer ship already available");
            return;

        } else {
            NewShippingData.push(NewShippingObj);
        }
        if (newAquaShip != "") {
            EditandSaveShippingMethod(NewShippingData, function (result: any) {
                let load: Loadermessage = {
                    message: "",
                    isLoader: false,
                    isSuccess: false,
                    isfailed: false
                }
                if (result != null || result != "") {
                    let results = CreateinitialLoadermessage();
                    results.isSuccess = true;
                    results.isfailed = false
                    results.message = "saved successfully"
                    Setisloader(results);
                    setIsShipPopUp(!isshipPopUp);
                    setNewAquaShip("");
                    setNewCustomerShip("");
                    refreshShipping();
                    setisLoader(false);

                }


            })
        }



    };

    const handleSelect = (selectedList: any, selectedItem: any, filterId: any) => {
        console.log("selectedList....", selectedList)

        var ifilter = [...filterobj];

        ifilter.forEach(element => {
            if (element.id === filterId) {

                element.value1 = selectedList.join(',');
            }
        });
        setfilterobj(ifilter);
        console.log("selectedItem....", ifilter);
    }
    const handleRemove = (selectedList: any, selectedItem: any, filterId: any) => {

        var ifilter = [...filterobj];
        ifilter.forEach(element => {
            if (element.id === filterId) {
                element.value1 = "";
                element.value1 = selectedList.join(',');
            }
        });
        setfilterobj(ifilter);
        console.log("DeselectedItem....", ifilter);
    }
    const [isEditShipping, setIsEditShipping] = useState(false);
    const [editShipName, setEditShipName] = useState("");
    const EditShipClick = (aquaShip: any, customerShip: any) => {
        console.log("name....", name)
        setEditShipName(aquaShip);
        setAquaShipMethod(aquaShip);
        setCustomerShipMethod(customerShip);
        setIsEditShipping(true);

    }
    const handleCancelShipEditClick = () => {
        setEditShipName("");

    };


    return (
        <React.Fragment>
            {

                isloader.isLoader ? (<Loader isActive={isloader} />) : (null)
            }
            <div className="row">
                <div className="col-md-12">
                    <div className="mpc-menu">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/userdashboard"><i className="bi bi-house-door-fill pe-1"></i>Home</a></li>
                                <li className="breadcrumb-item" aria-current="page">
                                    <span>Settings</span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <div className="head-accno">
                                        Account No: <span>{accname}</span>
                                    </div>
                                </li>
                            </ol>
                        </nav>
                        <NFSideBar id={accid} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="heading-section">
                                <h1>Customer Account Settings</h1>

                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <ul className="nav nav-pills">
                                {/*{*/}
                                {/*    VUOMMapping == true || VAssProduct == true ?*/}
                                {/*        <li className="nav-item">*/}
                                {/*            <button className={inactiveclassname} id="4" onClick={handleChange}>UOM Mapping</button>*/}
                                {/*        </li> : (null)*/}
                                {/*}*/}
                                {/*{*/}
                                {/*    VAssProduct == true ?*/}
                                {/*        <li className="nav-item">*/}
                                {/*            <button className={activeimportclass == true ? activeclassname : inactiveclassname} id="2" onClick={handleChange}>Associate Products </button>*/}
                                {/*        </li> : (null)*/}
                                {/*}*/}
                                {/*{*/}
                                {/*    VWHMapping == true ?*/}
                                {/*        <li className="nav-item">*/}
                                {/*            <button className={inactiveclassname} id="3" onClick={handleChange}>Warehouse Mapping</button>*/}
                                {/*        </li> : (null)*/}
                                {/*}*/}

                                {/*{*/}
                                {/*    VSMMapping == true ?*/}
                                {/*        <li className="nav-item">*/}
                                {/*            <button className={inactiveclassname} aria-current="page" id="5" onClick={handleChange} >Shipping Methods</button>*/}
                                {/*        </li> : (null)*/}
                                {/*}*/}
                                {
                                    VApi == true ?
                                        <li className="nav-item">
                                            <button className={activeclassname} aria-current="page" id="1" onClick={handleChange} >Order Settings</button>
                                        </li> : (null)
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="tab-content">
                        <div className={globalUOM == true ? "" : "hidden"}>
                            <div className="row">
                                <div className="col-md-12">
                                    <ul className="nav nav-pills">
                                        {/*{*/}
                                        {/*    VAssProduct == true ?*/}
                                        {/*        <li className="nav-item">*/}
                                        {/*            <button className={activeimportclass == true ? activeclassname : inactiveclassname} id="2" onClick={handleChange}>Product UOM Mapping </button>*/}
                                        {/*        </li> : (null)*/}
                                        {/*}*/}
                                        {/*{*/}
                                        {/*    VUOMMapping == true ?*/}
                                        {/*        <li className="nav-item">*/}
                                        {/*            <button className={activeguomclass == true ? activeclassname : inactiveclassname} id="4" onClick={handleChange}>Global UOM Mapping</button>*/}
                                        {/*        </li> : (null)*/}
                                        {/*}*/}

                                    </ul>
                                </div>
                            </div>
                            {/*<div className="tab-content">*/}
                            {/*<div className={activeimportclass == true ? "" : "hidden"}>*/}
                            {/*    <div className="row">*/}
                            {/*        <div className="col-md-12">*/}
                            {/*            <div className="section-border-new pb-2">*/}
                            {/*                <div className="row">*/}
                            {/*                    <div className="col-sm-6 col-md-6">*/}
                            {/*                        */}{/*<span className="fs-5 fw-bold pe-2">*/}
                            {/*                        */}{/*    Account Number:*/}
                            {/*                        */}{/*</span>*/}
                            {/*                        */}{/*{*/}
                            {/*                        */}{/*    mpacclist.map((item, index) => (*/}
                            {/*                        */}{/*        //<option value={item.customerId}>{item.message}</option>*/}
                            {/*                        */}{/*        <span className="fs-5 fw-bold">{item.message}</span>*/}
                            {/*                        */}{/*    ))*/}
                            {/*                        */}{/*}*/}
                            {/*                        */}{/*<select className="form-select">*/}
                            {/*                        */}{/*    <option selected value={0}>Select</option>*/}
                            {/*                        */}{/*    {*/}
                            {/*                        */}{/*        mpacclist.map((item, index) => (*/}
                            {/*                        */}{/*            <option value={item.customerId}>{item.message}</option>*/}
                            {/*                        */}{/*        ))*/}
                            {/*                        */}{/*    }*/}
                            {/*                        */}{/*</select>*/}
                            {/*                        <form>*/}
                            {/*                            <div className="row">*/}
                            {/*                                <div className="col-md-8">*/}
                            {/*                                    <input className="form-control" type="text" value={filename} />*/}
                            {/*                                    <div className="form-text mt-2">*/}
                            {/*                                        */}{/* <a target="_blank" href="/Files/SampleImportProducts.xlsx" download>Download Sample File</a> */}
                            {/*                                        <a className="download-file" target="_blank" onClick={DownloadAProduct} download>Download Sample File</a>*/}

                            {/*                                    </div>*/}
                            {/*                                </div>*/}
                            {/*                                <div className="col-md-4">*/}
                            {/*                                    <div className="d-flex align-items-start">*/}
                            {/*                                        <input type="button" className="btn btn-primary me-2" onClick={OpenFileExplore} disabled={EAssProduct != true ? true : false} value="Browse & Upload" />*/}
                            {/*                                        */}{/*     <a target='_blank' href={`api/MP/MPExport?accountid=${accid}&&sku=${''}&&locationid=${0}&&branchid=${0}&&active=${false}`} type="button" className="btn btn-primary">Export</a>*/}
                            {/*                                    </div>*/}
                            {/*                                </div>*/}
                            {/*                            </div>*/}
                            {/*                            <input*/}
                            {/*                                ref={inputFile}*/}
                            {/*                                style={{ display: "none" }} onChange={handleFileUpload}*/}
                            {/*                                type="file"*/}
                            {/*                            />*/}
                            {/*                        </form>*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}

                            {/*            <div className="section-border-new">*/}
                            {/*                <h2 className="pb-2 primarycolor">Filters</h2>*/}
                            {/*                <div className="row">*/}
                            {/*                    {*/}
                            {/*                        filter != null ? (*/}
                            {/*                            filter.map((item, index) => (*/}

                            {/*                                item.ftid == 3 ? <div className="col-6 mb-2">*/}
                            {/*                                    <div className="row g-3 align-items-end">*/}
                            {/*                                        <div className="col-6">*/}
                            {/*                                            <label className="form-label">From SKU</label>*/}
                            {/*                                            <input type="text" onChange={changeFilterSku} placeholder="" className="form-control" value={fsku} />*/}
                            {/*                                        </div>*/}
                            {/*                                        <div className="col-6">*/}
                            {/*                                            <label className="form-label">To SKU</label>*/}
                            {/*                                            <input type="text" onChange={changeFilterTSku} placeholder="" className="form-control" value={tsku} />*/}
                            {/*                                        </div>*/}

                            {/*                                    </div>*/}
                            {/*                                </div> :*/}

                            {/*                                    //item.ftid == 2 ? <div className="col-6">*/}
                            {/*                                    //    <div className="row g-3 align-items-end">*/}
                            {/*                                    //        <div className="col-6">*/}
                            {/*                                    //            <label className="form-label">SKU</label>*/}
                            {/*                                    //            <input type="text" onChange={changeFilterSku} placeholder="" className="form-control" value={fsku} />*/}
                            {/*                                    //        </div>*/}
                            {/*                                    //        <div className="col-2">*/}
                            {/*                                    //            <button type="button" className="btn btn-outline-primary" onClick={(e) => changeFilters(item.id, item.name, e)}>Search</button>*/}
                            {/*                                    //        </div>*/}
                            {/*                                    //    </div>*/}
                            {/*                                    //</div> : */}

                            {/*                                    // item.ftid == 1 ? <div className="col-3 mb-2">*/}
                            {/*                                    //     <label className="form-label">*/}
                            {/*                                    //         {item.name}*/}
                            {/*                                    //     </label>*/}
                            {/*                                    //     <select className="form-select" onChange={(e) => changeFilters(item.id, item.name, e)}>*/}
                            {/*                                    //         <option selected value={""}>Allss</option>*/}
                            {/*                                    //         {*/}
                            {/*                                    //             item.filtersvalues != null ? (*/}
                            {/*                                    //                 item.filtersvalues.map((itemval, index) => (*/}
                            {/*                                    //                     <option value={itemval.key}>{itemval.value}</option>*/}
                            {/*                                    //                 ))*/}
                            {/*                                    //             ) : (null)*/}
                            {/*                                    //         }*/}
                            {/*                                    //     </select>*/}
                            {/*                                    // </div> : ""*/}

                            {/*                                    item.ftid == 1 ? <div className="col-3 mb-2">*/}
                            {/*                                        <label className="form-label">*/}
                            {/*                                            {item.name}*/}
                            {/*                                        </label>*/}
                            {/*                                        <Multiselect*/}
                            {/*                                            isObject={false}*/}
                            {/*                                            displayValue="name"*/}
                            {/*                                            placeholder="All"*/}

                            {/*                                            onKeyPressFn={function noRefCheck() { }}*/}
                            {/*                                            // onRemove={handleRemove}*/}
                            {/*                                            onRemove={(selectedList, selectedItem) => handleRemove(selectedList, selectedItem, item.id)}*/}
                            {/*                                            onSearch={function noRefCheck() { }}*/}
                            {/*                                            // onSelect={handleSelect}*/}
                            {/*                                            onSelect={(selectedList, selectedItem) => handleSelect(selectedList, selectedItem, item.id)}*/}


                            {/*                                            options={item.filtersvalues.map(x => x.value)}*/}
                            {/*                                        />*/}
                            {/*                                    </div> : ""*/}

                            {/*                            ))*/}
                            {/*                        ) : (null)*/}
                            {/*                    }*/}
                            {/*                    */}{/*<div className="col-4 mb-2">*/}
                            {/*                    */}{/*    <div className="row g-3 align-items-end">*/}
                            {/*                    */}{/*        <div className="col-9">*/}
                            {/*                    */}{/*            <label className="form-label">SKU/ Customer ReferenceID/ Mfg</label>*/}
                            {/*                    */}{/*            <input type="text" onChange={changeSearchSku} placeholder="" className="form-control" value={sku} />*/}
                            {/*                    */}{/*        </div>*/}
                            {/*                    */}{/*        <div className="col-3">*/}
                            {/*                    */}{/*            <button type="button" className="btn btn-outline-primary" onClick={SearchValues}>Search</button>*/}
                            {/*                    */}{/*        </div>*/}
                            {/*                    */}{/*    </div>*/}
                            {/*                    */}{/*</div>*/}
                            {/*                    */}{/*<div className="col-4 mb-2">*/}
                            {/*                    */}{/*    <label className="form-label">*/}
                            {/*                    */}{/*        Supplier*/}
                            {/*                    */}{/*    </label>*/}
                            {/*                    */}{/*    <select className="form-select" onChange={(e) => changeSupplierId(e)}>*/}
                            {/*                    */}{/*        <option selected value={""}>Select</option>*/}
                            {/*                    */}{/*        {*/}
                            {/*                    */}{/*            suppList.map((item, index) => (*/}
                            {/*                    */}{/*                <option value={item.entityId}>{item.entityName}</option>*/}
                            {/*                    */}{/*            ))*/}
                            {/*                    */}{/*        }*/}
                            {/*                    */}{/*    </select>*/}
                            {/*                    */}{/*</div>*/}
                            {/*                    */}{/*<div className="col-4 mb-2">*/}
                            {/*                    */}{/*    <label className="form-label">*/}
                            {/*                    */}{/*        Product Group*/}
                            {/*                    */}{/*    </label>*/}
                            {/*                    */}{/*    <select className="form-select" onChange={(e) => changeprdgrp(e)}>*/}
                            {/*                    */}{/*        <option selected value={""}>Select</option>*/}
                            {/*                    */}{/*        {*/}
                            {/*                    */}{/*            Prdgrplist.map((item, index) => (*/}
                            {/*                    */}{/*                <option value={item.name}>{item.name}</option>*/}
                            {/*                    */}{/*            ))*/}
                            {/*                    */}{/*        }*/}
                            {/*                    */}{/*    </select>*/}
                            {/*                    */}{/*</div>*/}
                            {/*                    */}{/*<div className="col-4 mb-2">*/}
                            {/*                    */}{/*    <label className="form-label">*/}
                            {/*                    */}{/*        Price Max*/}
                            {/*                    */}{/*    </label>*/}
                            {/*                    */}{/*    <select className="form-select" onChange={(e) => changeprimax(e)}>*/}
                            {/*                    */}{/*        <option selected value={""}>Select</option>*/}
                            {/*                    */}{/*        {*/}
                            {/*                    */}{/*            Pricemaxlist.map((item, index) => (*/}
                            {/*                    */}{/*                <option value={item.name}>{item.name}</option>*/}
                            {/*                    */}{/*            ))*/}
                            {/*                    */}{/*        }*/}
                            {/*                    */}{/*    </select>*/}
                            {/*                    */}{/*</div>*/}
                            {/*                    */}{/*<div className="col-4 mb-2">*/}
                            {/*                    */}{/*    <label className="form-label">*/}
                            {/*                    */}{/*        Price Min*/}
                            {/*                    */}{/*    </label>*/}
                            {/*                    */}{/*    <select className="form-select" onChange={(e) => changeprimin(e)}>*/}
                            {/*                    */}{/*        <option selected value={""}>Select</option>*/}
                            {/*                    */}{/*        {*/}
                            {/*                    */}{/*            Priceminlist.map((item, index) => (*/}
                            {/*                    */}{/*                <option value={item.name}>{item.name}</option>*/}
                            {/*                    */}{/*            ))*/}
                            {/*                    */}{/*        }*/}
                            {/*                    */}{/*    </select>*/}
                            {/*                    */}{/*</div>*/}
                            {/*                    */}{/*<div className="col-4 mb-2">*/}
                            {/*                    */}{/*    <label className="form-label">*/}
                            {/*                    */}{/*        Manufacture*/}
                            {/*                    */}{/*    </label>*/}
                            {/*                    */}{/*    <select className="form-select" onChange={(e) => changemfgname(e)}>*/}
                            {/*                    */}{/*        <option selected value={""}>Select</option>*/}
                            {/*                    */}{/*        {*/}
                            {/*                    */}{/*            mfglist.map((item, index) => (*/}
                            {/*                    */}{/*                <option value={item.name}>{item.name}</option>*/}
                            {/*                    */}{/*            ))*/}
                            {/*                    */}{/*        }*/}
                            {/*                    */}{/*    </select>*/}
                            {/*                    */}{/*</div>*/}
                            {/*                    */}{/*<div className="col-4">*/}
                            {/*                    */}{/*    <div className="form-check">*/}
                            {/*                    */}{/*        <label className="form-label">*/}
                            {/*                    */}{/*            Is Active*/}
                            {/*                    */}{/*        </label>*/}
                            {/*                    */}{/*        <select className="form-select" aria-label="Default select example" onChange={searchisactive}>*/}
                            {/*                    */}{/*            <option selected>Select</option>*/}
                            {/*                    */}{/*            <option value="2">All</option>*/}
                            {/*                    */}{/*            <option value="1">Active</option>*/}
                            {/*                    */}{/*            <option value="0">InActive</option>*/}
                            {/*                    */}{/*        </select>*/}
                            {/*                    */}{/*        */}{/*    <input className="form-check-input" type="checkbox" name="entities" value="" onChange={(e) => searchisactive(e)} />*/}
                            {/*                    */}{/*    </div>*/}
                            {/*                    */}{/*</div>*/}


                            {/*                    */}{/*<div className="col-3">*/}
                            {/*                    */}{/*    <label className="form-label">*/}
                            {/*                    */}{/*        Warehouse*/}
                            {/*                    */}{/*    </label>*/}
                            {/*                    */}{/*    <select className="form-select" onChange={(e) => changelocation1(e)}>*/}
                            {/*                    */}{/*        <option selected value={0}>Select</option>*/}
                            {/*                    */}{/*        {*/}
                            {/*                    */}{/*            loclist1.map((item, index) => (*/}
                            {/*                    */}{/*                <option value={item.resultid}>{item.name}</option>*/}
                            {/*                    */}{/*            ))*/}
                            {/*                    */}{/*        }*/}
                            {/*                    */}{/*    </select>*/}
                            {/*                    */}{/*</div>*/}
                            {/*                    */}{/*<div className="col-3">*/}
                            {/*                    */}{/*    <label className="form-label">*/}
                            {/*                    */}{/*        Branch*/}
                            {/*                    */}{/*    </label>*/}
                            {/*                    */}{/*    <select className="form-select" onChange={(e) => changebranch1(e)}>*/}
                            {/*                    */}{/*        <option selected value={0}>Select</option>*/}
                            {/*                    */}{/*        {*/}
                            {/*                    */}{/*            branchlist1.map((item, index) => (*/}
                            {/*                    */}{/*                <option value={item.resultid}>{item.name}</option>*/}
                            {/*                    */}{/*            ))*/}
                            {/*                    */}{/*        }*/}
                            {/*                    */}{/*    </select>*/}
                            {/*                    */}{/*</div>*/}
                            {/*                </div>*/}
                            {/*                <div className="row">*/}
                            {/*                    <div className="col-12 mb-3">*/}
                            {/*                        <div className="d-flex justify-content-end align-items-center">*/}
                            {/*                            <button type="button" className="btn btn-wh-1 btn-primary" disabled={EAssProduct != true ? true : false} onClick={(e) => ApplyRules(e)}>Filter</button>*/}
                            {/*                            <button type="button" className="btn btn-wh-1 btn-primary ms-2" onClick={resetPage}>Reset</button>*/}
                            {/*                        </div>*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}

                            {/*            <div className="row">*/}
                            {/*                <div className="col-8 mb-3">*/}
                            {/*                    <h2>Associated Products</h2>*/}
                            {/*                </div>*/}
                            {/*                <div className="col-4 mb-3">*/}
                            {/*                    <div className="d-flex justify-content-end align-items-center" aria-disabled={EAssProduct != true ? true : false}>*/}
                            {/*                        */}{/*<input className="btn btn-primary btn-wh-1" type="button" onClick={(e) => SyncAllOpenClosePopUp(0)} value="Add Product" />*/}
                            {/*                        */}{/*    <a target='_blank' href={`api/MP/MAPExport?accountid=${accid}&&sku=${sku}&&locationid=${locationid}&&branchid=${branchid}&&activestatus=${activestatus}&&suppkey=${supplierId}`} type="button" className="btn btn-primary btn-wh-1 ms-2">Download</a>*/}
                            {/*                        <a target="_blank" type="button" className="btn btn-wh-1 btn-primary float-end" onClick={DownloadAProduct} aria-disabled={EAssProduct != true ? true : false}>Download</a>*/}

                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*            <Pagenation3 />*/}
                            {/*            <form onSubmit={handleEditFormSubmit}>*/}
                            {/*                <div className="table-scroll-section">*/}
                            {/*                    <table className="table table-bordered managepartno-table">*/}
                            {/*                        <thead className="table-light sticky-top">*/}
                            {/*                            <tr>*/}
                            {/*                                <th className="col-th-011" scope="col">*/}
                            {/*                                    ID*/}
                            {/*                                </th>*/}
                            {/*                                <th className="col-th-022" scope="col">*/}
                            {/*                                    SKU*/}
                            {/*                                </th>*/}
                            {/*                                <th className="col-th-033" scope="col">*/}
                            {/*                                    Agility UOM*/}
                            {/*                                </th>*/}
                            {/*                                <th className="col-th-066" scope="col">*/}
                            {/*                                    Customer Reference ID*/}
                            {/*                                </th>*/}
                            {/*                                <th className="col-th-077" scope="col">*/}
                            {/*                                    Customer  UOM*/}
                            {/*                                </th>*/}
                            {/*                                <th className="col-th-044" scope="col">*/}
                            {/*                                    Item Name*/}
                            {/*                                </th>*/}
                            {/*                                <th className="col-th-055" scope="col">*/}
                            {/*                                    Mfg Partnumber*/}
                            {/*                                </th>*/}
                            {/*                                <th className="col-th-077" scope="col">*/}
                            {/*                                    Brand*/}
                            {/*                                </th>*/}
                            {/*                                <th scope="col-th-077">More Information</th>*/}
                            {/*                                */}{/*<th scope="col-th-077">Product Group Major</th>*/}
                            {/*                                */}{/*<th scope="col-th-077">Product Group Minor</th>*/}
                            {/*                                */}{/*<th scope="col-th-077">Price Group Major</th>*/}
                            {/*                                */}{/*<th scope="col-th-077">Price Group Minor</th>*/}
                            {/*                                */}{/*<th scope="col-th-077">Supplier</th>*/}


                            {/*                                <th scope="col-th-077">Actions</th>*/}
                            {/*                            </tr>*/}
                            {/*                        </thead>*/}
                            {/*                        <tbody>*/}
                            {/*                            {*/}
                            {/*                                aproducts != null ? (*/}
                            {/*                                    aproducts.map((item) => (*/}
                            {/*                                        <Fragment>*/}
                            {/*                                            {editContactId === item.id ? (*/}
                            {/*                                                <EditableRow*/}
                            {/*                                                    editFormData={editFormData}*/}
                            {/*                                                    allProducts={aproducts}*/}
                            {/*                                                    handleEditFormChange={handleEditFormChange}*/}
                            {/*                                                    handleCancelClick={handleCancelClick}*/}
                            {/*                                                />*/}
                            {/*                                            ) : (*/}
                            {/*                                                <ReadOnlyRow*/}
                            {/*                                                    item={item}*/}
                            {/*                                                    allProducts={aproducts}*/}
                            {/*                                                    handleEditClick={handleEditClick}*/}
                            {/*                                                    handleDeleteClick={handleDeleteClick} ebtn={EAssProduct}*/}
                            {/*                                                />*/}
                            {/*                                            )}*/}
                            {/*                                        </Fragment>*/}

                            {/*                                    ))*/}
                            {/*                                ) : (null)*/}
                            {/*                            }*/}
                            {/*                        </tbody>*/}
                            {/*                    </table>*/}

                            {/*                </div>*/}
                            {/*            </form>*/}


                            {/*            {*/}
                            {/*                isShowpopup ? (*/}
                            {/*                    <div id="popup1" className="overlay">*/}
                            {/*                        <div className="popup">*/}
                            {/*                            <h4> Add a Product</h4>*/}
                            {/*                            <a className="close" onClick={(e) => OpenClosePopUp(0)}>&times;</a>*/}
                            {/*                            <div className="row">*/}
                            {/*                                <div className="col-sm-12 col-md-12">*/}
                            {/*                                    <form onSubmit={handleAddFormSubmit}>*/}
                            {/*                                        <div className="row">*/}
                            {/*                                            <div className="col-sm-12 col-md-12 mb-2">*/}
                            {/*                                                <input*/}
                            {/*                                                    type="text"*/}
                            {/*                                                    name="sku"*/}
                            {/*                                                    placeholder="Enter a sku..."*/}
                            {/*                                                    className="form-control"*/}
                            {/*                                                    onChange={handleAddFormChange}*/}
                            {/*                                                />*/}
                            {/*                                            </div>*/}
                            {/*                                        </div>*/}
                            {/*                                        <div className="row">*/}
                            {/*                                            <div className="col-sm-12 col-md-12 mb-2">*/}
                            {/*                                                <input*/}
                            {/*                                                    type="text"*/}
                            {/*                                                    name="uom"*/}
                            {/*                                                    placeholder="Enter an uom..."*/}
                            {/*                                                    className="form-control"*/}
                            {/*                                                    onChange={handleAddFormChange}*/}
                            {/*                                                />*/}
                            {/*                                            </div>*/}
                            {/*                                        </div>*/}
                            {/*                                        <div className="row">*/}
                            {/*                                            <div className="col-sm-12 col-md-12 mb-2">*/}
                            {/*                                                <input*/}
                            {/*                                                    type="text"*/}
                            {/*                                                    name="ToUOM"*/}
                            {/*                                                    placeholder="Enter a Customer Reference UOM..."*/}
                            {/*                                                    className="form-control"*/}
                            {/*                                                    onChange={handleAddFormChange}*/}
                            {/*                                                />*/}
                            {/*                                            </div>*/}
                            {/*                                        </div>*/}
                            {/*                                        <div className="row">*/}
                            {/*                                            <div className="col-sm-12 col-md-12 mb-2">*/}
                            {/*                                                <input*/}
                            {/*                                                    type="text"*/}
                            {/*                                                    name="CustRefID"*/}
                            {/*                                                    placeholder="Enter an Customer Reference ID..."*/}
                            {/*                                                    className="form-control"*/}
                            {/*                                                    onChange={handleAddFormChange}*/}
                            {/*                                                />*/}
                            {/*                                            </div>*/}
                            {/*                                        </div>*/}
                            {/*                                        <div className="row">*/}
                            {/*                                            <div className="col-sm-12 col-md-12">*/}
                            {/*                                                <button type="submit" className="btn btn-sm btn-primary">Add</button>*/}
                            {/*                                            </div>*/}
                            {/*                                        </div>*/}
                            {/*                                    </form>*/}
                            {/*                                </div>*/}
                            {/*                            </div>*/}
                            {/*                        </div>*/}
                            {/*                    </div>*/}
                            {/*                ) : (null)*/}
                            {/*            }*/}
                            {/*            <div onClick={ClosePopup}>*/}
                            {/*                {*/}
                            {/*                    isloader.isfailed ? (*/}
                            {/*                        <AFailed message={isloader.message} />*/}
                            {/*                    ) : (null)*/}
                            {/*                }*/}
                            {/*            </div>*/}
                            {/*            <div onClick={ClosePopup}>*/}
                            {/*                {*/}
                            {/*                    isloader.isSuccess ? (*/}
                            {/*                        <ASuccess message={isloader.message} />*/}
                            {/*                    ) : (null)*/}
                            {/*                }*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            {/*<div className={activeguomclass == true ? "" : "hidden"}>*/}
                            {/*    <div className="row">*/}
                            {/*        <div className="col-md-12">*/}
                            {/*            */}{/*<div className="row mb-3">*/}
                            {/*            */}{/*    <div className="col-sm-6 col-md-6">*/}
                            {/*            */}{/*        <span className="fs-5 fw-bold pe-2">*/}
                            {/*            */}{/*            Account Number:*/}
                            {/*            */}{/*        </span>*/}
                            {/*            */}{/*        */}{/*<select className="form-select" onChange={(e) => changeacc(e)}>*/}
                            {/*            */}{/*        {*/}
                            {/*            */}{/*            mpacclist.map((item, index) => (*/}
                            {/*            */}{/*                //<option value={item.customerId}>{item.message}</option>*/}
                            {/*            */}{/*                <span className="fs-5 fw-bold pe-2">{item.message}</span>*/}
                            {/*            */}{/*            ))*/}
                            {/*            */}{/*        }*/}
                            {/*            */}{/*        */}{/*    </select>*/}
                            {/*            */}{/*    </div>*/}
                            {/*            */}{/*</div>*/}
                            {/*            <div className="row mb-3">*/}
                            {/*                <div className="col-sm-6 col-md-6">*/}
                            {/*                    <label className="form-label">*/}
                            {/*                        Customer UOM*/}
                            {/*                    </label>*/}
                            {/*                    <div className="">*/}
                            {/*                        <input className="form-control" type="text" value={isfromuom} onChange={Changefromuom} />*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*            <div className="row mb-3">*/}
                            {/*                <div className="col-sm-6 col-md-6">*/}
                            {/*                    <label className="form-label">*/}
                            {/*                        Agility UOM*/}
                            {/*                    </label>*/}
                            {/*                    <div className="">*/}
                            {/*                        <input className="form-control" type="text" value={istouom} onChange={Changetouom} />*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*            <div className="row mb-3">*/}
                            {/*                <div className="col-sm-12 col-md-12">*/}
                            {/*                    <div className="d-flex justify-content-start">*/}
                            {/*                        <input className="btn btn-primary btn-wh-1" type="button" value="Save" disabled={AUOMMapping != true ? true : false} onClick={(e) => Saveuom()} />*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*            <div className="row">*/}
                            {/*                <div className="col-sm-12 col-md-12">*/}
                            {/*                    <table className="table table-bordered">*/}
                            {/*                        <thead className="table-light">*/}
                            {/*                            <tr>*/}
                            {/*                                <th className="" scope="col">*/}
                            {/*                                    ID*/}
                            {/*                                </th>*/}
                            {/*                                <th className="" scope="col">*/}
                            {/*                                    Customer UOM*/}
                            {/*                                </th>*/}
                            {/*                                <th className="" scope="col">*/}
                            {/*                                    Agility UOM*/}
                            {/*                                </th>*/}
                            {/*                                <th className="" scope="col">*/}
                            {/*                                    Actions*/}
                            {/*                                </th>*/}
                            {/*                            </tr>*/}
                            {/*                        </thead>*/}
                            {/*                        <tbody>*/}

                            {/*                            {*/}
                            {/*                                uoms != null ? (*/}
                            {/*                                    uoms.map((item, index) => (*/}
                            {/*                                        <tr key={index}>*/}
                            {/*                                            <td className="">*/}
                            {/*                                                {item.id}*/}
                            {/*                                            </td>*/}
                            {/*                                            <td className="">*/}
                            {/*                                                {item.fromuom}*/}
                            {/*                                            </td>*/}
                            {/*                                            <td className="">*/}
                            {/*                                                {item.touom}*/}
                            {/*                                            </td>*/}
                            {/*                                            <td>*/}
                            {/*                                                <button className="btn btn-sm btn-danger" disabled={DUOMMapping != true ? true : false} data-bs-toggle="tooltip" data-bs-placement="top" title="Delete UOM" onClick={(e) => RemoveUOM(item.id)} ><i className="bi bi-trash-fill"></i></button>*/}
                            {/*                                            </td>*/}
                            {/*                                        </tr>*/}
                            {/*                                    ))*/}
                            {/*                                ) : (null)*/}
                            {/*                            }*/}

                            {/*                        </tbody>*/}
                            {/*                    </table>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/* </div>*/}

                        </div>

                        <div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group mb-3">
                                        <label className="form-label">Process only mapped items :</label>
                                        <input className="form-check-input" type="checkbox" value={"true"}  checked={ismapped == "Yes" ? true : false} onChange={ChangeNfSettings} />
                                    </div>
                                    <div className="form-group mb-3">
                                        <label className="form-label">Process order when inventory is zero</label>
                                        <select className="form-select" aria-label="Default select example" onChange={ChangeNfOSettings} value={nforder}>
                                            <option selected>Select</option>
                                            <option value="1">Accept</option>
                                            <option value="2">Reject</option>
                                            <option value="3">Hold</option>
                                        </select>
                                    </div>
                                    {/*<div className="form-group mb-3">*/}
                                    {/*    <label className="form-label">AWS Access Key</label>*/}
                                    {/*    <input className="form-control" type="text" value={awsaccesskey} onChange={changeawsaccesskey} />*/}
                                    {/*</div>*/}
                                    {/*<div className="form-group mb-3">*/}
                                    {/*    <label className="form-label">AWS Secret Key</label>*/}
                                    {/*    <input className="form-control" type="text" value={awssecretkey} onChange={changeawssecretkey} />*/}
                                    {/*</div>*/}
                                    {/*<div className="form-group mb-3">*/}
                                    {/*    <label className="form-label">Refresh Token</label>*/}
                                    {/*    <input className="form-control" type="text" value={refreshtoken} onChange={changerefreshtoken} />*/}
                                    {/*</div>*/}
                                    {/*<div className="form-group mb-3">*/}
                                    {/*    <label className="form-label">Amazon ClientId</label>*/}
                                    {/*    <input className="form-control" type="text" value={amazonclientid} onChange={changeamazonclientid} />*/}
                                    {/*</div>*/}
                                    {/*<div className="form-group mb-3">*/}
                                    {/*    <label className="form-label">Amazon Secret Key</label>*/}
                                    {/*    <input className="form-control" type="text" value={amazonsecretkey} onChange={changeamazonsecretkey} />*/}
                                    {/*</div>*/}
                                    {/*<div className="form-group mb-3">*/}
                                    {/*    <label className="form-label">Marketplace ID</label>*/}
                                    {/*    <input className="form-control" type="text" value={marketplaceid} onChange={changemarketplaceid} />*/}
                                    {/*</div>*/}
                                    {/*<div className="form-group mb-3">*/}
                                    {/*    <label className="form-label">API URL</label>*/}
                                    {/*    <input className="form-control" type="text" value={apiurl} onChange={changeapi} />*/}
                                    {/*</div>*/}
                                    {/*<div className="form-group mb-3">*/}
                                    {/*    <label className="form-label">APIOAUTH URL</label>*/}
                                    {/*    <input className="form-control" type="text" value={apioauthurl} onChange={changeapioauth} />*/}
                                    {/*</div>*/}
                                    {/*<div className="form-group mb-3">*/}
                                    {/*    <label className="form-label">APIOAUTH PATH</label>*/}
                                    {/*    <input className="form-control" type="text" value={apiouthpath} onChange={changeapioauthpath} />*/}
                                    {/*</div>*/}
                                    {/*<div className="form-group mb-3">*/}
                                    {/*    <label className="form-label">Batch Size</label>*/}
                                    {/*    <input className="form-control" type="text" value={batchsize} onChange={changebatchsize} />*/}
                                    {/*</div>*/}
                                    <div className="d-flex justify-content-center">
                                        <input className="btn btn-primary btn-wh-1" type="button" value="Update" disabled={ENFSettings != true ? true : false} onClick={(e) => SaveSettings()} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={activelocclass == true ? "" : "hidden"}>
                            <div className="row">
                                <div className="col-md-12">
                                    {/*<div className="row mb-3">*/}
                                    {/*    <div className="col-sm-6 col-md-6">*/}
                                    {/*        <span className="fs-5 fw-bold pe-2">*/}
                                    {/*            Account Number:*/}
                                    {/*        </span>*/}
                                    {/*        */}{/*<select className="form-select" onChange={(e) => changeacc(e)}>*/}
                                    {/*        {*/}
                                    {/*            mpacclist.map((item, index) => (*/}
                                    {/*                //<option value={item.customerId}>{item.message}</option>*/}
                                    {/*                <span className="fs-5 fw-bold pe-2">{item.message}</span>*/}
                                    {/*            ))*/}
                                    {/*        }*/}
                                    {/*        */}{/*    </select>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div className="row mb-3">
                                        <div className="col-sm-6 col-md-6">
                                            <label className="form-label">
                                                Warehouse
                                            </label>
                                            <select className="form-select" onChange={(e) => changelocation(e)}>
                                                <option selected value={0}>Select</option>
                                                {
                                                    loclist.map((item, index) => (
                                                        <option value={item.resultid}>{item.name}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-sm-6 col-md-6">
                                            <label className="form-label">
                                                Branch
                                            </label>
                                            <select className="form-select" onChange={(e) => changebranch(e)}>
                                                <option selected value={0}>Select</option>
                                                {
                                                    branchlist.map((item, index) => (
                                                        <option value={item.resultid}>{item.name}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-sm-12 col-md-12">
                                            <div className="d-flex justify-content-start">
                                                <input className="btn btn-primary btn-wh-1" type="button" value="Save" disabled={AWHMapping != true ? true : false} onClick={(e) => Savewarehoues()} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12 col-md-12">
                                            <div className="table-scroll-section">
                                                <table className="table table-bordered">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th className="" scope="col">
                                                                ID
                                                            </th>
                                                            <th className="" scope="col">
                                                                Name
                                                            </th>
                                                            <th className="" scope="col">
                                                                Account number
                                                            </th>
                                                            <th className="" scope="col">
                                                                Warehouse
                                                            </th>
                                                            <th className="" scope="col">
                                                                Branch
                                                            </th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            products != null ? (
                                                                products.map((item, index) => (
                                                                    <tr key={index}>
                                                                        <td className="">
                                                                            {item.id}
                                                                        </td>
                                                                        <td className="">
                                                                            {item.marketplacename}
                                                                        </td>
                                                                        <td className="">
                                                                            {item.accountnumber}
                                                                        </td>
                                                                        <td className="">
                                                                            {item.location}
                                                                        </td>
                                                                        <td className="">
                                                                            {item.branch}
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            ) : (null)
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={activeShippingClass == true ? "" : "hidden"}>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-sm-12 col-md-12">
                                            <div className="d-flex justify-content-end mb-3">
                                                <button type="button" className="btn btn-primary btn-wh-1" disabled={ASMMapping != true ? true : false} onClick={OpenCloseAddShippingPopup} >Add</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12 col-md-12">
                                            <table className="table table-bordered">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th className="" scope="col">
                                                            Aqua Ship Method
                                                        </th>
                                                        <th className="" scope="col">
                                                            Customer Ship Method
                                                        </th>
                                                        <th className="" scope="col">
                                                            Edit
                                                        </th>
                                                        <th className="" scope="col">
                                                            Delete
                                                        </th>

                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {
                                                        shippingData != null ? (
                                                            shippingData.map((item, index) => (
                                                                <Fragment>
                                                                    {editShipName === item.aquaShipMethod ? (

                                                                        <tr key={index}>


                                                                            <td>
                                                                                <input readOnly
                                                                                    type="text"
                                                                                    //required="required"
                                                                                    placeholder="Enter Aqua Ship Method..."
                                                                                    name="aqua"

                                                                                    className="form-control px-1"
                                                                                    value={item.aquaShipMethod}
                                                                                // onChange={handleEditFormChange}
                                                                                ></input>
                                                                            </td>
                                                                            <td>
                                                                                <input
                                                                                    type="text"
                                                                                    //required="required"
                                                                                    placeholder="Enter Customer Ship Method..."
                                                                                    name="cust"
                                                                                    className="form-control px-1"
                                                                                    value={customerShipMethod}
                                                                                    onChange={ChangeCustomerShipMethod}
                                                                                ></input>
                                                                            </td>

                                                                            <td>
                                                                                <div className="btn-style">
                                                                                    <button type="submit" className="btn btn-sm btn-primary mb-1" disabled={ESMMapping != true ? true : false} onClick={EditSaveShippingMethod}><i className="bi bi-journal-check"></i></button>
                                                                                    <button type="button" className="btn btn-sm btn-primary" onClick={handleCancelShipEditClick}>
                                                                                        <i className="bi bi-x"></i>
                                                                                    </button>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <button className="btn btn-sm btn-danger" disabled={DSMMapping != true ? true : false} data-toggle="tooltip" data-placement="top" title="Delete Shipping Method" onClick={(e) => DeleteAlert(item.aquaShipMethod, item.customerShipMethod)}><i className="bi bi-trash-fill"></i></button>
                                                                            </td>

                                                                        </tr>
                                                                    ) : (
                                                                        <tr>
                                                                            <td className="">
                                                                                {item.aquaShipMethod}
                                                                            </td>
                                                                            <td className="">
                                                                                {item.customerShipMethod}
                                                                            </td>
                                                                            <td>
                                                                                <button className="btn btn-sm btn-primary" disabled={ESMMapping != true ? true : false} onClick={() => EditShipClick(item.aquaShipMethod, item.customerShipMethod)}><i className="bi bi-pencil-square" ></i></button>
                                                                            </td>
                                                                            <td>
                                                                                <button className="btn btn-sm btn-danger" disabled={DSMMapping != true ? true : false} data-toggle="tooltip" data-placement="top" title="Delete Shipping Method" onClick={(e) => DeleteAlert(item.aquaShipMethod, item.customerShipMethod)}><i className="bi bi-trash-fill"></i></button>
                                                                            </td>



                                                                        </tr>


                                                                    )}
                                                                </Fragment>


                                                            ))
                                                        ) : (null)
                                                    }

                                                </tbody>

                                            </table>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                        {
                            isshipPopUp ? (
                                <div id="popup1" className="overlay">
                                    <div className="popup">
                                        <h4> Add a Shipping Method</h4>
                                        <a className="close" onClick={OpenCloseAddShippingPopup}>&times;</a>
                                        <div className="row">
                                            <div className="col-sm-12 col-md-12">
                                                <form onSubmit={handleAddShippingFormSubmit}>
                                                    <div className="row">
                                                        <div className="col-sm-12 col-md-12 mb-2">
                                                            <input
                                                                type="text"
                                                                name="aqua"
                                                                placeholder="Aqua Ship Method"
                                                                className="form-control"
                                                                onChange={handleAddShipFormChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-12 col-md-12 mb-2">
                                                            <input
                                                                type="text"
                                                                name="cust"
                                                                placeholder="Customer Ship Method"
                                                                className="form-control"
                                                                onChange={handleAddShipFormChange}
                                                            />
                                                        </div>
                                                    </div>


                                                    <div className="row">
                                                        <div className="col-sm-12 col-md-12">
                                                            <button type="submit" className="btn btn-sm btn-primary" >Add</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (null)
                        }
                        <div className={activeeditView == true ? "" : "hidden"}>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="heading-section">
                                            <h1>Edit Shipping Method</h1>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6 col-md-6">
                                                <div className="mb-3">
                                                    <label className="form-label">Aqua Ship Method</label>
                                                    <input className="form-control" type="text" value={aquaShipMethod} disabled={true} />
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label">Customer Ship Method</label>
                                                    <input className="form-control" type="text" value={customerShipMethod} onChange={ChangeCustomerShipMethod} />
                                                </div>
                                                <button type="button" className="btn btn-primary me-2" disabled={ESMMapping != true ? true : false} onClick={EditSaveShippingMethod}>Save</button>
                                                <button className="btn btn-sm btn-primary" onClick={(e) => CloseEditClick()}>Back</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={addview == true ? "" : "hidden"}>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="heading-section">
                                            <h1>Add Shipping Method</h1>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6 col-md-6">
                                                <div className="mb-3">
                                                    <label className="form-label">Aqua Ship Method</label>
                                                    <input className="form-control" type="text" value={newAquaShip} onChange={AddNewAquaship} />
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label">Customer Ship Method</label>
                                                    <input className="form-control" type="text" value={newCustomerShip} onChange={AddnewCustomerShip} />
                                                </div>
                                                <button type="button" className="btn btn-primary me-2" onClick={SaveNewShippingMethods}>Save</button>
                                                <button className="btn btn-sm btn-primary" onClick={(e) => CloseEditClick()}>Back</button>
                                            </div>
                                        </div>
                                        {/* <button className="btn btn-sm btn-primary" onClick={(e) =>CloseEditClick()}>Close</button> */}
                                        {/* <button type="button" className="btn btn-primary" onClick={CloseEditClick} >Close</button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div onClick={ClosePopup}>
                        {
                            isloader.isfailed ? (
                                <AFailed message={isloader.message} />
                            ) : (null)
                        }
                    </div>
                    <div onClick={ClosePopup}>
                        {
                            isloader.isSuccess ? (
                                <ASuccess message={isloader.message} />
                            ) : (null)
                        }
                    </div>
                </div>
            </div>
        </React.Fragment >
    )
}
export default nfsetup;


