import React, { useEffect, useState } from "react";
import { Loadermessage, CreateinitialLoadermessage } from "../../dataservices/model/loadermessage";
import { GetMarketplaceAccountNumberForRelationshipType } from "../../dataservices/action/marketplace/marketplace";
import { GetMpNfCustomerList, GetRefreshedApiKey, SaveNfCustomerDetailsById, AddNfCustomer, Getrolepermissionbyuserid } from "../../dataservices/action/marketplace/marketplace";
import { channellist } from "../../dataservices/model/channellist";
import { mpnfcustomers } from "../../dataservices/model/mpnfcustomers";
import AFailed from "../alretbox/failed";
import ASuccess from "../alretbox/success";
import Loader from "../loader";
import { useParams } from 'react-router-dom';
import NFSideBar from "../nfsidebar";
import toast from "react-hot-toast";
import axios from "axios";

const NFCustomerList = () => {
    const [isloader, Setisloader] = useState(CreateinitialLoadermessage());
    var intMPAcclist: channellist[] = new Array<channellist>();
    const [mpacclist, setmpacclist] = useState(intMPAcclist);
    var intmpnfcustomers: mpnfcustomers[] = new Array<mpnfcustomers>();
    const [nfcustomers, setnfcustomers] = useState(intmpnfcustomers);
    const [maid, setMaid] = useState(0);
    const [customername, setCustomername] = useState('');
    const [apikey, setApikey] = useState('');
    const [genApiKey, setGenApikey] = useState("");
    const [id, setid] = useState(0);
    const [editoption, seteditoption] = useState(false);
    const [addoption, setaddoption] = useState(false);
    const [isLoader, setisLoader] = useState(false);
    const [isShowpopup, SetisShowpopup] = useState(false);
    const [opendiv, setopendiv] = useState(false);
    interface Property {
        key: string;
        value: string;
    }

    const [properties, setProperties] = useState<Property[]>([]);
    const [accountid, setAccountid] = useState('');
    const { accid } = useParams();
    const [addFormData, setAddFormData] = useState({
        maid: accid,
        customerName: "",
        accountId: "",
        apikey: ""


    });
    useEffect(() => {
        Getrolepermissionbyuserid(1, function (result: any) {
            if (result != null) {
                if (result != null) {
                    for (var i = 0; i < result.length; i++) {
                        console.log(result[i].name);
                        switch (result[i].permissionid) {

                            case 35:
                                if (result[i].canadd == "1") {
                                    setaddoption(true);
                                }
                                if (result[i].canedit == "1") {
                                    seteditoption(true);
                                }
                                break;

                        }
                    }
                }
            }
        })
    }, [])

    useEffect(() => {
        GetMpNfCustomerList(accid, function (result: any) {
            //console.log("cust....", result);
            setnfcustomers(result);
        })

    }, [id])

    const OpenClosePopUp = (id: number) => {
        SetisShowpopup(!isShowpopup);
    }
    const changeCustomer = (e: any) => {
        setopendiv(true);
        const filteredData = nfcustomers.find(item => item.id == e.target.value);

        if (filteredData) {
            setid(filteredData.id);
            setMaid(filteredData.maid);
            setCustomername(filteredData.customername);
            setApikey(filteredData.apikey);
            var propArr = JSON.parse(filteredData.properties);
            setProperties(propArr);
            setAccountid(filteredData.accountid);
        }
    }
    const handleChangeCheckbox = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const updatedProperties = [...properties];
        updatedProperties[index].value = e.target.checked ? "1" : "0";
        setProperties(updatedProperties);
    };
    const handleClickRefresh = () => {
        //console.log("refresh");
        GetRefreshedApiKey(function (result: string) {
            if (result != null) {
                setApikey(result);
            }
        })
    }
    const saveClick = () => {
       // console.log("save")
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load)
        SaveNfCustomerDetailsById(id, apikey, JSON.stringify(properties), function (result: any) {
            if (result.isSuccess) {
                let res = CreateinitialLoadermessage();
                res.isSuccess = true;
                res.isLoader = false;
                res.isfailed = false;
                res.message = "Saved Successfully"
                Setisloader(res);
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                // window.location.reload();

            }

        })

    }
    const SyncAllOpenClosePopUp = (id: number) => {


        SetisShowpopup(!isShowpopup);
    }


    const handleAddFormChange = (e: any) => {
        e.preventDefault();
        const fieldName = e.target.getAttribute("name");
        const fieldValue = e.target.value;

        const newFormData = { ...addFormData };
        if (fieldName == "accountid") {
            newFormData.accountId = fieldValue;
        }
        else if (fieldName == "customername") {
            newFormData.customerName = fieldValue;
        }
        else if (fieldName == "apikey") {
            newFormData.apikey = genApiKey;
        }

        //newFormData[fieldName] = fieldValue;

        setAddFormData(newFormData);
    }
    const handleAddFormSubmit = (e: any) => {
        e.preventDefault();

        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load)
        if (addFormData.accountId == "") {
            let result = CreateinitialLoadermessage()
            result.isfailed = true;
            result.message = "Please enter Account ID"
            Setisloader(result);
            setisLoader(false);
        }
        else if (addFormData.customerName == "") {
            let result = CreateinitialLoadermessage()
            result.isfailed = true;
            result.message = "Please enter Customer Name"
            Setisloader(result);
            setisLoader(false);
        }
        else if (genApiKey == "") {
            let result = CreateinitialLoadermessage()
            result.isfailed = true;
            result.message = "Please Generate API Key"
            Setisloader(result);
            setisLoader(false);
        } else if (addFormData.accountId != "" && addFormData.customerName != "" && genApiKey != "") {


            AddNfCustomer(accid, addFormData.accountId, addFormData.customerName, genApiKey, function (result: any) {
                if (result.isSuccess) {
                    let res = CreateinitialLoadermessage();
                    res.isSuccess = true;
                    res.message = "Saved Successfully"
                    Setisloader(res);
                    setisLoader(false);

                }
                SetisShowpopup(!isShowpopup);
                window.location.reload();

            })
            let result = addFormData
            result.accountId = "";
            result.customerName = "";
            setGenApikey("");
            setAddFormData(result);
        }


    }

    const generateApiClick = () => {
       // console.log("apikeygen")
        GetRefreshedApiKey(function (result: string) {
            if (result != null) {
                setGenApikey(result);
            }
        })
    }
    const ClosePopup = () => {
        let loads: Loadermessage = {
            message: "",
            isLoader: false,
            isSuccess: false,
            isfailed: false
        };
        Setisloader(loads);
    }
    // const ExportNfCustomers = (page: any) => {
    //     try {

    //         let load: Loadermessage = {
    //             message: "",
    //             isLoader: true,
    //             isSuccess: false,
    //             isfailed: false
    //         }
    //         Setisloader(load);
    //         const data = new FormData();
    //         data.append('maid', accid);
    //         //  setLoading(true);
    //         const response = axios({
    //             method: "post",
    //             url: "api/MP/ExportNfCustomers",
    //             data: data,
    //             headers: { "Content-Type": "multipart/form-data" },
    //             responseType: 'blob',
    //         }).then((result: any) => {
    //             let resultmes = CreateinitialLoadermessage();
    //             if (result != null && result.status == 200) {
    //                 debugger;
    //                 const url = window.URL.createObjectURL(new Blob([result.data]));
    //                 const a = document.createElement('a');
    //                 a.href = url;
    //                 a.setAttribute('download', "NfCustomers.csv");
    //                 document.body.appendChild(a);
    //                 a.click();
    //                 a.remove();
    //                 URL.revokeObjectURL(url);

    //                 //setLoading(false);

    //             } else {
    //                 toast.error(
    //                     "No Records Found",
    //                     { duration: 50000000 }
    //                 );
    //                 //setLoading(false);
    //                 window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    //             }
    //             resultmes.isLoader = false;
    //             Setisloader(resultmes);
    //         });

    //     } catch (error) {
    //         console.log(error)

    //     }

    // }
    return (
        <React.Fragment>
            <div>
                <div className="row">
                    {

                        isloader.isLoader ? (<Loader isActive={isloader} />) : (null)
                    }
                    <div className="col-md-12">
                        <div className="mpc-menu">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/userdashboard"><i className="bi bi-house-door-fill pe-1"></i>Home</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        Customer List
                                    </li>
                                </ol>
                            </nav>
                            <NFSideBar id={accid} />
                        </div>
                    </div>
                </div>

                <div className="row gy-2 gx-3">
                    <div className="col-10">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-auto">
                                <label className="form-label">
                                    <strong>Select Customer </strong>
                                </label>
                            </div>
                            <div className="col-auto">
                                <select className="form-select" onChange={(e) => changeCustomer(e)}>
                                    <option value="0">Select</option>
                                    {
                                        nfcustomers.map((item, index) => (

                                            <option value={item.id}>{item.customername}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-2">
                        <button type="button" className="btn btn-primary float-end" onClick={(e) => SyncAllOpenClosePopUp(0)} disabled={!addoption ? true : false}>Add Customer</button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mb-3">
                        <div className="d-flex justify-content-end align-items-center">

                            {/* <button type="button" className="btn btn-primary btn-wh-1 ms-2" onClick={ExportNfCustomers} disabled={!editoption ? true : false}>Export</button> */}
                        </div>
                    </div>
                </div>
                <hr />
                {opendiv ? (


                    <div>

                        <div className="row">
                            <div className="col-6">
                                <div className="box-results-row">
                                    <div className="box-results-col">
                                        Customer Account ID:
                                    </div>
                                    <div className="box-results-col">
                                    <strong>{accountid}</strong>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="box-results-row">
                                    <div className="box-results-col">
                                        Customer Name:
                                    </div>
                                    <div className="box-results-col">
                                    <strong>{customername}</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <div className="box-results-row">
                                    <div className="box-results-col">
                                        Customer API Key:
                                    </div>
                                    <div className="box-results-col">
                                    <strong>{apikey}</strong>
                                    </div>
                                    <div>
                                        <button className="btn btn-link" onClick={handleClickRefresh} disabled={!editoption ? true : false}>
                                            <i className="bi bi-arrow-clockwise"></i>
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <table className="table table-bordered mt-3">
                                    <thead className="table-light">
                                        <tr>
                                            <th>API</th>
                                            <th>STATUS</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            properties != null ? (
                                                properties.map((item, index) =>
                                                (
                                                    <tr key={index}>
                                                        <td>
                                                            {item.key}
                                                        </td>
                                                        <td><label>
                                                            <input
                                                                type="checkbox"
                                                                checked={item.value == "1" ? true : false}
                                                                onChange={handleChangeCheckbox(index)}
                                                            />

                                                        </label>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (null)
                                        }

                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 mb-3">
                                <div className="d-flex justify-content-end align-items-center">
                                    <button type="button" className="btn btn-primary btn-wh-1 ms-2" onClick={saveClick} disabled={!editoption ? true : false}>Save</button>
                                </div>
                            </div>
                        </div>
                    </div>)
                    : (null)}

                {
                    isShowpopup ? (
                        <div id="popup1" className="overlay">
                            <div className="popup">
                                <h4> Add a Customer</h4>
                                <a className="close" onClick={(e) => OpenClosePopUp(0)}>&times;</a>
                                <div className="row">
                                    <div className="col-sm-12 col-md-12">
                                        <form onSubmit={handleAddFormSubmit}>
                                            <div className="row">
                                                <div className="col-sm-12 col-md-12 mb-2">
                                                    <input
                                                        type="text"
                                                        name="accountid"
                                                        placeholder="Enter Customer Account ID"
                                                        className="form-control"
                                                        onChange={handleAddFormChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12 col-md-12 mb-2">
                                                    <input
                                                        type="text"
                                                        name="customername"
                                                        placeholder="Enter Customer Name"
                                                        className="form-control"
                                                        onChange={handleAddFormChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12 col-md-12 mb-2">
                                                    <div className="d-flex justify-content-end align-items-center">
                                                        <input
                                                            type="text"
                                                            name="apikey"
                                                            placeholder="Customer API Key"
                                                            className="form-control"
                                                            value={genApiKey}
                                                            onChange={handleAddFormChange}
                                                        />
                                                        <button type="button" className="btn btn-primary ms-2" onClick={generateApiClick}>Generate</button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-sm-12 col-md-12">
                                                    <div className="d-flex justify-content-center align-items-center">
                                                        <button type="submit" className="btn btn-wh-1 btn-primary">Add</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (null)
                }
                <div onClick={ClosePopup}>
                    {
                        isloader.isfailed ? (
                            <AFailed message={isloader.message} />
                        ) : (null)
                    }
                </div>
                <div onClick={ClosePopup}>
                    {
                        isloader.isSuccess ? (
                            <ASuccess message={isloader.message} />
                        ) : (null)
                    }
                </div>
            </div>
        </React.Fragment>
    )
}
export default NFCustomerList;