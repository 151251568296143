import React, { useEffect, useState, useRef } from "react";
import Item from "./sample.jpg";
import { baseUrl, SubscriptionKey } from "../config";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import Button from "@material-ui/core/Button";
import { useHistory, useParams } from "react-router-dom";
import Popover from "@material-ui/core/Popover";
import CircularProgress from "@material-ui/core/CircularProgress";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import InfoIcon from "@material-ui/icons/Info";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MyAttributes from "./attributes";

// import MPSideBar from "../../mpsidebar";
import axios from "axios";
import "./style.css";
function OrderDetails() {
    const navigate = useHistory();
    const { OLT }: any = useParams();
    const [olisturl, setolisturl] = useState<any>("orderlisting");
    const refHistory = useRef<any>(null);
    const [trackNumberArray, setTracknumberArray] = useState<any>([]);
    const [itemSubTotal, setItemSubTotal] = useState<any>(0);
    const [loading, setLoading] = useState(true);
    const [orderShipments, setOrderShipments] = useState<any>([]);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null
    );
    const [attributes, setAttribute] = useState<any>({});

    const [orderHistory, setOrderHistory] = useState<any>([]);

    const handleClick = (event: any, list: any) => {
        setAttribute(list);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const [data, setData] = useState<any>({
        accountNumber: "",
        statusCode: "",
        customerOrderId: "",
        customerOrderDateTime: "",
        transactionId: "",
        transactionDate: "",
        agilityOrderId: null,
        customerCode: null,
        notes: "",
        sourceSystem: "",
        customerWarehouseId: "",
        branchId: null,
        shipToSequenceNumber: null,
        shipVia: null,
        shipTo: {
            name: "",
            addressLine1: "",
            addressLine2: "",
            addressLine3: "",
            city: "",
            state: "",
            zipCode: "",
            country: "",
            shipToPhone: "",
        },
        billTo: {
            company: "",
            addressLine1: "",
            addressLine2: "",
            addressLine3: "",
            city: "",
            state: "",
            zipCode: "",
            country: "",
            shipToPhone: "",
        },
        orderDetails: {
            customerOrderId: "",
            customerOrderDateTime: "",
            orderType: "",
            shippingMethod: "",
            shipComplete: "",
            expectedDeliveryDate: "",
            expectedDeliveryTime: null,
            routeId: "",
            agilityShipVia: null,
            jobNumber: null,
        },
        orderLineItems: [],
        customerContactInformation: {
            additionalContactEmails: ["", ""],
            customerContactName: "",
            customerContactEmail: "",
            customerContactPhone: "",
            customerContactAddress: {
                addressLine1: "",
                addressLine2: "",
                addressLine3: "",
                city: "",
                state: "",
                zipCode: "",
                country: "",
                phone: "",
            },
        },
        orderHistory: [],
        orderShipments: [],
    });
    function formatDate(date: any) {
        var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
    }



    useEffect(() => {

        const orderItem = JSON.parse(localStorage.getItem("orderItem") || "{}");
        if (orderItem.path === "track") {
            refHistory.current.scrollIntoView();
        }
        let orderdetailsAPIfun = "";

        if (OLT == "F") {
            orderdetailsAPIfun = "GetSalesOrdersData"
            setolisturl("/orderlisting/F")
        }
        else {
            orderdetailsAPIfun = "GetNFSalesOrdersData"
            setolisturl("/orderlisting/D")

        }
        const headers = {
            "Content-Type": "application/json",
            Authorization: `${localStorage.getItem("Authorization")}`,
        };
        axios
            .get(
                `${baseUrl}/${orderdetailsAPIfun}?transactionId=${orderItem.transactionId}`,
                { headers }
            )
            .then((response) => {
                setLoading(false);
                setData(response.data.salesOrder[0]);
                setOrderHistory(response.data.salesOrder[0].orderHistory.reverse());
                let itemSub = 0;
                let orderShipments = response.data.salesOrder[0].orderShipments;
                let orderLineItems = response.data.salesOrder[0].orderLineItems;
                orderLineItems.forEach((x: any) => {
                    itemSub = itemSub + x.salesPrice;
                });
                setItemSubTotal(itemSub);
                const newOrderShipments = orderShipments.map((item: any) => {
                    return {
                        ...item,
                        containers: item.containers.map((child: any) => {
                            trackNumberArray.push(child.trackingNumber);
                            return {
                                ...child,
                                packedItems: child.packedItems.map((packedItem: any) => {
                                    return {
                                        ...packedItem,
                                    };
                                }),
                            };
                        }),
                    };
                });
                setOrderShipments([...newOrderShipments]);

                setTracknumberArray([...trackNumberArray]);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }, []);
    function formatAMPM(date: any) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? "PM" : "AM";
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? "0" + minutes : minutes;
        var strTime = hours + ":" + minutes + " " + ampm;
        return strTime;
    }
    const dateConverter = (x: any) => {
        var today = new Date(x);
        var month = today.toLocaleString("default", { month: "short" });
        let time = formatAMPM(today);
        let date = `${month} ${today.getDate()}, ${today.getFullYear()} ${time}`;
        return date;
    };

    const handleBillingAddress = (data: any) => {
        if (
            data.billTo.company ||
            data.billTo.addressLine1 ||
            data.billTo.addressLine2 ||
            data.billTo.addressLine3 ||
            data.billTo.city ||
            data.billTo.state ||
            data.billTo.country ||
            data.billTo.zipCode ||
            data.customerContactInformation.customerContactPhone ||
            data.customerContactInformation.customerContactEmail
        ) {
            return false;
        } else {
            return true;
        }
    };

    const handleCustomerAddress = (data: any) => {
        if (
            data.customerContactInformation.customerContactAddress.addressLine1 ||
            data.customerContactInformation.customerContactAddress.addressLine2 ||
            data.customerContactInformation.customerContactAddress.addressLine3 ||
            data.customerContactInformation.customerContactAddress.city ||
            data.customerContactInformation.customerContactAddress.state ||
            data.customerContactInformation.customerContactAddress.country ||
            data.customerContactInformation.customerContactAddress.zipCode ||
            data.customerContactInformation.customerContactPhone ||
            data.customerContactInformation.customerContactEmail
        ) {
            return false;
        } else {
            return true;
        }
    };

    return (
        <>
            {loading && (
                <div className="loader_orderlisting">
                    <CircularProgress style={{ color: "#000000" }} />
                </div>
            )}
            {/* <div className="row" hidden={loading}>
        <div className="col-md-12">
          <div className="mpc-menu">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/userdashboard">
                    <i className="bi bi-house-door-fill pe-1"></i>Home
                  </a>
                </li>
                <li className="breadcrumb-item" aria-current="page">
                  <span>Order Details</span>
                </li>
              </ol>
            </nav>
            <MPSideBar id={accid} />
          </div>
        </div>
      </div> */}
            <div className="containerOrderDetails" hidden={loading}>
                <div className="orderDetails">
                    <div className="OrderHeader">Order Details</div>
                    <div>
                        <Button
                            onClick={() => navigate.push(olisturl)}
                            color="primary"
                            size="small"
                            className="searchButtonOutlined"
                            variant="outlined"
                            startIcon={<KeyboardBackspaceIcon />}
                        >
                            Back
                        </Button>
                    </div>
                </div>
                <div className="Orderdate">
                    Ordered On {dateConverter(data.customerOrderDateTime)}
                </div>
                <div className="OrderInfo">
                    <b>Order #</b>
                    {" " + data.customerOrderId}
                </div>
                {data.transactionId && (
                    <div className="OrderInfo">
                        <b>Transaction Id :</b>
                        {" " + data.transactionId}
                    </div>
                )}
                {data.branchCode && (
                    <div className="OrderInfo">
                        <b>Branch Code :</b>
                        {" " + data.branchCode}
                    </div>
                )}
                {data.agilityOrderId && (
                    <div className="OrderInfo">
                        <b>Agility Order Id :</b>
                        {" " + data.agilityOrderId}
                    </div>
                )}
                {trackNumberArray.length > 0 && (
                    <div className="TrackingInfo">
                        <div>
                            <b>{"Tracking #"}</b>
                        </div>
                        <div className="TrackingInfo_content">
                            {" "}
                            {trackNumberArray.toString().substring(0, 100)}
                        </div>
                    </div>
                )}
                <div className="container-extra-fields">
                    {data.orderDetails.shippingMethod && (
                        <div className="container-extra-item">
                            <b>Shipping Method:</b>
                            {" " + data.orderDetails.shippingMethod}
                        </div>
                    )}
                    {data.orderDetails.orderType && (
                        <div className="container-extra-item">
                            <b>Order Type:</b>
                            {" " + data.orderDetails.orderType}
                        </div>
                    )}
                    {data.orderDetails.shipComplete && (
                        <div className="container-extra-item">
                            <b>Ship Complete:</b>
                            {" " + data.orderDetails.shipComplete}
                        </div>
                    )}
                    {data.orderDetails.expectedDeliveryDate && (
                        <div className="container-extra-item">
                            <b>Expected Delivery Date:</b>
                            {" " + data.orderDetails.expectedDeliveryDate}
                        </div>
                    )}
                    {data.orderDetails.expectedDeliveryTime && (
                        <div className="container-extra-item">
                            <b>Expected Delivery Time:</b>
                            {" " + data.orderDetails.expectedDeliveryTime}
                        </div>
                    )}
                    {data.orderDetails.agilityShipVia && (
                        <div className="container-extra-item">
                            <b>Agility Ship Via:</b>
                            {" " + data.orderDetails.agilityShipVia}
                        </div>
                    )}
                    {data.sourceSystem && (
                        <div className="container-extra-item">
                            <b>Source System:</b>
                            {" " + data.sourceSystem}
                        </div>
                    )}
                    {data.customerWarehouseId && (
                        <div className="container-extra-item">
                            <b>Customer Warehouse:</b>
                            {" " + data.customerWarehouseId}
                        </div>
                    )}
                    {data.accountNumber && (
                        <div className="container-extra-item">
                            <b>Customer Account Id:</b>
                            {" " + data.accountNumber}
                        </div>
                    )}
                    {data.orderDetails.routeId && (
                        <div className="container-extra-item">
                            <b>Route Id:</b>
                            {" " + data.orderDetails.routeId}
                        </div>
                    )}
                    {data.orderDetails.jobNumber && (
                        <div className="container-extra-item">
                            <b>Job Number:</b>
                            {" " + data.orderDetails.jobNumber}
                        </div>
                    )}
                    {data.shipToSequenceNumber && (
                        <div className="container-extra-item">
                            <b>Ship To Sequence Number:</b>
                            {" " + data.shipToSequenceNumber}
                        </div>
                    )}
                </div>
                {data.notes && (
                    <div className="NoteInfo">
                        <div>
                            <b>{"Notes: "}</b>
                        </div>
                        <div className="TrackingInfo_content"> {data.notes} </div>
                    </div>
                )}
                <div className="billingDeatilsTable">
                    <div className="ShippingAddress">
                        <div className="AddressText">Shipping Address</div>
                        <div className="AddressText_items">{data.shipTo.name}</div>
                        <div className="AddressText_items">
                            {data.shipTo.addressLine1 ? data.shipTo.addressLine1 : ""}
                        </div>
                        <div className="AddressText_items">
                            {data.shipTo.addressLine2 ? data.shipTo.addressLine2 : ""}
                        </div>
                        <div className="AddressText_items">
                            {data.shipTo.addressLine3 ? data.shipTo.addressLine3 : ""}
                        </div>
                        <div className="AddressText_items">
                            {data.shipTo.city}
                            {data.shipTo.state && " , "}
                            {data.shipTo.state}
                            {data.shipTo.country && " , "}
                            {data.shipTo.country}
                        </div>
                        <div className="AddressText_items">
                            {data.shipTo.zipCode}
                            {data.shipTo.shipToPhone && " , "}
                            {data.shipTo.shipToPhone}
                        </div>
                    </div>
                    <div className="BillingAddress">
                        <div hidden={handleBillingAddress(data)} className="AddressText">
                            Billing Address
                        </div>
                        <div className="AddressText_items">{data.billTo.company}</div>
                        <div className="AddressText_items">{data.billTo.addressLine1}</div>
                        <div className="AddressText_items">{data.billTo.addressLine2}</div>
                        <div className="AddressText_items">{data.billTo.addressLine3}</div>
                        <div className="AddressText_items">
                            {data.billTo.city}
                            {data.billTo.state && " , "}
                            {data.billTo.state}
                            {data.billTo.country && " , "}
                            {data.billTo.country}
                            {data.billTo.zipCode && " , "}
                            {data.billTo.zipCode}
                        </div>
                        <div className="AddressText_items">
                            {data.customerContactInformation.customerContactPhone}
                            {data.customerContactInformation.customerContactEmail && " , "}
                            {data.customerContactInformation.customerContactEmail}
                        </div>
                    </div>
                    <div className="CustomerAddress">
                        <div hidden={handleCustomerAddress(data)} className="AddressText">
                            Customer Information
                        </div>
                        <div className="AddressText_items">
                            {
                                data.customerContactInformation.customerContactAddress
                                    .addressLine1
                            }
                        </div>
                        <div className="AddressText_items">
                            {
                                data.customerContactInformation.customerContactAddress
                                    .addressLine2
                            }
                        </div>
                        <div className="AddressText_items">
                            {
                                data.customerContactInformation.customerContactAddress
                                    .addressLine3
                            }
                        </div>
                        <div className="AddressText_items">
                            {data.customerContactInformation.customerContactAddress.city}
                            {data.customerContactInformation.customerContactAddress.state &&
                                " , "}
                            {data.customerContactInformation.customerContactAddress.state}
                            {data.customerContactInformation.customerContactAddress.country &&
                                " , "}
                            {data.customerContactInformation.customerContactAddress.country}
                            {data.customerContactInformation.customerContactAddress.zipCode &&
                                " , "}
                            {data.customerContactInformation.customerContactAddress.zipCode}
                        </div>
                        <div className="AddressText_items">
                            {data.customerContactInformation.customerContactPhone}
                            {data.customerContactInformation.customerContactEmail && " , "}

                            {data.customerContactInformation.customerContactEmail}
                        </div>
                    </div>
                    <div className="OrderSummerry">
                        <div className="AddressText">Order Summary</div>
                        <div>
                            <span className="AddressText">Grand Total:{"  "}</span>
                            <span className="AddressText">${itemSubTotal.toFixed(2)}</span>
                        </div>

                        {/* <tr>
                  <td>Item(s) Subtotal:</td>
                  <td>${itemSubTotal.toFixed(2)}</td>
                </tr> */}
                        {/* <tr>
                  <td>Shipping:</td>
                  <td>$0</td>
                </tr> */}
                        {/* <tr>
                  <td>Total:</td>
                  <td>${itemSubTotal}</td>
                </tr> */}
                        {/* <tr>
                  <td>Promotion Applied:</td>
                  <td>$0</td>
                </tr> */}
                    </div>
                </div>
                <div style={{ width: "100%" }}>
                    <Accordion defaultExpanded className="order_content_accordion">
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <div className="orderHistoryHeading">Order Contents</div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className={"ordertableContainer"}>
                                <table className="OrderLineTable">
                                    <tbody>
                                        <tr className="">
                                            <th style={{ width: "35%" }}>Product Name</th>
                                            <th style={{ width: "40%" }}>Product Description</th>
                                            <th style={{ width: "15%" }}>More Information</th>
                                            <th style={{ width: "10%" }}>Quantity</th>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        {Array.isArray(data.orderLineItems) &&
                                            data.orderLineItems.map((x: any, i: any) => (
                                                <tr className="" key={i}>
                                                    <td>
                                                        <div>
                                                            <div className="orderLineProductName">
                                                                {x.productName}
                                                            </div>
                                                        </div>
                                                        <div className="orderLinegroup">
                                                            {/* <div>ASIN:{"NA"}</div> */}
                                                            <div>
                                                                SKU:{" "}
                                                                <span className="orderLineProductName">
                                                                    {OLT == "F" ? x.customerPartNumber : x.agilityPartNumber}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div>
                                                            <div className="OrderLineItemdescription">
                                                                {x.productDescription}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div
                                                            onClick={(e) => handleClick(e, x)}
                                                            className="attribute_icon"
                                                        >
                                                            <InfoIcon className="Icon_color_global" />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div>
                                                            <div className="OrderLineItemdescription">
                                                                {x.quantity}
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                                <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "center",
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "center",
                                    }}
                                >
                                    <MyAttributes attributes={attributes} />
                                </Popover>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div style={{ width: "100%" }}>
                    <div
                        hidden={orderShipments.length === 0 ? true : false}
                        className="orderHistoryHeading"
                    >
                        Shipment Configuration
                    </div>
                    <div className="OrderHistoryTableConatiner">
                        {Array.isArray(orderShipments) &&
                            orderShipments.map((item: any, i: any) =>
                                item.containers.map((child: any, childIndex: any) => (
                                    <table key={i + 100} className="OrderHistoryTable">
                                        <tbody>
                                            <tr
                                                hidden={child.containerIdentifier ? false : true}
                                                className="OdhistoryTableRow"
                                            >
                                                <th>
                                                    BOX:
                                                    {child.containerIdentifier}
                                                </th>
                                            </tr>
                                        </tbody>
                                        <tbody>
                                            <tr
                                                hidden={
                                                    child.height ||
                                                        child.length ||
                                                        child.width ||
                                                        child.weight
                                                        ? false
                                                        : true
                                                }
                                            >
                                                <td style={{ display: "flex", gap: "1rem" }}>
                                                    <div
                                                        hidden={child.length ? false : true}
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            gap: 2,
                                                        }}
                                                    >
                                                        <div style={{ fontSize: 14, fontWeight: 600 }}>
                                                            LENGTH
                                                        </div>
                                                        <div style={{ fontSize: 14, fontWeight: 400 }}>
                                                            {child.length}
                                                        </div>
                                                    </div>
                                                    <div
                                                        hidden={child.height ? false : true}
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            gap: 2,
                                                        }}
                                                    >
                                                        <div style={{ fontSize: 14, fontWeight: 600 }}>
                                                            HEIGHT
                                                        </div>
                                                        <div style={{ fontSize: 14, fontWeight: 400 }}>
                                                            {child.height}
                                                        </div>
                                                    </div>

                                                    <div
                                                        hidden={child.width ? false : true}
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            gap: 2,
                                                        }}
                                                    >
                                                        <div style={{ fontSize: 14, fontWeight: 600 }}>
                                                            WIDTH
                                                        </div>
                                                        <div style={{ fontSize: 14, fontWeight: 400 }}>
                                                            {child.width}
                                                        </div>
                                                    </div>
                                                    <div
                                                        hidden={child.weight ? false : true}
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            gap: 2,
                                                        }}
                                                    >
                                                        <div style={{ fontSize: 14, fontWeight: 600 }}>
                                                            WEIGHT
                                                        </div>
                                                        <div style={{ fontSize: 14, fontWeight: 400 }}>
                                                            {child.weight}
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div>
                                                        {item.shipmentDetails.shippedDate && (
                                                            <div>
                                                                <span className="Form_field_label">
                                                                    Shipped Date
                                                                </span>
                                                                {": "}
                                                                <span className="Form_field_info">
                                                                    {dateConverter(
                                                                        item.shipmentDetails.shippedDate
                                                                    )}
                                                                </span>
                                                            </div>
                                                        )}
                                                        {item.shipmentDetails.estimatedDeliveryDate && (
                                                            <div>
                                                                <span className="Form_field_label">
                                                                    Expected Shipment Delivery Date
                                                                </span>
                                                                {": "}
                                                                <span className="Form_field_info">
                                                                    {dateConverter(
                                                                        item.shipmentDetails.estimatedDeliveryDate
                                                                    )}
                                                                </span>
                                                            </div>
                                                        )}
                                                        <div className="ItemLabel">
                                                            {item.shipmentDetails.shipmentStatus
                                                                ? item.shipmentDetails.shipmentStatus
                                                                : ""}
                                                        </div>
                                                        {Array.isArray(child.packedItems) &&
                                                            child.packedItems.map((pack: any, i: any) => (
                                                                <div className="itemsDetailContainer">
                                                                    <div className="ItemImage">
                                                                        <img
                                                                            src={pack.imageUrl ? pack.imageUrl : Item}
                                                                            alt="icon"
                                                                        ></img>
                                                                    </div>
                                                                    <div className="ItemDetails">
                                                                        <div className="ItemDetailsInfo">
                                                                            <div className="ItemDetailsInfoname1">
                                                                                {pack.productName}
                                                                            </div>
                                                                            <div className="ItemDetailDescription">
                                                                                {pack.productDescription}
                                                                            </div>

                                                                            <div className="ItemDetailDescription2">
                                                                                <div>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 14,
                                                                                            fontWeight: 600,
                                                                                        }}
                                                                                    >
                                                                                        UNIT:{" "}
                                                                                    </span>
                                                                                    <span>{pack.quantityShipped}</span>
                                                                                </div>
                                                                                {/* <div>
                                          <span
                                            style={{
                                              fontSize: 14,
                                              fontWeight: 600,
                                            }}
                                          >
                                            ASIN:
                                          </span>
                                          <span>{"NA"}</span>
                                        </div> */}
                                                                                <div>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 14,
                                                                                            fontWeight: 600,
                                                                                        }}
                                                                                    >
                                                                                        SKU:{" "}
                                                                                    </span>
                                                                                    <span>{pack.aquashipPartNumber}</span>
                                                                                </div>
                                                                                <div>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 14,
                                                                                            fontWeight: 600,
                                                                                        }}
                                                                                    >
                                                                                        Price:{" "}
                                                                                    </span>
                                                                                    <span>${pack.salesPrice}</span>
                                                                                </div>
                                                                            </div>

                                                                            <div className="ItemDetailDescription2">
                                                                                <div>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 14,
                                                                                            fontWeight: 600,
                                                                                        }}
                                                                                    >
                                                                                        Tracking Number:{" "}
                                                                                    </span>
                                                                                    <span>{child.trackingNumber}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="ItemActions">
                                                                        {/* <Button
                                style={{ backgroundColor: "#0D6EFD" }}
                                fullWidth
                                variant="contained"
                              >
                                Contained
                              </Button>
                              <Button fullWidth variant="outlined">
                                Undoubtable
                              </Button>
                              <Button fullWidth variant="outlined">
                                Generators
                              </Button>
                              <Button fullWidth variant="outlined">
                                Undoubtable
                              </Button>
                              <Button fullWidth variant="outlined">
                                Predefined
                              </Button> */}
                                                                    </div>
                                                                </div>
                                                            ))}
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                ))
                            )}
                    </div>
                </div>
                <div style={{ width: "100%" }} ref={refHistory}>
                    <div className="orderHistoryHeading">Order History</div>
                    <div className="OrderHistoryTableConatiner">
                        <table className="OrderHistoryTable">
                            <tbody>
                                <tr className="OdhistoryTableHeadRow">
                                    <th style={{ width: "30%" }}>Date</th>
                                    <th>System Interaction Status</th>
                                </tr>
                            </tbody>
                            <tbody>
                                {Array.isArray(orderHistory) &&
                                    orderHistory.map((x: any, i: any) => (
                                        <tr className="OdhistoryTableRow" key={i}>
                                            <td>{x.createdDate}</td>
                                            <td>{x.comments}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}

export default OrderDetails;
