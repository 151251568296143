import React, { useState, useEffect } from "react";
import ASuccess from "../alretbox/success";
import AFailed from "../alretbox/failed";
import { PaginationValues } from "../pagenation/pageflow";
import { Loadermessage, CreateinitialLoadermessage } from "../../dataservices/model/loadermessage";
import Loader from "../loader";
import { confirmAlert } from 'react-confirm-alert';
import { GetAllRuleType, GetFilfillmentTypebyRuleType, GetMarketplaceAccountNumberForRelationshipType,GetFilterDatabyModuleIdandFilterId, GetFilters, GetFiltersjson, GetProductsLoadbyRules, SaveProductRules, GetProductRulesLoadNF, DeletePRule, SaveProductRulesNF, DeletePRuleNF } from "../../dataservices/action/marketplace/marketplace";
import { channellist } from "../../dataservices/model/channellist";
import { filters, filtersobjs,storeFilter } from "../../dataservices/model/filters";
import { Createinitialjson, json } from "../../dataservices/model/json";
import { filterentities, products } from "../../dataservices/model/mpproducts";
import Multiselect from "multiselect-react-dropdown";
import axios from "axios";
import Atable from "../alretbox/table";


const NFProductRules = (props: any) => {
    const [isloader, Setisloader] = useState(CreateinitialLoadermessage());
    const [isLoader, setisLoader] = useState(false);
    const [activeprodclass, setactiveprodclass] = useState(true);
    const [activeEprdclass, setactiveEprdclass] = useState(false);
    var intallruletype: channellist[] = new Array<channellist>();
    const [ruleType, setruleType] = useState(intallruletype);
    const [ruleTypeId, setRuleTypeId] = useState(0);
    const [ruleTypeIdExist, setRuleTypeIdExist] = useState(0);
    var intfulfillmenttype: channellist[] = new Array<channellist>();
    const [fulfillmentType, setFulfillmentType] = useState(intfulfillmenttype);
    const [fulfillmentId, setFulfillmentId] = useState(0);
    const [fulfillmentIdExist, setFulfillmentIdExist] = useState(0);
    const [openDropdown, setOpenDropdown] = useState(false);
    const [selectCustomerId, setSelectCustomerId] = useState(0);
    var intMPAcclist: channellist[] = new Array<channellist>();
    const [NFClist, SetNFClist] = useState(intMPAcclist);
    var etylist: string[] = new Array<string>();
    const [enlist, setenlist] = useState(etylist);

    var intfilterlist: filters[] = new Array<filters>();
    const [filter, setfilterlist] = useState(intfilterlist);
    const [TotalCount, setTotalCount] = useState(0);
    const [Page, setPage] = useState(1);
    const [PageSize, setPageSize] = useState(100);
    const [PageSizeList, setPageSizeList] = useState([50, 100, 200]);
    const [activePage, setActivePage] = useState(0);
    var listofprod: object[] = new Array<object>();
    const [lstprod, setlstprod] = useState(listofprod);
    const [isjsonv, setisjsonv] = useState(Createinitialjson());
    var intproducts: products[] = new Array<products>()
    const [products, setProducts] = useState(intproducts);
    const [productcount, setproductcount] = useState(0);
    var intfilterobj: filtersobjs[] = new Array<filtersobjs>();
    const [filterobj, setfilterobj] = useState(intfilterobj);
    const [pjson, setpjson] = useState("");
    var filterlist: string[] = new Array<string>();
    const [flist, setflist] = useState(filterlist);
    const [fsku, setfsku] = useState("");
    const [tsku, settsku] = useState("");
    const [ischange, setischange] = useState(false);
    var mpfilter: filterentities[] = new Array<filterentities>();
    const [mpfilters, setmpfilters] = useState(mpfilter);
    const [APRules, setAPRules] = useState(true);
    const [EPRules, setEPRules] = useState(false);
    const [DPRules, setDPRules] = useState(false);
    const [showTable, setShowTable] = useState(false);
    const [accountNumber, setAccountNumber] = useState(0);



    //const [mpacclist, setmpacclist] = useState(intMPAcclist);

    var activeclassname = "nav-link active";
    var inactiveclassname = "nav-link";
    useEffect(() => {
        GetAllRuleType("D", function (result: any) {
            console.log("res....", result);
            setruleType(result);

        })
    }, [])
    useEffect(() => {
        GetFilfillmentTypebyRuleType(ruleTypeId, function (result: any) {
            console.log("res..fulfill..", result);
            setFulfillmentType(result);

        })

    }, [ruleTypeId])

    useEffect(() => {
        GetFilfillmentTypebyRuleType(ruleTypeIdExist, function (result: any) {
            console.log("res..fulfill..", result);
            setFulfillmentType(result);

        })

    }, [ruleTypeIdExist])

    useEffect(() => {

        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);

        GetFilters(5, function (result: any) {

            setfilterlist(result);
            GetFiltersjson(5, function (result: any) {

                setfilterobj(result);
                GetProductsLoadbyRules("1", result, Page, PageSize, flist, false, function (result: any) {

                    let load: Loadermessage = {
                        message: "",
                        isLoader: false,
                        isSuccess: false,
                        isfailed: false
                    }
                    if (result != null && result.length > 0) {
                        debugger;
                        setlstprod(result);
                        let jon: json = {
                            message: JSON.stringify(result),
                            isSuccess: true

                        }
                        setisjsonv(jon);

                        setpjson(JSON.stringify(result));
                        setProducts(result);
                        setTotalCount(result[0].totalRecord)
                        setproductcount(result.length)
                        setPage(result[0].index);
                        setPageSize(result[0].recordPerPage)
                        setActivePage(Page)


                    }
                    else {
                        let jon: json = {
                            message: JSON.stringify(result),
                            isSuccess: true

                        }
                        setisjsonv(jon);

                        setpjson(JSON.stringify(result));
                    }
                    Setisloader(load);
                })

            })


        })

    }, [])
    const handleChange = (e: any) => {
        if (e.target.id == 1) {
            setactiveprodclass(true);
            setactiveEprdclass(false);

        }
        if (e.target.id == 2) {

            setactiveprodclass(false);
            setactiveEprdclass(true);
            RefreshProductRules();
        }

    }
    const changeRuleType = (e: any) => {
        setRuleTypeId(e.target.value);
        setOpenDropdown(true);
    }
    const changeRuleTypeExisting = (e: any) => {
        setRuleTypeIdExist(e.target.value);
        GetProductRulesLoadNF(e.target.value, fulfillmentIdExist.toString(), accountNumber, 100, function (result: any) {
            let load: Loadermessage = {
                message: "",
                isLoader: false,
                isSuccess: false,
                isfailed: false
            }
            setlstprodrules(result);
            //setTotalCount(result[0].totalRecord)
            //setPage(result[0].index);
            setPageSize(result[0].recordPerPage)
            setActivePage(Page);
            setisLoader(false);
        })

        // setOpenDropdown(true);
    }
    const changeFulFillmentType = (e: any) => {
        setFulfillmentId(e.target.value);

        if (e.target.value == 5) {
            setShowTable(true);
            let load: Loadermessage = {
                message: "",
                isLoader: false,
                isSuccess: false,
                isfailed: false
            }
            GetMarketplaceAccountNumberForRelationshipType("D", function (result: any) {
                console.log("cust....", result);
                SetNFClist(result);
                setisLoader(false);
            })

        }

    }
    const changeFulFillmentTypeExisting = (e: any) => {
        debugger;
        setFulfillmentIdExist(e.target.value);

        if (e.target.value == 5) {
            setShowTable(true);
            let load: Loadermessage = {
                message: "",
                isLoader: false,
                isSuccess: false,
                isfailed: false
            }
            GetMarketplaceAccountNumberForRelationshipType("D", function (result: any) {
                console.log("cust....", result);
                SetNFClist(result);
                setisLoader(false);
            })

        }
        GetProductRulesLoadNF(ruleTypeIdExist.toString(), e.target.value, accountNumber, 100, function (result: any) {
            let load: Loadermessage = {
                message: "",
                isLoader: false,
                isSuccess: false,
                isfailed: false
            }
            setlstprodrules(result);
            // setTotalCount(result[0].totalRecord)
            // setPage(result[0].index);
            // setPageSize(result[0].recordPerPage)
            setActivePage(Page);
            setisLoader(false);
        })


    }
    const changeAccount = (e: any) => {
        setAccountNumber(e.target.value);
        GetProductRulesLoadNF(ruleTypeIdExist.toString(), fulfillmentIdExist.toString(), e.target.value, 100, function (result: any) {
            let load: Loadermessage = {
                message: "",
                isLoader: false,
                isSuccess: false,
                isfailed: false
            }
            setlstprodrules(result);
            // setTotalCount(result[0].totalRecord)
            //setPage(result[0].index);
            //setPageSize(result[0].recordPerPage)
            setActivePage(Page);
            setisLoader(false);
        })

    }
    const handleAllClick = (e: any) => {
        const { value, checked } = e.target;
        let tmpcust = NFClist.map((mp: any) => {

            return { ...mp, isSuccess: checked };
        });

        var ifilter = [...tmpcust];
        ifilter.forEach(element => {
            let entityval = element.customerId;
            if (element.isSuccess) {
                setenlist(enlist => [...enlist, String(entityval)]);
            }
            else {
                setenlist(enlist.filter((e) => e !== String(entityval)));
            }

        });
        console.log("enlist....", enlist);


        SetNFClist(tmpcust);

    }
    const handleMPAccClick = (id: number, e: any) => {
        const { value, checked } = e.target;
        let entityval = e.target.id;

        if (e.target.checked) {
            setenlist(enlist => [...enlist, entityval]);
        }
        else {
            setenlist(enlist.filter((e) => e !== entityval));
        }
        var ifilter1 = [...NFClist];
        ifilter1.forEach(element => {
            let entityval = e.target.id;
            if (element.customerId == entityval) {
                element.isSuccess = e.target.checked;
            }


        });

        console.log(enlist);

    }
    const changeFilterSku = (e: any) => {
        setfsku(e.target.value);
        if (e.target.value != "") {
            setischange(true);
        } else {
            setischange(false);
        }


    }
    const changeFilterTSku = (e: any) => {
        settsku(e.target.value);
        if (e.target.value != "") {
            setischange(true);
        } else {
            setischange(false);
        }

    }
    const handleRemove2 = (selectedList: any, selectedItem: any) => {
        debugger;
        var ifilter = [...filterobj];
        ifilter.forEach(element => {
            if (element.id === 2) {
                element.value1 = "";
                element.value1 = selectedList.join(',');
            }
        });

        setfilterobj(ifilter);
    }
    const handleSelect2 = (selectedList: any, selectedItem: any) => {

        //alert("hi");
        var ifilter = [...filterobj];
        ifilter.forEach(element => {
            if (element.id === 2) {
                element.value1 = selectedList.join(',');
            }
        });

        let mplistval: filterentities = {
            id: "2"
        }
        setmpfilters(mpfilters => [...mpfilters, mplistval]);
        setischange(true);
        setfilterobj(ifilter);
        console.log("Id2.....", selectedItem);
    }
    const handleRemove4 = (selectedList: any, selectedItem: any) => {
        debugger;
        var ifilter = [...filterobj];
        ifilter.forEach(element => {
            if (element.id === 4) {
                element.value1 = "";
                element.value1 = selectedList.join(',');
            }
        });
        setfilterobj(ifilter);
    }
    const handleSelect4 = (selectedList: any, selectedItem: any) => {
        debugger;
        //alert("hi");
        var ifilter = [...filterobj];
        ifilter.forEach(element => {
            if (element.id === 4) {

                element.value1 = selectedList.join(',');

            }
        });

        // const { value, checked } = e.target;
        //let entityval = e.target.id;

        let mplistval: filterentities = {
            id: "4"
        }
        setmpfilters(mpfilters => [...mpfilters, mplistval]);


        setischange(true);



        setfilterobj(ifilter);
        console.log(selectedItem);
    }
    const handleRemove5 = (selectedList: any, selectedItem: any) => {
        debugger;
        var ifilter = [...filterobj];
        ifilter.forEach(element => {
            if (element.id === 5) {
                element.value1 = "";
                element.value1 = selectedList.join(',');
            }
        });
        setfilterobj(ifilter);
    }
    const handleSelect5 = (selectedList: any, selectedItem: any) => {
        debugger;
        //alert("hi");
        var ifilter = [...filterobj];
        ifilter.forEach(element => {
            if (element.id === 5) {

                element.value1 = selectedList.join(',');

            }
        });

        //const { value, checked } = e.target;
        //let entityval = e.target.id;

        let mplistval: filterentities = {
            id: "5"
        }
        setmpfilters(mpfilters => [...mpfilters, mplistval]);


        setischange(true);



        setfilterobj(ifilter);
        console.log(selectedItem);
    }
    const handleRemove6 = (selectedList: any, selectedItem: any) => {
        debugger;
        var ifilter = [...filterobj];
        ifilter.forEach(element => {
            if (element.id === 6) {
                element.value1 = "";
                element.value1 = selectedList.join(',');
            }
        });
        setfilterobj(ifilter);
    }
    const handleSelect6 = (selectedList: any, selectedItem: any) => {
        debugger;
        //alert("hi");
        var ifilter = [...filterobj];
        ifilter.forEach(element => {
            if (element.id === 6) {

                element.value1 = selectedList.join(',');

            }
        });

        //const { value, checked } = e.target;
        //let entityval = e.target.id;

        let mplistval: filterentities = {
            id: "6"
        }
        setmpfilters(mpfilters => [...mpfilters, mplistval]);


        setischange(true);



        setfilterobj(ifilter);
        console.log(selectedItem);
    }
    const handleRemove11 = (selectedList: any, selectedItem: any) => {
        debugger;
        var ifilter = [...filterobj];
        ifilter.forEach(element => {
            if (element.id === 11) {
                element.value1 = "";
                element.value1 = selectedList.join(',');
            }
        });
        setfilterobj(ifilter);
    }
    const handleSelect11 = (selectedList: any, selectedItem: any) => {
        debugger;
        //alert("hi");
        var ifilter = [...filterobj];
        ifilter.forEach(element => {
            if (element.id === 11) {

                element.value1 = selectedList.join(',');

            }
        });

        //const { value, checked } = e.target;
        //let entityval = e.target.id;

        let mplistval: filterentities = {
            id: "11"
        }
        setmpfilters(mpfilters => [...mpfilters, mplistval]);


        setischange(true);



        setfilterobj(ifilter);
        console.log(selectedItem);
    }
    const handleRemove12 = (selectedList: any, selectedItem: any) => {
        debugger;
        var ifilter = [...filterobj];
        ifilter.forEach(element => {
            if (element.id === 12) {
                element.value1 = "";
                element.value1 = selectedList.join(',');
            }
        });
        setfilterobj(ifilter);
    }

    const handleSelect12 = (selectedList: any, selectedItem: any) => {
        debugger;
        //alert("hi");
        var ifilter = [...filterobj];
        ifilter.forEach(element => {
            if (element.id === 12) {

                element.value1 = selectedList.join(',');

            }
        });

        //const { value, checked } = e.target;
        //let entityval = e.target.id;

        let mplistval: filterentities = {
            id: "12"
        }
        setmpfilters(mpfilters => [...mpfilters, mplistval]);


        setischange(true);



        setfilterobj(ifilter);
        console.log(selectedItem);
    }
    const handleRemove13 = (selectedList: any, selectedItem: any) => {
        debugger;
        var ifilter = [...filterobj];
        ifilter.forEach(element => {
            if (element.id === 13) {
                element.value1 = "";
                element.value1 = selectedList.join(',');
            }
        });
        setfilterobj(ifilter);
    }
    const handleSelect13 = (selectedList: any, selectedItem: any) => {
        debugger;
        //alert("hi");
        var ifilter = [...filterobj];
        ifilter.forEach(element => {
            if (element.id === 13) {

                element.value1 = selectedList.join(',');

            }
        });

        //const { value, checked } = e.target;
        //let entityval = e.target.id;

        let mplistval: filterentities = {
            id: "13"
        }
        setmpfilters(mpfilters => [...mpfilters, mplistval]);


        setischange(true);



        setfilterobj(ifilter);
        console.log(selectedItem);
    }
    const handleRemove14 = (selectedList: any, selectedItem: any) => {
        debugger;
        var ifilter = [...filterobj];
        ifilter.forEach(element => {
            if (element.id === 14) {
                element.value1 = "";
                element.value1 = selectedList.join(',');
            }
        });
        setfilterobj(ifilter);
    }

    const handleSelect14 = (selectedList: any, selectedItem: any) => {
        debugger;
        //alert("hi");
        var ifilter = [...filterobj];
        ifilter.forEach(element => {
            if (element.id === 14) {

                element.value1 = selectedList.join(',');

            }
        });

        //const { value, checked } = e.target;
        //let entityval = e.target.id;

        let mplistval: filterentities = {
            id: "14"
        }
        setmpfilters(mpfilters => [...mpfilters, mplistval]);


        setischange(true);



        setfilterobj(ifilter);
        console.log(selectedItem);
    }
    const handleRemove15 = (selectedList: any, selectedItem: any) => {
        debugger;
        var ifilter = [...filterobj];
        ifilter.forEach(element => {
            if (element.id === 15) {
                element.value1 = "";
                element.value1 = selectedList.join(',');
            }
        });
        setfilterobj(ifilter);
    }

    const handleSelect15 = (selectedList: any, selectedItem: any) => {
        debugger;
        //alert("hi");
        var ifilter = [...filterobj];
        ifilter.forEach(element => {
            if (element.id === 15) {

                element.value1 = selectedList.join(',');

            }
        });

        //const { value, checked } = e.target;
        //let entityval = e.target.id;

        let mplistval: filterentities = {
            id: "15"
        }
        setmpfilters(mpfilters => [...mpfilters, mplistval]);


        setischange(true);



        setfilterobj(ifilter);
        console.log(selectedItem);
    }
    const handleRemove21 = (selectedList: any, selectedItem: any) => {
        debugger;
        var ifilter = [...filterobj];
        ifilter.forEach(element => {
            if (element.id === 21) {
                element.value1 = "";
                element.value1 = selectedList.join(',');
            }
        });
        setfilterobj(ifilter);
    }

    const handleSelect21 = (selectedList: any, selectedItem: any) => {
        debugger;
        //alert("hi");
        var ifilter = [...filterobj];
        ifilter.forEach(element => {
            if (element.id === 21) {

                element.value1 = selectedList.join(',');

            }
        });

        //const { value, checked } = e.target;
        //let entityval = e.target.id;

        let mplistval: filterentities = {
            id: "21"
        }
        setmpfilters(mpfilters => [...mpfilters, mplistval]);


        setischange(true);



        setfilterobj(ifilter);
        console.log(selectedItem);
    }
    const ApplyRules = (e: any) => {
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);


        var ifilter = [...filterobj];
        ifilter.forEach(element => {
            if (element.code === "SKU") {
                element.value1 = fsku;
                element.value2 = tsku;
            }
        });
        setfilterobj(ifilter);
        GetProductsLoadbyRules("1", filterobj, Page, PageSize, mpfilters, true, function (result: any) {

            let load: Loadermessage = {
                message: "",
                isLoader: false,
                isSuccess: false,
                isfailed: false
            }
            if (result != null && result.length > 0) {
                let jon: json = {
                    message: JSON.stringify(result),
                    isSuccess: true

                }
                setisjsonv(jon);

                setpjson(JSON.stringify(result));
                setProducts(result);
                setTotalCount(result.length);
                setproductcount(result.length);
                console.log(result.length);
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage)
                setActivePage(Page)
            }
            else {
                setProducts(result);
                let jon: json = {
                    message: JSON.stringify(result),
                    isSuccess: true

                }
                setisjsonv(jon);
                setTotalCount(0);
                let load: Loadermessage = {
                    message: "",
                    isLoader: false,
                    isSuccess: false,
                    isfailed: false
                }
                Setisloader(load);
            }
            Setisloader(load);
        })
    }
    function refreshPage() {
        window.location.reload();
    }

    const [filteravl, setfilteravl] = useState(false);
    const [invtypeValue, setinvtypeValue] = useState(4);
    const [filterSelected, setFilterSelected] = useState(false);
    const AddProductRules = (e: any) => {
        debugger;
        setisLoader(true);
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        var customerId = enlist.join();
        if (fulfillmentId == 3) {  //If it is ALL(NF)
            customerId = "0";
            enlist.length = 1;
        }
        var ifilter = [...filterobj];
        ifilter.forEach(element => {
            if (element.code === "SKU") {
                element.value1 = fsku;
                element.value2 = tsku;
            }
        });
        var changedValue = false;
        var ifilter2 = [...filterobj];
        console.log("ifilter2....", ifilter2);
        ifilter2.filter(element => {
            if (element.value1 != "") {
                setischange(true)
                changedValue = true;

            }
        });



        setfilterobj(ifilter);
        setfilteravl(false);



        console.log("setischange....", ischange);

        if (ruleTypeId != 0) {
            if (enlist.length != 0 && changedValue) {
                SaveProductRulesNF(customerId, filterobj, ruleTypeId, fulfillmentId, function (result: any) {
                    let load: Loadermessage = {
                        message: "Rule Activated Successfully",
                        isLoader: false,
                        isSuccess: true,
                        isfailed: false
                    }
                    Setisloader(load);
                    // setisLoader(false);
                    //RefreshProductRules();
                    history.go(0);
                })
            }
            else {
                if (enlist.length == 0) {
                    let result1 = CreateinitialLoadermessage();
                    result1.isfailed = true;
                    result1.message = "please select atleast one customer"
                    Setisloader(result1);
                    setisLoader(false);
                }
                else if (!changedValue) {
                    let result1 = CreateinitialLoadermessage();
                    result1.isfailed = true;
                    result1.message = "please select atleast one filter"
                    Setisloader(result1);
                    setisLoader(false);
                }
            }

        }
        else {
            let result1 = CreateinitialLoadermessage();
            result1.isfailed = true;
            result1.message = "please select Rule Type"
            Setisloader(result1);
            setisLoader(false);
        }
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    }
    const DownloadProductRule = () => {
        if (filterobj != null) {
            try {
                let load: Loadermessage = {
                    message: "",
                    isLoader: true,
                    isSuccess: false,
                    isfailed: false
                }
                Setisloader(load);
                debugger;
                var data = new FormData();
                data.append("filters", JSON.stringify(filterobj));
                const response = axios({
                    method: "post",
                    url: "api/MP/DownloadProductRule",
                    data: data,
                    headers: { "Content-Type": "multipart/form-data" },
                    responseType: 'blob',
                }).then((result) => {

                    let resultmes = CreateinitialLoadermessage();
                    if (result != null) {
                        debugger;
                        const url = window.URL.createObjectURL(new Blob([result.data]));
                        const a = document.createElement('a');
                        a.href = url;
                        a.setAttribute('download', "ProductRule.csv");
                        document.body.appendChild(a);
                        a.click();
                        a.remove();
                        URL.revokeObjectURL(url);

                    }
                    resultmes.isLoader = false;
                    Setisloader(resultmes);
                });
                //Setisloader(load);
            } catch (error) {
                console.log(error)
            }
        }
        else {
            let load: Loadermessage = {
                message: "Select Atleast one filter and download",
                isLoader: false,
                isSuccess: false,
                isfailed: true
            }
            Setisloader(load);
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

        }
    }
    const [etid, setetid] = useState(2);
    const [cgid, setcgid] = useState(0);
    const [ecgid, setecgid] = useState(0);
    const [accno, setaccno] = useState(0);
    var listofprodrules: products[] = new Array<products>();
    const [lstprodrules, setlstprodrules] = useState(listofprodrules);
    var intAcclist: channellist[] = new Array<channellist>();
    const [acclist, setacclist] = useState(intAcclist);

    var intcgrouplist: channellist[] = new Array<channellist>();
    const [cglist, setcglist] = useState(intcgrouplist);
    // const changeEType = (e: any) => {
    //     debugger;
    //     setetid(e.target.value);
    //     GetProductRulesLoadNF(0, 100, e.target.value, String(ecgid), String(accno), function (result: any) {
    //         let load: Loadermessage = {
    //             message: "",
    //             isLoader: false,
    //             isSuccess: false,
    //             isfailed: false
    //         }
    //         setlstprodrules(result);
    //         setTotalCount(result[0].totalRecord)
    //         setPage(result[0].index);
    //         setPageSize(result[0].recordPerPage)
    //         setActivePage(Page);
    //         setisLoader(false);
    //     })
    // }


    // const changeAccNo = (e: any) => {
    //     setaccno(e.target.value);
    //     GetProductRulesLoadNF(0, 100, String(etid), String(ecgid), e.target.value, function (result: any) {
    //         let load: Loadermessage = {
    //             message: "",
    //             isLoader: false,
    //             isSuccess: false,
    //             isfailed: false
    //         }
    //         setlstprodrules(result);
    //         setTotalCount(result[0].totalRecord)
    //         setPage(result[0].index);
    //         setPageSize(result[0].recordPerPage)
    //         setActivePage(Page);
    //         setisLoader(false);
    //     })
    // }
    // const changeEcustpmergroup = (e: any) => {
    //     let cgidv = e.target.value;
    //     setecgid(cgidv);
    //     debugger;
    //     GetProductRulesLoadNF(0, 100, String(etid), e.target.value, String(accno), function (result: any) {
    //         let load: Loadermessage = {
    //             message: "",
    //             isLoader: false,
    //             isSuccess: false,
    //             isfailed: false
    //         }
    //         setlstprodrules(result);
    //         setTotalCount(result[0].totalRecord)
    //         setPage(result[0].index);
    //         setPageSize(result[0].recordPerPage)
    //         setActivePage(Page);
    //         setisLoader(false);
    //     })
    // }
    const ClosePopup = () => {
        Setisloader(CreateinitialLoadermessage())
    }
    const DeleteAlert = (id: number) => {
        confirmAlert({
            title: 'Delete Rule',
            message: 'Are you sure you want to deactivate the Rule?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => Delete(id)
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    }
    const Delete = (id: number) => {
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        DeletePRuleNF(id, function (result: any) {
            if (result.isSuccess) {
                let result = CreateinitialLoadermessage();
                result.isSuccess = true;
                result.message = "Rule deactivated successfully"
                Setisloader(result);
                RefreshProductRules();
            }
        })
    }
    const RefreshProductRules = () => {
        setisLoader(true);
        GetProductRulesLoadNF(ruleTypeIdExist.toString(), fulfillmentIdExist.toString(), accountNumber, 100, function (result: any) {
            let load: Loadermessage = {
                message: "",
                isLoader: false,
                isSuccess: false,
                isfailed: false
            }
            setlstprodrules(result);
            setTotalCount(result[0].totalRecord)
            setPage(result[0].index);
            setPageSize(result[0].recordPerPage)
            setActivePage(Page);
            setisLoader(false);
            console.log("setlstprodrules....", result);
        })
    }

    const handleSelect = (selectedList: any, selectedItem: any, filterId: any) => {
        console.log("sele....", filterId)
        //alert("hi");
        var ifilter = [...filterobj];
        ifilter.forEach(element => {
            if (element.id === filterId) {
                //  console.log("coming....")
                element.value1 = selectedList.join(',');
            }
        });
        setfilterobj(ifilter);
        // console.log("selectedItem....",ifilter);
    }
    const eventRef = React.useRef(null);
    const handleRemove = (selectedList: any, selectedItem: any, filterId: any) => {
        // debugger;
        var ifilter = [...filterobj];
        ifilter.forEach(element => {
            if (element.id === filterId) {
                element.value1 = "";
                element.value1 = selectedList.join(',');
            }
        });
        setfilterobj(ifilter);
        const event: any = eventRef.current;
        event.stopPropagation();
        //console.log("DeselectedItem....",ifilter);
    }
    // var intfilterlist1: filters[] = new Array<filters>();
    // const [options1, setOptions1] = useState(intfilterlist1);
    // const [isLoading, setIsLoading] = useState(false);
    // const loadOptions = (e: any) => {
    //     console.log("calling")
    //     console.log("DropDownClicked....", e.currentTarget.getAttribute('data-d-id'));
    //     let filterId = e.currentTarget.getAttribute('data-d-id');
    //     // setOptions([]);
    //     setIsLoading(true);
    //     GetFilterDatabyModuleIdandFilterId(1, filterId, function (result: any) {
    //         const optionsList = result.map((item: any) => item.value);
    //         setOptions1(optionsList);
    //         setIsLoading(false);
    //     });

    // }
    const [options1, setOptions1] = useState<string[]>([]);
    const [storeFilters, setStoreFilters] = useState<storeFilter[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const loadOptions = (e: any) => {
        setOptions1([]);
        let filterId: number = e.currentTarget.getAttribute('data-d-id');
        setIsLoading(true);
        let isFilterExist: storeFilter[] = storeFilters.filter(x => x.filterID == filterId);
        if (isFilterExist.length > 0) {
            setOptions1(isFilterExist[0].values);
            setIsLoading(false);
        } else {
            GetFilterDatabyModuleIdandFilterId(1, filterId, function (result: any) {
                const optionsList: string[] = result.map((item: any) => item.value);
                let makeFilteredStoredData: storeFilter = {
                    filterID: filterId,
                    values: optionsList
                };
                let sum: storeFilter[] = storeFilters;
                sum.push(makeFilteredStoredData);
                setStoreFilters(storeFilters);
                setOptions1(optionsList);
                setIsLoading(false);
            });
        }
    }
    return (
        <React.Fragment>
            <div className="row">
                {

                    isloader.isLoader ? (<Loader isActive={isloader} />) : (null)
                }
                <div className="col-md-12">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/userdashboard"><i className="bi bi-house-door-fill pe-1"></i>Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page">
                                Product Rules(NF)
                            </li>
                        </ol>
                    </nav>
                    <div className="heading-section">
                        <h1>Product Rules(NF)</h1>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <ul className="nav nav-pills">
                                <li className="nav-item">
                                    <button className={activeprodclass == true ? activeclassname : inactiveclassname} aria-current="page" id="1" onClick={handleChange} >New Product Rule</button>
                                </li>
                                <li className="nav-item">
                                    <button className={activeEprdclass == true ? activeclassname : inactiveclassname} id="2" onClick={handleChange}>Existing Product Rules</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className={activeprodclass == true ? "" : "hidden"}>
                        <div className="tab-content">
                            <div className="row">
                                <div className="col-6">
                                    <div className="row g-2 mb-3 align-items-center">
                                        <div className="col-4">
                                            <label className="form-label">
                                                Rule Type
                                            </label>
                                        </div>
                                        <div className="col-8">
                                            <div className="form-check">
                                                <select className="form-select" onChange={(e) => changeRuleType(e)} >
                                                    <option value="0">Select</option>
                                                    {
                                                        ruleType.filter(item => item.resultid != 1 && item.resultid != 4).map((item, index) => (

                                                            <option value={item.resultid}>{item.name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        {
                                            openDropdown ? (
                                                <>
                                                    <div className="col-4">
                                                        <label className="form-label">
                                                            Customer Type
                                                        </label>
                                                    </div>
                                                    <div className="col-8">
                                                        <div className="form-check">
                                                            <select className="form-select" onChange={(e) => changeFulFillmentType(e)} >
                                                                <option value="0">Select</option>
                                                                {
                                                                    fulfillmentType.map((item, index) => (

                                                                        <option value={item.resultid}>{item.name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </>


                                            ) : (null)
                                        }

                                    </div>
                                </div>
                                <div className="col-12">
                                    {/* <div className="row g-2 mb-3 align-items-center"> */}
                                    <div className="row">
                                        <div className="col-sm-12 col-md-12">
                                            <table className="table table-bordered">
                                                {fulfillmentId == 5 ?

                                                    <thead className="table-light">
                                                        <tr>
                                                            <th scope="col">
                                                                <div className="form-check"><input className="form-check-input" type="checkbox" id="" onChange={(e) => handleAllClick(e)} value="" /></div>
                                                            </th>
                                                            <th scope="col">
                                                                Name
                                                            </th>
                                                            <th scope="col">
                                                                Account Number
                                                            </th>


                                                        </tr>
                                                    </thead> : (null)}
                                                <tbody>
                                                    {
                                                        NFClist != null && fulfillmentId == 5 ? (
                                                            NFClist.map((item: any, index) => (
                                                                <tr key={index}>
                                                                    <td>
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="checkbox" name="entities" value={item.id} onChange={(e) => handleMPAccClick(item.customerId, e)} checked={item.isSuccess ? true : false} id={item.customerId.toString()} />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        {item.name}
                                                                    </td>
                                                                    <td>
                                                                        {item.message}
                                                                    </td>

                                                                </tr>
                                                            ))) : (null)
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    {/* </div> */}

                                    <h2 className="pb-2 primarycolor">Filters</h2>
                                    <div className="section-border-new">
                                        <div className="row">
                                            {
                                                filter != null ? (
                                                    filter.map((item, index) => (

                                                        item.ftid == 3 ? <div className="col-6 mb-2">
                                                            <div className="row g-3 align-items-end">
                                                                <div className="col-6">
                                                                    <label className="form-label">From SKU</label>
                                                                    <input type="text" onChange={changeFilterSku} placeholder="" className="form-control" value={fsku} />
                                                                </div>
                                                                <div className="col-6">
                                                                    <label className="form-label">To SKU</label>
                                                                    <input type="text" onChange={changeFilterTSku} placeholder="" className="form-control" value={tsku} />
                                                                </div>

                                                            </div>
                                                        </div> :

                                                            item.ftid == 1 && item.name != "Override" ? <div className="col-3 mb-2">
                                                                <label className="form-label">
                                                                    {item.name}
                                                                </label>
                                                                <div onClick={loadOptions} data-d-id={item.id}>
                                                                    <Multiselect
                                                                        isObject={false}
                                                                        displayValue="name"
                                                                        //placeholder="All"
                                                                        // onDropdownToggle={handleDropdownToggle}

                                                                        onKeyPressFn={function noRefCheck() { }}
                                                                        // onRemove={handleRemove}
                                                                        onRemove={(selectedList, selectedItem) => handleRemove(selectedList, selectedItem, item.id)}
                                                                        onSearch={function noRefCheck() { }}
                                                                        // onSelect={handleSelect}
                                                                        onSelect={(selectedList, selectedItem) => handleSelect(selectedList, selectedItem, item.id)}
                                                                        // options={item.filtersvalues.map(x => x.value)}
                                                                        options={options1}
                                                                        loading={isLoading}

                                                                    />
                                                                </div>
                                                            </div> : ""

                                                        // item.id == 2 ? <div className="col-3 mb-2">
                                                        //     <label className="form-label">
                                                        //         {item.name}
                                                        //     </label>
                                                        //     <Multiselect
                                                        //         isObject={false}
                                                        //         displayValue="name"
                                                        //         placeholder="All"
                                                        //         onKeyPressFn={function noRefCheck() { }}
                                                        //         onRemove={handleRemove2}
                                                        //         onSearch={function noRefCheck() { }}
                                                        //         onSelect={handleSelect2}

                                                        //         options={item.filtersvalues.map(x => x.value)}
                                                        //     />
                                                        //     {/*<MultiSelect*/}
                                                        //     {/*    options={item.filtersvalues}*/}
                                                        //     {/*    value={selected}*/}
                                                        //     {/*    onChange={handleSelect}*/}
                                                        //     {/*    labelledBy="Select"*/}
                                                        //     {/*/>*/}
                                                        //     {/*<Select*/}
                                                        //     {/*    options={optionList}*/}
                                                        //     {/*    placeholder="Select color"*/}
                                                        //     {/*    value={selectedOptions}*/}
                                                        //     {/*    onChange={handleSelect}*/}
                                                        //     {/*/>*/}
                                                        //     {/*<select className="form-select" multiple onChange={(e) => changeFilters(item.id, item.name, e)}>*/}
                                                        //     {/*    <option selected value={""}>Select</option>*/}
                                                        //     {/*    {*/}
                                                        //     {/*        item.filtersvalues != null ? (*/}
                                                        //     {/*            item.filtersvalues.map((itemval, index) => (*/}
                                                        //     {/*                <>*/}
                                                        //     {/*                    <option value={itemval.key}>{itemval.value}</option>*/}
                                                        //     {/*                </>*/}

                                                        //     {/*            ))*/}
                                                        //     {/*        ) : (null)*/}
                                                        //     {/*    }*/}
                                                        //     {/*</select>*/}
                                                        // </div> : item.id == 4 ? <div className="col-3 mb-2">
                                                        //     <label className="form-label">
                                                        //         {item.name}
                                                        //     </label>
                                                        //     <Multiselect
                                                        //         isObject={false}
                                                        //         displayValue="name"
                                                        //         placeholder="All"

                                                        //         onKeyPressFn={function noRefCheck() { }}
                                                        //         onRemove={handleRemove4}
                                                        //         onSearch={function noRefCheck() { }}
                                                        //         onSelect={handleSelect4}

                                                        //         options={item.filtersvalues.map(x => x.value)}
                                                        //     />
                                                        // </div> : item.id == 5 ? <div className="col-3 mb-2">
                                                        //     <label className="form-label">
                                                        //         {item.name}
                                                        //     </label>
                                                        //     <Multiselect
                                                        //         isObject={false}
                                                        //         displayValue="name"
                                                        //         placeholder="All"

                                                        //         onKeyPressFn={function noRefCheck() { }}
                                                        //         onRemove={handleRemove5}
                                                        //         onSearch={function noRefCheck() { }}
                                                        //         onSelect={handleSelect5}

                                                        //         options={item.filtersvalues.map(x => x.value)}
                                                        //     />
                                                        // </div> : item.id == 6 ? <div className="col-3 mb-2">
                                                        //     <label className="form-label">
                                                        //         {item.name}
                                                        //     </label>
                                                        //     <Multiselect
                                                        //         isObject={false}
                                                        //         displayValue="name"
                                                        //         placeholder="All"

                                                        //         onKeyPressFn={function noRefCheck() { }}
                                                        //         onRemove={handleRemove6}
                                                        //         onSearch={function noRefCheck() { }}
                                                        //         onSelect={handleSelect6}

                                                        //         options={item.filtersvalues.map(x => x.value)}
                                                        //     />
                                                        // </div> : item.id == 11 ? <div className="col-3 mb-2">
                                                        //     <label className="form-label">
                                                        //         {item.name}
                                                        //     </label>
                                                        //     <Multiselect
                                                        //         isObject={false}
                                                        //         displayValue="name"
                                                        //         placeholder="All"

                                                        //         onKeyPressFn={function noRefCheck() { }}
                                                        //         onRemove={handleRemove11}
                                                        //         onSearch={function noRefCheck() { }}
                                                        //         onSelect={handleSelect11}

                                                        //         options={item.filtersvalues.map(x => x.value)}
                                                        //     />
                                                        // </div> : item.id == 12 ? <div className="col-3 mb-2">
                                                        //     <label className="form-label">
                                                        //         {item.name}
                                                        //     </label>
                                                        //     <Multiselect
                                                        //         isObject={false}
                                                        //         displayValue="name"
                                                        //         placeholder="All"

                                                        //         onKeyPressFn={function noRefCheck() { }}
                                                        //         onRemove={handleRemove12}
                                                        //         onSearch={function noRefCheck() { }}
                                                        //         onSelect={handleSelect12}

                                                        //         options={item.filtersvalues.map(x => x.value)}
                                                        //     />
                                                        // </div> : item.id == 13 ? <div className="col-3 mb-2">
                                                        //     <label className="form-label">
                                                        //         {item.name}
                                                        //     </label>
                                                        //     <Multiselect
                                                        //         isObject={false}
                                                        //         displayValue="name"
                                                        //         placeholder="All"

                                                        //         onKeyPressFn={function noRefCheck() { }}
                                                        //         onRemove={handleRemove13}
                                                        //         onSearch={function noRefCheck() { }}
                                                        //         onSelect={handleSelect13}

                                                        //         options={item.filtersvalues.map(x => x.value)}
                                                        //     />
                                                        // </div> : item.id == 14 ? <div className="col-3 mb-2">
                                                        //     <label className="form-label">
                                                        //         {item.name}
                                                        //     </label>
                                                        //     <Multiselect
                                                        //         isObject={false}
                                                        //         displayValue="name"
                                                        //         placeholder="All"

                                                        //         onKeyPressFn={function noRefCheck() { }}
                                                        //         onRemove={handleRemove14}
                                                        //         onSearch={function noRefCheck() { }}
                                                        //         onSelect={handleSelect14}

                                                        //         options={item.filtersvalues.map(x => x.value)}
                                                        //     />
                                                        // </div> : item.id == 15 ? <div className="col-3 mb-2">
                                                        //     <label className="form-label">
                                                        //         {item.name}
                                                        //     </label>
                                                        //     <Multiselect
                                                        //         isObject={false}
                                                        //         displayValue="name"
                                                        //         placeholder="All"

                                                        //         onKeyPressFn={function noRefCheck() { }}
                                                        //         onRemove={handleRemove15}
                                                        //         onSearch={function noRefCheck() { }}
                                                        //         onSelect={handleSelect15}

                                                        //         options={item.filtersvalues.map(x => x.value)}
                                                        //     />
                                                        // </div> : item.id == 21 ? <div className="col-3 mb-2">
                                                        //     <label className="form-label">
                                                        //         {item.name}
                                                        //     </label>
                                                        //     <Multiselect
                                                        //         isObject={false}
                                                        //         displayValue="name"
                                                        //         placeholder="All"

                                                        //         onKeyPressFn={function noRefCheck() { }}
                                                        //         onRemove={handleRemove21}
                                                        //         onSearch={function noRefCheck() { }}
                                                        //         onSelect={handleSelect21}

                                                        //         options={item.filtersvalues.map(x => x.value)}
                                                        //     />
                                                        // </div> : ""

                                                    ))
                                                ) : (null)
                                            }

                                        </div>
                                        <div className="row">
                                            <div className="col-12 mb-3">
                                                <div className="d-flex justify-content-end align-items-center">
                                                    {/* <button type="button" disabled={APRules != true ? true : false} className="btn btn-wh-1 btn-primary" onClick={(e) => ApplyRules(e)}>Filter</button> */}
                                                    <button type="button" disabled={APRules != true ? true : false} className="btn btn-wh-1 btn-primary ms-2" onClick={refreshPage}>Reset</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 mb-2 text-center">
                                            <button type="button" disabled={APRules != true ? true : false} className="btn btn-wh-2 btn-primary" onClick={(e) => AddProductRules(e)}>Create Rule</button>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {/* <div className="col-12 mb-2">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <h2 className="m-0 p-0 primarycolor">Products</h2>
                                                <span><strong>Total Records:{productcount}</strong></span>
                                                <a target="_blank" type="button" className="btn btn-wh-1 btn-primary float-end" aria-disabled={EPRules != true ? true : false} onClick={DownloadProductRule}>Download</a>

                                                
                                            </div>
                                        </div> */}
                                    </div>
                                    {/* <div className="row">
                                        <div className="col-sm-12 col-md-12">
                                            <div className="table-scroll-section">
                                                {
                                                    isjsonv.isSuccess ? (
                                                        <Atable message={isjsonv.message} />
                                                    ) : (null)

                                                }
                                            </div>
                                        </div>
                                    </div> */}

                                </div>

                            </div>


                        </div>
                    </div>
                    <div className={activeEprdclass == true ? "" : "hidden"}>
                        <div className="tab-content">
                            <div className="section-border-new">
                                <h2 className="pb-2 primarycolor">Filters</h2>
                                <div className="row">
                                    <div className="col-4">
                                        <label className="form-label">
                                            Rule Type
                                        </label>
                                        <div className="form-check p-0">
                                            {/* <select className="form-select" aria-label="Default select example" onChange={changeEType}> */}
                                            {/* <select className="form-select" aria-label="Default select example">
                                                <option selected value="4">All</option>
                                                <option value="0">Include</option>
                                                <option value="1">Exclude</option>
                                                <option value="2">Always Include</option>
                                            </select> */}
                                            <select className="form-select" onChange={(e) => changeRuleTypeExisting(e)} >
                                                <option value="0">Select</option>
                                                {
                                                    ruleType.filter(item => item.resultid != 1 && item.resultid != 4).map((item, index) => (

                                                        <option value={item.resultid}>{item.name}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-4 mb-3">
                                        <label className="form-label">
                                            Customer Type
                                        </label>
                                        <div className="form-check p-0">
                                            {/* <select className="form-select" onChange={(e) => changeAccNo(e)} > */}

                                            <select className="form-select" onChange={(e) => changeFulFillmentTypeExisting(e)} >
                                                <option value="0">Select</option>
                                                {
                                                    fulfillmentType.map((item, index) => (

                                                        <option value={item.resultid}>{item.name}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <label className="form-label">
                                            Account Number
                                        </label>
                                        <div className="form-check p-0">
                                            {/* <select className="form-select" aria-label="Default select example" onChange={changeEcustpmergroup}> */}
                                            <select className="form-select" aria-label="Default select example" onChange={(e) => changeAccount(e)}>
                                                <option selected value="0">All</option>
                                                {
                                                    NFClist != null ? (
                                                        NFClist.map((itemval, index) => (
                                                            <option value={itemval.customerId}>{itemval.message}</option>
                                                        ))
                                                    ) : (null)
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 col-md-12">
                                    <div className="table-scroll-section">
                                        <table className="table table-bordered">
                                            <thead className="table-light">
                                                <tr>
                                                    <th scope="col">
                                                        Rule Type
                                                    </th>
                                                    <th scope="col">
                                                        Rule Created Date
                                                    </th>
                                                    <th scope="col">
                                                        Customers
                                                    </th>
                                                    <th scope="col">
                                                        Customer Type
                                                    </th>
                                                    <th scope="col">
                                                        Rule Criteria
                                                    </th>
                                                    <th scope="col">
                                                        Action
                                                    </th>


                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    lstprodrules != null ? (
                                                        lstprodrules.map((item, index) => (
                                                            <tr key={index}>
                                                                <td>
                                                                    {item.rtname}
                                                                </td>
                                                                <td>
                                                                    {item.rDate}
                                                                </td>
                                                                <td>
                                                                    {item.rcustomers}
                                                                </td>
                                                                <td>
                                                                    {item.ftname}
                                                                </td>
                                                                <td>
                                                                    {item.rrjson}
                                                                </td>
                                                                <td>
                                                                    <button className="btn btn-sm btn-danger" data-toggle="tooltip" data-placement="top" title="Delete Rule" onClick={(e) => DeleteAlert(item.id)} ><i className="bi bi-trash-fill"></i></button>
                                                                </td>
                                                            </tr>
                                                        ))) : (null)
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div onClick={ClosePopup}>
                        {
                            isloader.isfailed ? (
                                <AFailed message={isloader.message} />
                            ) : (null)
                        }
                    </div>
                    <div onClick={ClosePopup}>
                        {
                            isloader.isSuccess ? (
                                <ASuccess message={isloader.message} />
                            ) : (null)
                        }
                    </div>
                </div>


            </div>
        </React.Fragment>

    )
}
export default NFProductRules;