import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { SSL_OP_COOKIE_EXCHANGE } from "constants";
import SideBar from "../sidebar";
import { useHistory } from "react-router";
import { CreateinitialLoadermessage, Loadermessage } from "../../dataservices/model/loadermessage";
import { channellist } from "../../dataservices/model/channellist";
import { GetChannellistService } from "../../dataservices/action/dashboard/channellist";
import { entities, jobDetails, createinitialjobDetails } from "../../dataservices/model/jobDetails";
import { DeleteEntity, DeleteInventoryData, GetAllCustomerGroup, GetAllMarketplacebyAccountId, GetAllMarketplaces, GetCategoryMPProducts, GetFilters, GetFiltersjson, GetInventoryentitylist, GetInventoryLoadbyFilter, GetMPEntityDetailsByFIlter, GetMPEntityDetailsById, GetmpLocationsANDagilityBranch, GetNFInventoryData, Getrolepermissionbyuserid, GetSuppliers, GetValuesFromMpProducts, SaveEntityDetailsById, SaveNFinventoryById } from "../../dataservices/action/marketplace/marketplace";
import { PaginationValues } from "../pagenation/pageflow";
import { confirmAlert } from "react-confirm-alert";
import { debug } from "console";
import Loader from "../loader";
import { moduleinfo } from "../../dataservices/model/user";
import { GetEditPermission } from "../../dataservices/action/account/register";
import { filterentities, products, createinitialProducts } from "../../dataservices/model/mpproducts";
import moment from "moment";
import AFailed from "../alretbox/failed";
import ASuccess from "../alretbox/success";
import { filters, filtersobjs } from "../../dataservices/model/filters";
import axios from "axios";
import InfoIcon from "@material-ui/icons/Info";
import Popover from "@material-ui/core/Popover";
import Multiselect from "multiselect-react-dropdown";
import { nfinventory } from "../../dataservices/model/nfinventory";



const nfinventoryRules = () => {
    const history = useHistory();
    const [isloader, Setisloader] = useState(CreateinitialLoadermessage());
    var intcgrouplist: channellist[] = new Array<channellist>();
    const [cglist, setcglist] = useState(intcgrouplist);

    const store = useSelector((state: any) => { return state })
    const dispatch = useDispatch();
    var intchannelsiteslist: channellist[] = new Array<channellist>();
    const [channellist, Setchannellist] = useState(intchannelsiteslist);
    const [channelId, setChannelId] = useState(0);
    const [iscategoryorothers, setiscategoryorothers] = useState(1);
    const [activecatclass, setactivecatclass] = useState(true);
    const [activeprdclass, setactiveprdclass] = useState(false);
    const [activeitemclass, setactiveitemclass] = useState(false);
    const [entityid, setentityid] = useState("");
    const [entityname, setentityname] = useState("");
    //Search List
    var intlist: products[] = new Array<products>();
    const [entitylist, Setentitylist] = useState(intlist);

    const [isChecked, setischecked] = useState(false);
    const [minimumqty, setminimumqty] = useState("");
    const [fixedinv, setfixedinv] = useState("");
    var intentylist: jobDetails[] = new Array<jobDetails>();
    const [entylist, Setentylist] = useState(intentylist);

    const [invalue, setinvalue] = useState("");
    const [chkValue, setchkValue] = useState(false);
    const [invtypeValue, setinvtypeValue] = useState(0);
    const [isvalue, setisvalue] = useState("");
    const [ispervalue, setispervalue] = useState(false);
    const [isfixvalue, setisfixvalue] = useState(false);
    var activeclassname = "nav-link active";
    var inactiveclassname = "nav-link";
    var etylist: string[] = new Array<string>();
    const [enlist, setenlist] = useState(etylist);
    const [IsallMP, setIsallMP] = useState(false);
    const [IsMarkdown, setIsMarkdown] = useState(false);
    const etylist1: string[] = [];
    const [activePage, setActivePage] = useState(0);
    const [TotalCount, setTotalCount] = useState(0);
    const [Page, setPage] = useState(1);
    const [PageSize, setPageSize] = useState(50);
    const [PageSizeList, setPageSizeList] = useState([50, 100, 200]);
    const [SearchValue, setSearchValue] = useState("");
    const [tablescrollsection, settablescrollsection] = useState(false);
    var intcatlist: jobDetails[] = new Array<jobDetails>();
    const [catList, setcatList] = useState(intcatlist);
    const [categoryId, setcategoryID] = useState(0);
    const [supplierId, setsupplierId] = useState("");

    const [isLoader, setisLoader] = useState(false);
    const [activePage1, setActivePage1] = useState(0);
    const [TotalCount1, setTotalCount1] = useState(0);
    const [Page1, setPage1] = useState(1);
    const [PageSize1, setPageSize1] = useState(50);
    const [PageSizeList1, setPageSizeList1] = useState([50, 100, 200]);
    var vperList: moduleinfo[] = new Array<moduleinfo>();
    const [VPList, setVPList] = useState(vperList);
    const [ECategoryAttributes, setVCategoryAttributes] = useState(false);
    const [EAPIsettings, setVAPIsettings] = useState(false);
    const [EMagentoSync, setVMagentoSync] = useState(false);
    const [EInriverSync, setVInriverSync] = useState(false);
    const [EProducts, setVProducts] = useState(false);
    const [EInventoryRules, setVInventoryRules] = useState(false);
    const [Elogs, setVlogs] = useState(false);
    const [ESettings, setVSettings] = useState(false);
    const [isDisabled, setisDisabled] = useState("disabled");

    var mpentityList: entities[] = new Array<entities>();
    const [mpList, setmpList] = useState(mpentityList);

    var intMPAcclist: channellist[] = new Array<channellist>();
    const [mpacclist, setmpacclist] = useState(intMPAcclist);

    const [stateval, setStateval] = useState("");
    const [isaccChecked, setisaccchecked] = useState(false);
    const [IsaccOrLocation, setIsaccOrLocation] = useState(false);

    const [isAllChecked, setisAllChecked] = useState(false);
    const [isdisabled, setisdisabled] = useState(false);
    const [isfdisabled, setisfdisabled] = useState(false);
    const [startDate, setstartDate] = useState(new Date());
    const [endDate, setendDate] = useState(new Date("2049-12-31T23:59:59"));
    const [searchDate, setsearchDate] = useState("");
    const [searchEndDate, setsearchEndDate] = useState("");
    const [utcStartDate, setutcStartDate] = useState("");
    const [utcEndDate, setutcEndDate] = useState(moment.utc(moment("2049-12-31T23:59:59").utc()).format());
    const [isDate, setisDate] = useState(false);
    var intsupplist: jobDetails[] = new Array<jobDetails>();
    const [suppList, setsuppList] = useState(intsupplist);

    var intPrdgrplist: channellist[] = new Array<channellist>();
    const [Prdgrplist, setPrdgrplist] = useState(intPrdgrplist);

    var intPricemaxlist: channellist[] = new Array<channellist>();
    const [Pricemaxlist, setPricemaxlist] = useState(intPricemaxlist);

    var intPriceminlist: channellist[] = new Array<channellist>();
    const [Priceminlist, setPriceminlist] = useState(intPriceminlist);


    var intmfglist: channellist[] = new Array<channellist>();
    const [mfglist, setmfglist] = useState(intmfglist);

    const [prdgrp, setprdgrp] = useState("");
    const [pricemax, setpricemax] = useState("");
    const [pricemin, setpricemin] = useState("");
    const [mfg, setmfg] = useState("");

    const [prdgrpname, setprdgrpname] = useState("");
    const [pricemaxname, setpricemaxname] = useState("");
    const [priceminname, setpriceminname] = useState("");
    const [mfgname, setmfgname] = useState("");
    const [fsku, setfsku] = useState("");
    const [tsku, settsku] = useState("");
    const [efsku, setefsku] = useState("");
    const [etsku, setetsku] = useState("");
    var intfilterlist: filters[] = new Array<filters>();
    const [filter, setfilterlist] = useState(intfilterlist);

    var intfilterobj: filtersobjs[] = new Array<filtersobjs>();
    const [filterobj, setfilterobj] = useState(intfilterobj);
    var mpfilter: filterentities[] = new Array<filterentities>();
    const [mpfilters, setmpfilters] = useState(mpfilter);

    var intfilterlist1: filters[] = new Array<filters>();
    const [filter1, setfilterlist1] = useState(intfilterlist1);

    var intfilterobj1: filtersobjs[] = new Array<filtersobjs>();
    const [filterobj1, setfilterobj1] = useState(intfilterobj1);
    var mpfilter1: filterentities[] = new Array<filterentities>();
    const [mpfilters1, setmpfilters1] = useState(mpfilter1);
    const [cgid, setcgid] = useState(0);
    const [isApplyfilter, setisApplyfilter] = useState(false);

    var intnflist: nfinventory[] = new Array<nfinventory>();
    const [nfilist, setnfilist] = useState(intnflist);

    const changeFilterSku = (e: any) => {

        setfsku(e.target.value);
    }
    const changeFilterTSku = (e: any) => {
        settsku(e.target.value);

    }


    const changeEFilterSku = (e: any) => {
        setefsku(e.target.value);

    }


    const changeEFilterTSku = (e: any) => {
        setetsku(e.target.value);

    }

    //useEffect(() => {
    //    setisLoader(true);
    //    GetNFInventoryData(Page, PageSize, function (result: nfinventory[]) {
    //        if (result != null && result.length > 0) {
    //            setnfilist(result);
    //            setTotalCount(result[0].totalRecord);
    //            SetPage(result[0].index);
    //            SetPageSize(result[0].recordPerPage);
    //            setActivePage(Page);
    //            setisLoader(false);
    //        }
    //        else {
    //            setnfilist(result);
    //            setisLoader(false);
    //        }
    //    })
    //}, [])
    //useEffect(() => {
    //    setisLoader(true);
    //    GetValuesFromMpProducts(2, function (result: any) {
    //        setPricemaxlist(result);
    //        setisLoader(false);

    //    })
    //}, [])
    //useEffect(() => {
    //    setisLoader(true);
    //    GetValuesFromMpProducts(3, function (result: any) {
    //        setPriceminlist(result);
    //        setisLoader(false);

    //    })
    //}, [])
    //useEffect(() => {
    //    setisLoader(true);
    //    GetValuesFromMpProducts(4, function (result: any) {
    //        setmfglist(result);
    //        setisLoader(false);

    //    })
    //}, [])
    const [AINVRules, setAINVRules] = useState(false);
    const [EINVRules, setEINVRules] = useState(false);
    const [DINVRules, setDINVRules] = useState(false);
    //const [CSIMsync, setCSIMsync] = useState(false);
    useEffect(() => {
        Getrolepermissionbyuserid(1, function (result: any) {
            if (result != null) {
                if (result != null) {
                    for (var i = 0; i < result.length; i++) {
                        console.log(result[i].name);
                        switch (result[i].permissionid) {
                            case 28:
                                if (result[i].canadd == "1") {
                                    setAINVRules(true);
                                }
                                if (result[i].canedit == "1") {
                                    setEINVRules(true);
                                }
                                if (result[i].candelete == "1") {
                                    setDINVRules(true);
                                }
                                break;
                        }
                    }
                }
            }
        })
    }, [])

    useEffect(() => {
        setisLoader(true);
        // GetFilters(3, function (result: any) {
        // setfilterlist1(result);
        // setisLoader(true);
        //GetFiltersjson(3, function (result: any) {
        //    setfilterobj1(result);
        //    console.log("res....", result)
        //    setisLoader(true);
        GetNFInventoryData(Page, PageSize, function (result: any) {
            if (result != null && result.length > 0) {
                setnfilist(result);
                setTotalCount(result[0].totalRecord);
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage);
                setActivePage(Page1);
                settablescrollsection(true);
                setisLoader(false);
            }
            else {
                setnfilist(result);
                setisLoader(false);
            }

        })
        // });
        // });
    }, [])
    //useEffect(() => {
    //    setisLoader(true);

    //    GetNFInventoryData(Page, PageSize, function (result: nfinventory[]) {
    //        if (result != null && result.length > 0) {
    //            setnfilist(result);
    //            setTotalCount(result[0].totalRecord);
    //            SetPage(result[0].index);
    //            SetPageSize(result[0].recordPerPage);
    //            setActivePage(Page);
    //            setisLoader(false);
    //        }
    //        else {
    //            setnfilist(result);
    //            setisLoader(false);
    //        }
    //    })

    //}, [])


    const ExistingInvProductLoad = () => {
        setisLoader(true);

        GetFilters(4, function (result: any) {

            setfilterlist(result);
            GetFiltersjson(4, function (result: any) {

                setfilterobj(result);
                GetInventoryLoadbyFilter(result, Page, PageSize, function (result: any) {
                    if (result != null && result.length > 0) {
                        Setentylist(result);
                        setTotalCount(result[0].totalRecord);
                        setPage(result[0].index);
                        setPageSize(result[0].recordPerPage);
                        setActivePage(Page);
                        setisLoader(false);
                    }
                    else {
                        Setentylist(result);
                        setisLoader(false);
                    }
                })
            })

        })

    }



    const ApplyFilterRules = (e: any) => {
        setisLoader(true);


        var ifilter = [...filterobj1];
        ifilter.forEach(element => {
            if (element.code === "SKU") {
                element.value1 = fsku;
                element.value2 = tsku;
            }
        });
        setfilterobj1(ifilter);
        GetMPEntityDetailsByFIlter(filterobj1, Page1, PageSize1, true, function (result: products[]) {
            debugger;
            if (result != null && result.length > 0) {
                Setentitylist(result);
                setTotalCount1(result[0].totalRecord);
                setPage1(result[0].index);
                setPageSize1(result[0].recordPerPage);
                setActivePage1(Page1);
                settablescrollsection(true);
                setisLoader(false);
            }
            else {
                Setentitylist(result);
                setTotalCount1(0);
                setisLoader(false);
            }

        })

    }

    const ApplyRules = (e: any) => {
        setisLoader(true);


        var ifilter = [...filterobj];
        ifilter.forEach(element => {
            if (element.code === "SKU") {
                element.value1 = efsku;
                element.value2 = etsku;
            }
            else if (element.code === "Date") {
                element.value1 = searchDate;
                element.value2 = searchEndDate;
            }
        });
        setfilterobj(ifilter);
        GetInventoryLoadbyFilter(filterobj, Page, PageSize, function (result: any) {
            debugger;
            if (result != null && result.length > 0) {
                Setentylist(result);
                setTotalCount(result[0].totalRecord);
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage);
                setActivePage(Page);
                setisLoader(false);
            }
            else {
                Setentylist(result);
                setTotalCount(0);
                setisLoader(false);
            }
        });

    }

    const RefreshList = () => {
        setisLoader(true);
        //debugger;
        GetNFInventoryData(Page, PageSize, function (result: any) {
            if (result != null && result.length > 0) {
                setnfilist(result);
                setTotalCount(result[0].totalRecord);
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage);
                setActivePage(Page1);
                settablescrollsection(true);
                setisLoader(false);
            }
            else {
                setnfilist(result);
                setisLoader(false);
            }

        });
    }

    const changeChannel = (e: any) => {
        setChannelId(e.target.value);
    }

    const changeCategoryId = (e: any) => {
        setcategoryID(e.target.value);
        console.log(categoryId);
        setisLoader(true);
        let catid = Number(e.target.value);

        if (catid == 0 && entityname == "") {
            GetMPEntityDetailsById(catid, "", 3, "", prdgrp, pricemax, pricemin, mfg, Page1, PageSize1, function (result: products[]) {
                if (result.length > 0 && result != null) {
                    Setentitylist(result);
                    setTotalCount1(result[0].totalRecord);
                    setPage1(result[0].index);
                    setPageSize1(result[0].recordPerPage);
                    setActivePage1(Page1);
                    settablescrollsection(true);
                    setisLoader(false);
                }
                else {
                    Setentitylist(result);
                    setTotalCount1(0);

                    setisLoader(false);
                }

            });
        }
        else if (entityname != "") {
            GetMPEntityDetailsById(catid, entityname, 1, supplierId, prdgrp, pricemax, pricemin, mfg, Page1, PageSize1, function (result: products[]) {
                if (result.length > 0 && result != null) {
                    Setentitylist(result);
                    setTotalCount1(result[0].totalRecord);
                    setPage1(result[0].index);
                    setPageSize1(result[0].recordPerPage);
                    setActivePage1(Page1);
                    settablescrollsection(true);
                    setisLoader(false);
                }
                else {
                    Setentitylist(result);
                    setTotalCount1(0);

                    setisLoader(false);
                }

            });
        }
        else {
            GetMPEntityDetailsById(catid, "", iscategoryorothers, supplierId, prdgrp, pricemax, pricemin, mfg, Page1, PageSize1, function (result: products[]) {
                if (result.length > 0 && result != null) {
                    Setentitylist(result);
                    setTotalCount1(result[0].totalRecord);
                    setPage1(result[0].index);
                    setPageSize1(result[0].recordPerPage);
                    setActivePage1(Page1);
                    settablescrollsection(true);
                    setisLoader(false);
                }
                else {
                    Setentitylist(result);
                    setTotalCount1(0);

                    setisLoader(false);
                }

            });
        }
    }


    const changeSupplierId = (e: any) => {
        setsupplierId(e.target.value);
        console.log(supplierId);
        setisLoader(true);
        let Catid = Number(categoryId);
        debugger;
        GetMPEntityDetailsById(Catid, "", 4, e.target.value, prdgrp, pricemax, pricemin, mfg, Page1, PageSize1, function (result: products[]) {
            if (result.length > 0 && result != null) {
                Setentitylist(result);
                setTotalCount1(result[0].totalRecord);
                setPage1(result[0].index);
                setPageSize1(result[0].recordPerPage);
                setActivePage1(Page1);
                settablescrollsection(true);
                setisLoader(false);
            }
            else {
                Setentitylist(result);
                setTotalCount1(0);
                setisLoader(false);
            }

        });

    }


    const changeProductGroupId = (e: any) => {
        setprdgrp(e.target.value);
        console.log(supplierId);
        setisLoader(true);
        let Catid = Number(categoryId);
        debugger;
        GetMPEntityDetailsById(Catid, "", 4, supplierId, e.target.value, pricemax, pricemin, mfg, Page1, PageSize1, function (result: products[]) {
            if (result.length > 0 && result != null) {
                Setentitylist(result);
                setTotalCount1(result[0].totalRecord);
                setPage1(result[0].index);
                setPageSize1(result[0].recordPerPage);
                setActivePage1(Page1);
                settablescrollsection(true);
                setisLoader(false);
            }
            else {
                Setentitylist(result);
                setTotalCount1(0);
                setisLoader(false);
            }

        });
    }
    const changePriceMax = (e: any) => {
        setpricemax(e.target.value);
        console.log(supplierId);
        setisLoader(true);
        let Catid = Number(categoryId);
        debugger;
        GetMPEntityDetailsById(Catid, "", 4, supplierId, prdgrp, e.target.value, pricemin, mfg, Page1, PageSize1, function (result: products[]) {
            if (result.length > 0 && result != null) {
                Setentitylist(result);
                setTotalCount1(result[0].totalRecord);
                setPage1(result[0].index);
                setPageSize1(result[0].recordPerPage);
                setActivePage1(Page1);
                settablescrollsection(true);
                setisLoader(false);
            }
            else {
                Setentitylist(result);
                setTotalCount1(0);
                setisLoader(false);
            }

        });
    }
    const changePriceMin = (e: any) => {
        setpricemin(e.target.value);
        console.log(supplierId);
        setisLoader(true);
        let Catid = Number(categoryId);
        debugger;
        GetMPEntityDetailsById(Catid, "", 4, supplierId, prdgrp, pricemax, e.target.value, mfg, Page1, PageSize1, function (result: products[]) {
            if (result.length > 0 && result != null) {
                Setentitylist(result);
                setTotalCount1(result[0].totalRecord);
                setPage1(result[0].index);
                setPageSize1(result[0].recordPerPage);
                setActivePage1(Page1);
                settablescrollsection(true);
                setisLoader(false);
            }
            else {
                Setentitylist(result);
                setTotalCount1(0);
                setisLoader(false);
            }

        });
    }
    const changeMfg = (e: any) => {
        setmfg(e.target.value);
        console.log(supplierId);
        setisLoader(true);
        let Catid = Number(categoryId);
        debugger;
        GetMPEntityDetailsById(Catid, "", 4, supplierId, prdgrp, pricemax, pricemin, e.target.value, Page1, PageSize1, function (result: products[]) {
            if (result.length > 0 && result != null) {
                Setentitylist(result);
                setTotalCount1(result[0].totalRecord);
                setPage1(result[0].index);
                setPageSize1(result[0].recordPerPage);
                setActivePage1(Page1);
                settablescrollsection(true);
                setisLoader(false);
            }
            else {
                Setentitylist(result);
                setTotalCount1(0);
                setisLoader(false);
            }

        });
    }




    const changeType = (e: any) => {
        setinvtypeValue(e.target.value);
        setmpacclist(intMPAcclist);
        if (e.target.value == 2) {
            setisLoader(true);
            GetAllMarketplaces(function (result: any) {
                setmpacclist(result);
                setisLoader(false);
                setIsaccOrLocation(false);

            });
            if (stateval == '') {
                setStateval("clicked");
            }

        }
        else if (e.target.value == 4) {
            GetmpLocationsANDagilityBranch(3, function (result: any) {
                setmpacclist(result);
                setisLoader(false);
                setIsaccOrLocation(true);
            });
        }
        else if (e.target.value == 1) {
            setStateval("");
            setenlist(etylist);
        }
        else {
            setStateval("");

        }
    }

    const handleChange = (e: any) => {
        if (e.target.id == 1) {
            setactivecatclass(true);
            setactiveprdclass(false);
            setactiveitemclass(false);
            setiscategoryorothers(1);
        }
        if (e.target.id == 2) {
            setactivecatclass(false);
            setactiveprdclass(true);
            setactiveitemclass(false);
            setiscategoryorothers(2);
        }
        if (e.target.id == 3) {
            setactivecatclass(false);
            setactiveprdclass(false);
            setactiveitemclass(true);
            setiscategoryorothers(3);
            ExistingInvProductLoad();
        }
    }

    const changeEntityId = (e: any) => {
        setentityid(e.target.value)
    }
    const changeEntityName = (e: any) => {
        setentityname(e.target.value)
    }

    const changeMimQty = (e: any) => {
        setminimumqty(e.target.value);

        if (e.target.value == "") {
            setisdisabled(false);
            setisfdisabled(false);
        }
        else {
            setisdisabled(false);
            setisfdisabled(true);
        }

    }

    const changeFixedQty = (e: any) => {
        setfixedinv(e.target.value);
        if (e.target.value == "") {
            setisdisabled(false);
            setisfdisabled(false);
        }
        else {
            setisdisabled(true);
            setisfdisabled(false);
        }
    }
    const changeinValue = (e: any) => {
        setinvalue(e.target.value)
    }


    const SearchValues = (e: any) => {
        console.log(entityid);
        console.log(entityname);
        console.log(iscategoryorothers);
        setisLoader(true);

        if (entityname == '') {

            GetMPEntityDetailsById(0, entityname, 3, "", prdgrp, pricemax, pricemin, mfg, Page1, PageSize1, function (result: products[]) {
                if (result.length > 0 && result != null) {
                    Setentitylist(result);
                    setTotalCount1(result[0].totalRecord);
                    setPage1(result[0].index);
                    setPageSize1(result[0].recordPerPage);
                    setActivePage1(Page1);
                    settablescrollsection(true);
                    setisLoader(false);
                }
                else {
                    Setentitylist(result);
                    setTotalCount1(0);

                    setisLoader(false);
                }

            })
        }
        else {
            if (iscategoryorothers != 1) {
                GetMPEntityDetailsById(0, entityname, iscategoryorothers, "", prdgrp, pricemax, pricemin, mfg, Page1, PageSize1, function (result: products[]) {
                    if (result.length > 0 && result != null) {
                        Setentitylist(result);
                        setTotalCount1(result[0].totalRecord);
                        setPage1(result[0].index);
                        setPageSize1(result[0].recordPerPage);
                        setActivePage1(Page1);
                        settablescrollsection(true);
                        setisLoader(false);
                    }
                    else {
                        Setentitylist(result);
                        setTotalCount1(0);

                        setisLoader(false);
                    }

                })
            } else {
                let catid = Number(categoryId);
                GetMPEntityDetailsById(catid, entityname, iscategoryorothers, supplierId, prdgrp, pricemax, pricemin, mfg, Page1, PageSize1, function (result: products[]) {
                    if (result.length > 0 && result != null) {
                        Setentitylist(result);
                        setTotalCount1(result[0].totalRecord);
                        setPage1(result[0].index);
                        setPageSize1(result[0].recordPerPage);
                        setActivePage1(Page1);
                        settablescrollsection(true);
                        setisLoader(false);
                    }
                    else {
                        Setentitylist(result);
                        setTotalCount1(0);
                        setisLoader(false);
                    }


                })
            }
        }
    }

    const SetPage1 = (page: number) => {
        setPage1(page);
        setActivePage1(page);
        //RefreshList();
        setisLoader(true);
        let catid = Number(categoryId);
        if (catid == 0 && entityname == "") {
            debugger;
            GetMPEntityDetailsByFIlter(filterobj1, page, PageSize1, true, function (result: products[]) {
                if (result != null && result.length > 0) {
                    Setentitylist(result);
                    setTotalCount1(result[0].totalRecord);
                    setPage1(result[0].index);
                    setPageSize1(result[0].recordPerPage);
                    setActivePage1(page);
                    settablescrollsection(true);
                    setisLoader(false);
                } else {
                    Setentitylist(result);
                    setisLoader(false);

                }

            })
        }
        else {
            GetMPEntityDetailsByFIlter(filterobj1, page, PageSize1, true, function (result: products[]) {
                if (result != null && result.length > 0) {

                    Setentitylist(result);
                    setTotalCount1(result[0].totalRecord);
                    setPage1(result[0].index);
                    setPageSize1(result[0].recordPerPage);
                    setActivePage1(page);
                    settablescrollsection(true);
                    setisLoader(false);
                } else {
                    Setentitylist(result);
                    setisLoader(false);

                }

            })
        }

    }
    const changeAllMP = (e: any) => {
        if (e.target.checked) {
            setIsallMP(true);
        } else {
            setIsallMP(false);

        }
    }
    const changeMarkdown = (e: any) => {
        if (e.target.checked) {
            setIsMarkdown(true);
        } else {
            setIsMarkdown(false);

        }
    }
    const handleMPAccClick = (id: number, e: any) => {
        const { value, checked } = e.target;
        let entityval = e.target.id;

        if (e.target.checked) {
            setenlist(enlist => [...enlist, entityval]);
        }
        else {
            setenlist(enlist.filter((e) => e !== entityval));
        }
        var ifilter1 = [...mpacclist];
        ifilter1.forEach(element => {
            let entityval = e.target.id;
            if (element.customerId == entityval) {
                element.isSuccess = e.target.checked;
            }


        });
        console.log(enlist);
        //setisaccchecked(false);

    }

    const handlempAllClick = (e: any) => {
        debugger;
        const { value, checked } = e.target;
        let tmpproduct = mpacclist.map((mp) => {

            return { ...mp, isSuccess: checked };
        });

        var ifilter = [...tmpproduct];
        ifilter.forEach(element => {
            let entityval = element.customerId;
            if (element.isSuccess) {
                setenlist(enlist => [...enlist, String(entityval)]);
            }
            else {
                setenlist(enlist.filter((e) => e !== String(entityval)));
            }

        });
        console.log(enlist);


        setmpacclist(tmpproduct);
        //confirmAlert({
        //    title: 'Update Product Status',
        //    message: 'Pls confirm to Update Status ?',
        //    buttons: [
        //        {
        //            label: 'Yes',
        //            onClick: () => Inactiveallproduct(checked)
        //        },
        //        {
        //            label: 'No',
        //            onClick: () => { }
        //        }
        //    ]
        //});

    }

    const handleAllClick = (e: any) => {
        const { value, checked } = e.target;
        let tmpacc = entitylist.map((mp) => {
            return { ...mp, isselected: checked };

        });
        Setentitylist(tmpacc);

        if (e.target.checked) {
            var ival = [...entitylist];
            ival.forEach(element => {
                let mplistval: entities = {
                    entityid: element.sku.toString(),
                    uom: element.uom,
                    isactive: true
                }
                setmpList(mpList => [...mpList, mplistval]);
            });
            //   setisAllChecked(true);
        }
        else {
            var ival1 = [...mpList];
            ival1.forEach(element => {
                //if (element.entityid === id && element.uom == uom) {
                element.isactive = false;
                // }
            });
            setmpList(ival1);
            // setisAllChecked(false);
        }

    }

    const handleClick = (sku: string, uom: string, e: any) => {
        const { value, checked } = e.target;
        let entityval = e.target.id;
        let tmpacc = entitylist.map((mp) => mp.sku === sku && mp.uom === uom ? { ...mp, isselected: checked } : mp);
        Setentitylist(tmpacc);
        if (e.target.checked) {
            let mplistval: entities = {
                entityid: sku,
                uom: uom,
                isactive: true
            }
            setmpList(mpList => [...mpList, mplistval]);

        }
        else {
            var ival = [...mpList];
            ival.forEach(element => {
                if (element.entityid === sku && element.uom == uom) {
                    element.isactive = false;
                }
            });
            setmpList(ival);
        }
    }

    const onChangeValue = (e: any) => {
        setispervalue(e.target.checked);
        if (e.target.checked) {
            setisvalue("per");
        } else {
            setisvalue("");

        }
        setisfixvalue(false);
    }
    const onChangefixValue = (e: any) => {
        setisfixvalue(e.target.checked);
        setispervalue(false);
        if (e.target.checked) {
            setisvalue("fix");
        }
        else {
            setisvalue("");

        }
    }

    const SaveRule = (e: any) => {
        debugger;
        setisLoader(true);
        var date = moment(new Date()).format('MM/DD/YYYY');
        var sldate = moment(new Date(startDate)).format('MM/DD/YYYY');
        let isstartdate = false;
        let isenddate = false;
        if (sldate == date) {
            isstartdate = true;
        }
        else if (sldate < date) {
            let result = CreateinitialLoadermessage()
            result.isfailed = true;
            result.message = "Expire StartDate -  don't select Past date"
            Setisloader(result);
        }
        else {
            isstartdate = true;
        }

        if (minimumqty != "" && invalue.toString() != "") {


            SaveNFinventoryById(Number(minimumqty), invalue.toString(), function (result: any) {

                let result1 = CreateinitialLoadermessage();
                result1.isSuccess = true;
                result1.message = "Saved Successfully"
                Setisloader(result1);
                setisLoader(false);
                //RefreshList();
                history.go(0);
                //}
            });

        }
        else {
            if (minimumqty == "" || minimumqty == "0") {
                let result = CreateinitialLoadermessage()
                result.isfailed = true;
                result.message = "Please Enter the Reserved Quantity "
                Setisloader(result);
                setisLoader(false);
            }
            //else if (utcStartDate == "") {
            //    let result = CreateinitialLoadermessage()
            //    result.isfailed = true;
            //    result.message = "Please Select the Start Date"
            //    Setisloader(result);
            //}
            else if (invalue != "") {
                let result = CreateinitialLoadermessage()
                result.isfailed = true;
                result.message = "Please Enter the Value"
                Setisloader(result);
            }
        }
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    }

    const Pagenation = () => {
        let Pagination = new PaginationValues();
        Pagination.TotalItemCount = TotalCount;
        Pagination.CurrentPage = Page;
        Pagination.PageSlide = 2;
        Pagination.IsSort = true;
        Pagination.PageSize = PageSize;

        return (
            <div className="pagination-section">
                <div className="row">
                    <div className="col-sm-4 col-md-4 col-lg-4">
                        <div className="item-page-results">
                            Items Per Page:50
                            {/* {
                                PageSizeList.map((x, i) => (
                                    x == PageSize ? (
                                        <span key={i}> {x} |</span>
                                    ) : (
                                        <span key={i}>   <a onClick={() => SetPageSize(x)} > {x}</a> | </span>
                                    )
                                ))
                            } */}
                        </div>
                    </div>
                    {
                        <div className="col-sm-4 col-md-4 col-lg-4">
                            <div className="sorting-results">
                                Showing {((Page - 1) * PageSize) + 1}    - {((((Page - 1) * PageSize) + 1) + PageSize) > TotalCount ? TotalCount : ((((Page - 1) * PageSize)) + PageSize)} of {TotalCount} results
                            </div>
                        </div>
                    }
                    <div className="col-sm-4 col-md-4 col-lg-4">
                        <nav aria-label="Page navigation example">
                            <ul className="pagination justify-content-end mb-2">
                                {
                                    Pagination.HasPrevPage() ?
                                        (<li className="page-item">
                                            <a className="page-link" onClick={() => SetPage(Page - 1)}>
                                                &#8592;</a>
                                        </li>
                                        )
                                        : ("")
                                }
                                {
                                    Pagination.Pages().map((x, i) =>
                                        x == Page ?
                                            (
                                                <li className={`page-item  ${activePage == x ? 'active' : null}`} key={i}><a className="page-link" >{x}</a></li>)
                                            : (
                                                <li className="page-item" key={i}>
                                                    <a className="page-link" onClick={() => SetPage(x)}>{x}
                                                    </a>
                                                </li>
                                            )
                                    )
                                }
                                {
                                    Pagination.HasNextPage() ?
                                        (<li className="page-item">
                                            <a className="page-link" onClick={() => SetPage(Page + 1)}>
                                                &#8594;</a>
                                        </li>) : ("")
                                }
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        )
    }

    const Pagenation1 = () => {
        let Pagination1 = new PaginationValues();
        Pagination1.TotalItemCount = TotalCount1;
        Pagination1.CurrentPage = Page1;
        Pagination1.PageSlide = 2;
        Pagination1.IsSort = true;
        Pagination1.PageSize = PageSize1;

        return (
            <div className="pagination-section">
                <div className="row">
                    <div className="col-sm-4 col-md-4 col-lg-4">
                        <div className="item-page-results">
                            Items Per Page:50
                            {/* {
                                PageSizeList.map((x, i) => (
                                    x == PageSize ? (
                                        <span key={i}> {x} |</span>
                                    ) : (
                                        <span key={i}>   <a onClick={() => SetPageSize(x)} > {x}</a> | </span>
                                    )
                                ))
                            } */}
                        </div>
                    </div>
                    {
                        <div className="col-sm-4 col-md-4 col-lg-4">
                            <div className="sorting-results">
                                Showing {((Page1 - 1) * PageSize1) + 1}    - {((((Page1 - 1) * PageSize1) + 1) + PageSize1) > TotalCount1 ? TotalCount1 : ((((Page1 - 1) * PageSize1)) + PageSize1)} of {TotalCount1} results
                            </div>
                        </div>
                    }
                    <div className="col-sm-4 col-md-4 col-lg-4">
                        <nav aria-label="Page navigation example">
                            <ul className="pagination justify-content-end mb-2">
                                {
                                    Pagination1.HasPrevPage() ?
                                        (<li className="page-item">
                                            <a className="page-link" onClick={() => SetPage1(Page1 - 1)}>
                                                &#8592;</a>
                                        </li>
                                        )
                                        : ("")
                                }
                                {
                                    Pagination1.Pages().map((x, i) =>
                                        x == Page1 ?
                                            (
                                                <li className={`page-item  ${activePage1 == x ? 'active' : null}`} key={i}><a className="page-link" >{x}</a></li>)
                                            : (
                                                <li className="page-item" key={i}>
                                                    <a className="page-link" onClick={() => SetPage1(x)}>{x}
                                                    </a>
                                                </li>
                                            )
                                    )
                                }
                                {
                                    Pagination1.HasNextPage() ?
                                        (<li className="page-item">
                                            <a className="page-link" onClick={() => SetPage1(Page1 + 1)}>
                                                &#8594;</a>
                                        </li>) : ("")
                                }
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        )
    }

    const SetPageSize = (size: number) => {
        setPageSize(size);
        //RefreshList();
        setisLoader(true);
        GetNFInventoryData(Page, size, function (result: nfinventory[]) {
            if (result != null && result.length > 0) {
                setnfilist(result);
                setTotalCount(result[0].totalRecord);
                SetPage(result[0].index);
                SetPageSize(result[0].recordPerPage);
                setActivePage(Page);
                settablescrollsection(true);
                setisLoader(false);
            }
            else {
                setnfilist(result);
                setisLoader(false);
            }


        })
    }

    const SetPage = (page: number) => {
        setPage(page);
        setActivePage(page);
        setisLoader(true);
        GetNFInventoryData(page, PageSize, function (result: nfinventory[]) {
            if (result != null && result.length > 0) {
                setnfilist(result);
                setTotalCount(result[0].totalRecord);
                SetPage(result[0].index);
                SetPageSize(result[0].recordPerPage);
                setActivePage(Page);
                settablescrollsection(true);
                setisLoader(false);
            }
            else {
                setnfilist(result);
                setisLoader(false);
            }


        })
    }

    const DeleteAlert = (id: number) => {
        confirmAlert({
            title: 'Deactivate Rule',
            message: 'Are you sure you want to deactivate the Rule?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => Delete(id)
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    }
    const Delete = (id: number) => {
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        DeleteInventoryData(id, function (result: any) {
            if (result.isSuccess) {
                let result = CreateinitialLoadermessage();
                result.isSuccess = true;
                result.message = "Rule deactivated successfully"
                Setisloader(result);
                RefreshList();
                //setisLoader(true)
                //GetInventoryLoadbyFilter(filterobj, Page, PageSize, function (result: any) {
                //    if (result != null) {
                //        Setentylist(result)
                //        setTotalCount(result[0].totalRecord)
                //        setPage(result[0].index);
                //        setPageSize(result[0].recordPerPage);
                //        setisLoader(false);
                //    }
                //})
                //let load: Loadermessage = {
                //    message: "",
                //    isLoader: true,
                //    isSuccess: false,
                //    isfailed: false
                //}
                //Setisloader(load);

                //GetInventoryLoadbyFilter(filterobj, Page, PageSize, function (result: any) {
                //    debugger;
                //    if (result != null && result.length > 0) {
                //        Setentylist(result);
                //        setTotalCount(result[0].totalRecord);
                //        setPage(result[0].index);
                //        setPageSize(result[0].recordPerPage);
                //        setActivePage(Page);
                //        setisLoader(false);
                //    }
                //    else {
                //        Setentylist(result);
                //        setTotalCount(0);
                //        setisLoader(false);
                //    }
                //    let load: Loadermessage = {
                //        message: "",
                //        isLoader: false,
                //        isSuccess: false,
                //        isfailed: false
                //    }
                //    Setisloader(load);
                //});
            }
        })
    }
    const backUserDB = () => {
        history.push('/userdashboard');
    }
    var intAcclist: channellist[] = new Array<channellist>();
    const [acclist, setacclist] = useState(intAcclist);

    var intlocationlist: channellist[] = new Array<channellist>();
    const [loclist, setloclist] = useState(intlocationlist);
    const [sku, setsku] = useState("");
    const [locationid, setlocationid] = useState("");
    const [accno, setaccno] = useState("");
    const [iruletype, setiruletype] = useState("");
    const [ivalidity, setivalidity] = useState("");
    const [supplierkey, setsupplierkey] = useState("");




    const changeSearchSku = (e: any) => {
        setsku(e.target.value);
        console.log(sku);

    }
    useEffect(() => {
        // setisLoader(true);
        GetmpLocationsANDagilityBranch(1, function (result: any) {
            setloclist(result);
            //setisLoader(false);

        })
    }, [])

    const changeFilters = (id: any, name: string, e: any) => {
        setisLoader(true);


        var ifilter = [...filterobj1];
        ifilter.forEach(element => {
            if (element.id === id) {
                if (name == "SKU") {
                    element.value1 = fsku

                } else {
                    element.value1 = e.target.value;
                }
            }
        });

        const { value, checked } = e.target;
        let entityval = e.target.id;

        let mplistval: filterentities = {
            id: id
        }
        setmpfilters1(mpfilters => [...mpfilters, mplistval]);
        setfilterobj1(ifilter);


        setisLoader(false);
    }

    const changeEFilters = (id: any, name: string, e: any) => {
        setisLoader(true);


        var ifilter = [...filterobj];
        ifilter.forEach(element => {
            if (element.id === id) {
                if (name == "SKU") {
                    element.value1 = efsku

                } else {
                    element.value1 = e.target.value;
                }
            }
        });

        const { value, checked } = e.target;
        let entityval = e.target.id;

        let mplistval: filterentities = {
            id: id
        }
        setmpfilters(mpfilters => [...mpfilters, mplistval]);
        setfilterobj(ifilter);


        setisLoader(false);
    }

    useEffect(() => {
        //setisLoader(true);
        GetAllMarketplaces(function (result: any) {
            setacclist(result);
            // setisLoader(false);

        })
    }, [])
    const SearchInventory = (e: any) => {
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        GetInventoryentitylist(sku, locationid, accno, iruletype, searchDate, searchEndDate, ivalidity, supplierkey, prdgrpname, pricemaxname, priceminname, mfgname, Page, PageSize, function (result: any) {
            Setentylist(result);
            if (result.length > 0) {
                setTotalCount(result[0].totalRecord);
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage);
                setActivePage(Page);
            }
            else {
                setTotalCount(result.length);
            }
            setisLoader(false);
        })
    }
    const changelocation = (e: any) => {
        let locid = e.target.value;
        setlocationid(locid);
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        GetInventoryentitylist(sku, e.target.value, accno, iruletype, searchDate, searchEndDate, ivalidity, supplierkey, prdgrpname, pricemaxname, priceminname, mfgname, Page, PageSize, function (result: any) {
            Setentylist(result);
            if (result.length > 0) {
                setTotalCount(result[0].totalRecord);
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage);
                setActivePage(Page);
            }
            else {
                setTotalCount(result.length);
            }
            setisLoader(false);
        });
    }
    const changesupplier = (e: any) => {
        let supkey = e.target.value;
        setsupplierkey(supkey);
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        setisLoader(true);
        GetInventoryentitylist(sku, locationid, accno, iruletype, searchDate, searchEndDate, ivalidity, e.target.value, prdgrpname, pricemaxname, priceminname, mfgname, Page, PageSize, function (result: any) {
            Setentylist(result);
            if (result.length > 0) {
                setTotalCount(result[0].totalRecord);
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage);
                setActivePage(Page);
            }
            else {
                setTotalCount(result.length);
            }
            setisLoader(false);
        });
    }

    const changeprdgrp = (e: any) => {
        let supkey = e.target.value;
        debugger;
        setprdgrpname(supkey);
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        setisLoader(true);
        GetInventoryentitylist(sku, locationid, accno, iruletype, searchDate, searchEndDate, ivalidity, supplierkey, e.target.value, pricemaxname, priceminname, mfgname, Page, PageSize, function (result: any) {
            Setentylist(result);
            if (result.length > 0) {
                setTotalCount(result[0].totalRecord);
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage);
                setActivePage(Page);
            }
            else {
                setTotalCount(result.length);
            }
            setisLoader(false);
        });
    }
    const changeprimax = (e: any) => {
        let supkey = e.target.value;
        setpricemaxname(supkey);
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        setisLoader(true);
        GetInventoryentitylist(sku, locationid, accno, iruletype, searchDate, searchEndDate, ivalidity, supplierkey, prdgrpname, e.target.value, priceminname, mfgname, Page, PageSize, function (result: any) {
            Setentylist(result);
            if (result.length > 0) {
                setTotalCount(result[0].totalRecord);
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage);
                setActivePage(Page);
            }
            else {
                setTotalCount(result.length);
            }
            setisLoader(false);
        });
    }
    const changeprimin = (e: any) => {
        let supkey = e.target.value;
        setpriceminname(supkey);
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        setisLoader(true);
        GetInventoryentitylist(sku, locationid, accno, iruletype, searchDate, searchEndDate, ivalidity, supplierkey, prdgrpname, priceminname, e.target.value, mfgname, Page, PageSize, function (result: any) {
            Setentylist(result);
            if (result.length > 0) {
                setTotalCount(result[0].totalRecord);
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage);
                setActivePage(Page);
            }
            else {
                setTotalCount(result.length);
            }
            setisLoader(false);
        });
    }
    const changemfgname = (e: any) => {
        let supkey = e.target.value;
        setmfgname(supkey);
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        setisLoader(true);
        GetInventoryentitylist(sku, locationid, accno, iruletype, searchDate, searchEndDate, ivalidity, supplierkey, prdgrpname, priceminname, priceminname, e.target.value, Page, PageSize, function (result: any) {
            Setentylist(result);
            if (result.length > 0) {
                setTotalCount(result[0].totalRecord);
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage);
                setActivePage(Page);
            }
            else {
                setTotalCount(result.length);
            }
            setisLoader(false);
        });
    }


    const changeacc = (e: any) => {
        let id = e.target.value;
        setaccno(id);
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        GetInventoryentitylist(sku, locationid, e.target.value, iruletype, searchDate, searchEndDate, ivalidity, supplierkey, prdgrpname, pricemaxname, priceminname, mfgname, Page, PageSize, function (result: any) {
            Setentylist(result);
            if (result.length > 0) {
                setTotalCount(result[0].totalRecord);
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage);
                setActivePage(Page);
            }
            else {
                setTotalCount(result.length);
            }
            setisLoader(false);
        });
    }
    const changerule = (e: any) => {
        let id = e.target.value;
        setiruletype(id);
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        GetInventoryentitylist(sku, locationid, accno, e.target.value, searchDate, searchEndDate, ivalidity, supplierkey, prdgrpname, pricemaxname, priceminname, mfgname, Page, PageSize, function (result: any) {
            Setentylist(result);
            if (result.length > 0) {
                setTotalCount(result[0].totalRecord);
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage);
                setActivePage(Page);
            }
            else {
                setTotalCount(result.length);
            }
            setisLoader(false);
        });
    }
    const changevalidity = (e: any) => {
        let id = e.target.value;
        setivalidity(id);
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        GetInventoryentitylist(sku, locationid, accno, iruletype, searchDate, searchEndDate, e.target.value, supplierkey, prdgrpname, pricemaxname, priceminname, mfgname, Page, PageSize, function (result: any) {
            Setentylist(result);
            if (result.length > 0) {
                setTotalCount(result[0].totalRecord);
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage);
                setActivePage(Page);
            }
            else {
                setTotalCount(result.length);
            }
            setisLoader(false);
        });
    }
    const onChangeStartDate = (e: any) => {
        const newDate = e.target.value;
        var date = moment(new Date()).format('MM/DD/YYYY');
        var sldate = moment(new Date(newDate)).format('MM/DD/YYYY');
        let isdatevalid = true;
        if (sldate == date) {
            setstartDate(newDate);
            setutcStartDate(moment.utc(moment(newDate).utc()).format());
            console.log('current date', newDate);
            console.log('UTC Date', moment.utc().format());
            //console.log(moment.utc(newDate).format());
            console.log('local date convert UTC Date', moment.utc(moment(newDate).utc()).format())
            //console.log(moment.utc(moment(date).utc()).toISOString());
            //console.log(newDate.toUTCString());

            Setisloader(CreateinitialLoadermessage());
        }
        else if (sldate < date) {
            let result = CreateinitialLoadermessage()
            result.isfailed = true;
            result.message = "Start Date - Please don't select Past date"
            Setisloader(result);
            isdatevalid = false;
        } else {
            setstartDate(newDate);
            setutcStartDate(moment.utc(moment(newDate).utc()).format());

            Setisloader(CreateinitialLoadermessage());

        }
    };
    const onChangeSearchDate = (e: any) => {
        debugger;
        const newDate = e.target.value;
        setsearchDate(newDate);
        //let load: Loadermessage = {
        //    message: "",
        //    isLoader: true,
        //    isSuccess: false,
        //    isfailed: false
        //}
        //Setisloader(load);
        //GetInventoryentitylist(sku, locationid, accno, iruletype, e.target.value, searchEndDate, ivalidity, supplierkey, prdgrpname, pricemaxname, priceminname, mfgname, Page, PageSize, function (result: any) {
        //    Setentylist(result);
        //    if (result.length > 0) {
        //        setTotalCount(result[0].totalRecord);
        //        setPage(result[0].index);
        //        setPageSize(result[0].recordPerPage);
        //        setActivePage(Page);
        //    }
        //    else {
        //        setTotalCount(result.length);
        //    }
        //    setisLoader(false);
        //});
    };

    const onChangeSearchEndDate = (e: any) => {
        const newDate = e.target.value;
        setsearchEndDate(newDate);
        //let load: Loadermessage = {
        //    message: "",
        //    isLoader: true,
        //    isSuccess: false,
        //    isfailed: false
        //}
        //Setisloader(load);
        //GetInventoryentitylist(sku, locationid, accno, iruletype, searchDate, e.target.value, ivalidity, supplierkey, prdgrpname, pricemaxname, priceminname, mfgname, Page, PageSize, function (result: any) {
        //    Setentylist(result);
        //    if (result.length > 0) {
        //        setTotalCount(result[0].totalRecord);
        //        setPage(result[0].index);
        //        setPageSize(result[0].recordPerPage);
        //        setActivePage(Page);
        //    }
        //    else {
        //        setTotalCount(result.length);
        //    }
        //    setisLoader(false);
        //});
    };
    const onChangeEndDate = (e: any) => {
        const newDate = e.target.value;
        var date = moment(new Date()).format('MM/DD/YYYY');
        var sldate = moment(new Date(newDate)).format('MM/DD/YYYY');
        let isdatevalid = true;
        if (sldate == date) {
            setendDate(newDate);
            setutcEndDate(moment.utc(moment(newDate).utc()).format())
            Setisloader(CreateinitialLoadermessage());
        }
        if (sldate < date) {
            let result = CreateinitialLoadermessage()
            result.isfailed = true;
            result.message = "End Date - Please don't select Past date"
            Setisloader(result);
            isdatevalid = false;
        } else {
            Setisloader(CreateinitialLoadermessage());
            setendDate(newDate);
            setutcEndDate(moment.utc(moment(newDate).utc()).format())


        }
    };
    const ClosePopup = () => {
        Setisloader(CreateinitialLoadermessage())
    }
    const resetPage = () => {
        history.go(0);
    }
    const resetEPage = () => {
        //history.go(0);
        debugger;
        setactivecatclass(false);
        setactiveprdclass(false);
        setactiveitemclass(true);
        setiscategoryorothers(3);
        setefsku("");
        setetsku("");
        setfilterlist(intfilterlist);
        ExistingInvProductLoad();
    }
    const changeCgroup = (e: any) => {
        let cgidv = e.target.value;
        setcgid(cgidv);
        debugger;
        GetAllMarketplacebyAccountId(cgidv, function (result: any) {
            setmpacclist(result);
        });
    }
    const DownloadInventoryRule = () => {
        if (filterobj1 != null) {
            try {
                let load: Loadermessage = {
                    message: "",
                    isLoader: true,
                    isSuccess: false,
                    isfailed: false
                }
                Setisloader(load);
                debugger;
                var data = new FormData();
                data.append("filters", JSON.stringify(filterobj1));
                const response = axios({
                    method: "post",
                    url: "api/MP/DownloadInventoryRule",
                    data: data,
                    headers: { "Content-Type": "multipart/form-data" },
                    responseType: 'blob',
                }).then((result) => {

                    let resultmes = CreateinitialLoadermessage();
                    if (result != null) {
                        debugger;
                        const url = window.URL.createObjectURL(new Blob([result.data]));
                        const a = document.createElement('a');
                        a.href = url;
                        a.setAttribute('download', "InventoryRuleProducts.csv");
                        document.body.appendChild(a);
                        a.click();
                        a.remove();
                        URL.revokeObjectURL(url);

                    }
                    resultmes.isLoader = false;
                    Setisloader(resultmes);
                });
                //Setisloader(load);
            } catch (error) {
                console.log(error)
            }
        }
        else {
            let load: Loadermessage = {
                message: "Select Atleast one filter and download",
                isLoader: false,
                isSuccess: false,
                isfailed: true
            }
            Setisloader(load);
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

        }
    }
    const DownloadEInventoryRule = () => {
        if (filterobj != null) {
            try {
                let load: Loadermessage = {
                    message: "",
                    isLoader: true,
                    isSuccess: false,
                    isfailed: false
                }
                Setisloader(load);
                debugger;
                var data = new FormData();
                data.append("filters", JSON.stringify(filterobj));
                const response = axios({
                    method: "post",
                    url: "api/MP/DownloadEInventoryRule",
                    data: data,
                    headers: { "Content-Type": "multipart/form-data" },
                    responseType: 'blob',
                }).then((result) => {

                    let resultmes = CreateinitialLoadermessage();
                    if (result != null) {
                        debugger;
                        const url = window.URL.createObjectURL(new Blob([result.data]));
                        const a = document.createElement('a');
                        a.href = url;
                        a.setAttribute('download', "ExInventoryRuleProducts.csv");
                        document.body.appendChild(a);
                        a.click();
                        a.remove();
                        URL.revokeObjectURL(url);

                    }
                    resultmes.isLoader = false;
                    Setisloader(resultmes);
                });
                //Setisloader(load);
            } catch (error) {
                console.log(error)
            }
        }
        else {
            let load: Loadermessage = {
                message: "Select Atleast one filter and download",
                isLoader: false,
                isSuccess: false,
                isfailed: true
            }
            Setisloader(load);
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

        }
    }
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null
    );
    const [currentEntity, setCurrentEntity] = useState(createinitialProducts);
    const [currentEntityExisting, setcurrentEntityExisting] = useState(createinitialjobDetails);
    const [isPopover1Open, setIsPopover1Open] = useState(false);
    const [isPopover2Open, setIsPopover2Open] = useState(false);

    const handleInfoClick = (event: any, data: any) => {
        setAnchorEl(event.currentTarget);
        setCurrentEntity(data);
        setIsPopover1Open(true);
        setIsPopover2Open(false);
        console.log("event....", data)

    }
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleInfoClickExisting = (event: any, data: any) => {
        setAnchorEl(event.currentTarget);
        setcurrentEntityExisting(data);
        setIsPopover2Open(true);
        setIsPopover1Open(false);
        console.log("handleInfoClickExisting....", data)

    }
    const handleSelect = (selectedList: any, selectedItem: any, filterId: any) => {
        console.log("selectedList....", selectedList)

        var ifilter = [...filterobj1];

        ifilter.forEach(element => {
            if (element.id === filterId) {

                element.value1 = selectedList.join(',');
            }
        });
        setfilterobj1(ifilter);
        console.log("selectedItem....", ifilter);
    }
    const handleRemove = (selectedList: any, selectedItem: any, filterId: any) => {

        var ifilter = [...filterobj1];
        ifilter.forEach(element => {
            if (element.id === filterId) {
                element.value1 = "";
                element.value1 = selectedList.join(',');
            }
        });
        setfilterobj1(ifilter);
        console.log("DeselectedItem....", ifilter);
    }

    const handleSelectExisting = (selectedList: any, selectedItem: any, filterId: any) => {
        console.log("selectedListExist....", selectedList)

        var ifilter = [...filterobj];

        ifilter.forEach(element => {
            if (element.id === filterId) {

                element.value1 = selectedList.join(',');
            }
        });
        setfilterobj1(ifilter);
        console.log("selectedItemExist....", ifilter);
    }
    const handleRemoveExisting = (selectedList: any, selectedItem: any, filterId: any) => {

        var ifilter = [...filterobj];
        ifilter.forEach(element => {
            if (element.id === filterId) {
                element.value1 = "";
                element.value1 = selectedList.join(',');
            }
        });
        setfilterobj1(ifilter);
        console.log("DeselectedItemExist....", ifilter);
    }

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    return (
        <React.Fragment>
            {isLoader ? (<Loader isActive={isLoader} />) : (null)}
            <div className="row">

                <div className="col-md-12">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/userdashboard"><i className="bi bi-house-door-fill pe-1"></i>Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page">
                                Inventory Rules(NF)
                            </li>
                        </ol>
                    </nav>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="heading-section">
                                <h1>Non-Fulfilment Inventory Rules</h1>
                                <div>
                                    <button type="button" className="btn btn-wh-1 btn-primary ms-2" onClick={backUserDB}>Back</button>
                                </div>
                            </div>

                            <div>
                                <div className="tab-content">
                                    <h2 className="pb-2 primarycolor">Inventory</h2>
                                    <div className="section-border-new">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-6">
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="row g-2 align-items-center mb-2">
                                                            <div className="col-4">
                                                                <label className="form-label">Reserved Quantity</label>
                                                            </div>
                                                            <div className="col-8">
                                                                <input type="text" id="" className="form-control" aria-describedby="" disabled={isdisabled} value={minimumqty} onChange={changeMimQty} />
                                                            </div>
                                                        </div>

                                                        <div className="row g-2 align-items-center mb-3">
                                                            <div className="col-4">
                                                                <label className="form-label">Level Up Percentage</label>
                                                            </div>
                                                            <div className="col-8">
                                                                <input type="number" id="" className="form-control" aria-describedby="" disabled={isdisabled} value={invalue} onChange={changeinValue} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*<div className="row">*/}
                                        {/*    <div className="col">*/}
                                        {/*        <div className="row mb-3 g-2 align-items-center">*/}
                                        {/*            <div className="col-4">*/}
                                        {/*                <label className="col-form-label">Effective Startdate</label>*/}
                                        {/*            </div>*/}
                                        {/*            <div className="col-8">*/}
                                        {/*                <input type="datetime-local" className="form-control" id="datetime" aria-describedby="datetimehelp" onChange={onChangeStartDate} />*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*    <div className="col">*/}
                                        {/*        <div className="row mb-3 g-2 align-items-center">*/}
                                        {/*            <div className="col-4">*/}
                                        {/*                <label className="col-form-label">Effective Enddate</label>*/}
                                        {/*            </div>*/}
                                        {/*            <div className="col-8">*/}
                                        {/*                <input type="datetime-local" className="form-control" id="datetime" aria-describedby="datetimehelp" onChange={onChangeEndDate} />*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        <div className="row">
                                            <div className="col-12 text-center">
                                                <button type="button" disabled={AINVRules != true ? true : false} className="btn btn-wh-2 btn-primary mb-2" onClick={SaveRule}>Create Rule</button>
                                            </div>
                                        </div>
                                    </div>
                                    {/*<Pagenation />*/}
                                    <div className="table-scroll-section-2">
                                        <table className="table table-bordered">
                                            <thead className="table-light sticky-top">
                                                <tr>

                                                    <th scope="col">
                                                        Reserved Qty
                                                    </th>
                                                    <th scope="col">
                                                        Level Up Percentage
                                                    </th>
                                                    {/*<th scope="col">*/}
                                                    {/*    StartDate*/}
                                                    {/*</th>*/}
                                                    {/*<th scope="col">*/}
                                                    {/*    EndDate*/}
                                                    {/*  </th>*/}
                                                    <th scope="col">
                                                        Created Date
                                                    </th>
                                                    <th scope="col">
                                                        Action
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    nfilist != null ? (
                                                        nfilist.map((item, index) => (
                                                            <tr key={index}>
                                                                <td>
                                                                    {item.reservedQuantity}
                                                                </td>

                                                                <td>
                                                                    {item.value}
                                                                </td>
                                                                {/*<td>*/}
                                                                {/*    {item.startDate}*/}
                                                                {/*</td>*/}
                                                                {/*<td>*/}
                                                                {/*    {item.endDate}*/}
                                                                {/*</td>*/}
                                                                <td>
                                                                    {item.createdOn}
                                                                </td>
                                                                <td>
                                                                    <button className="btn btn-sm btn-danger" disabled={DINVRules != true ? true : false} data-bs-toggle="tooltip" data-bs-placement="top" title="DeActivate Rule" onClick={(e) => DeleteAlert(item.id)} ><i className="bi-archive"></i></button>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    ) : (null)
                                                }
                                            </tbody>
                                        </table>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div onClick={ClosePopup}>
                        {
                            isloader.isfailed ? (
                                <AFailed message={isloader.message} />
                            ) : (null)
                        }
                    </div>
                    <div onClick={ClosePopup}>
                        {
                            isloader.isSuccess ? (
                                <ASuccess message={isloader.message} />
                            ) : (null)
                        }
                    </div>
                </div>
            </div>
        </React.Fragment >
    )
}
export default nfinventoryRules;


