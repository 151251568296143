import axios from "axios";
import moment from "moment";
import { Data } from "popper.js";
import { jobDetails } from "../../model/jobDetails";
import { scheduledSync } from "../../model/scheduledSync";
import { moduleinfo } from "../../model/user";
import { inventoryproducts } from "../../model/inventoryproduct";
import { stat } from "fs";

export const GetProductsLoad = async (accountid: string, sku: string, location: number, branch: number, activestatus: string, SupplierKey: string, prodgrp: string, pricemax1: string, pricemin1: string, manufacture: string, page: number, size: number, callback: any) => {
    await axios.post("api/MP/GetProductsLoad", {
        sku: sku,
        location: location.toString(),
        branch: branch.toString(),
        accountid: accountid.toString(),
        activestatus: activestatus,
        productgrp: prodgrp,
        pricemax: pricemax1,
        pricemin: pricemin1,
        mfg: manufacture,
        supplierid: SupplierKey,
        index: page,
        recordPerPage: size
    }).then(res => {
        callback(res.data)
    })
        .catch(err => console.log(err))
}
export const GetCategoryMPProducts = async (callback: any) => {
    await axios.get("api/MP/GetCategoryMPProducts").then(result => {
        callback(result.data)
    }).catch(err => {
        console.log(err)
    })
}
export const DeleteEntity = async (id: number, callback: any) => {
    await axios.post("api/MP/DeleteEntity?id=" + id)
        .then(res => {
            callback(res.data)
        })
        .catch(err => console.log(err))
}
export const DeactivateRules = async (productids: any, callback: any) => {
    await axios.post("api/MP/DeactivateRule", {
        // ids: productids
        Entitylist: productids,
    })
        .then(res => {
            callback(res.data)
        })
        .catch(err => console.log(err))
}
export const DeleteInventoryData = async (id: number, callback: any) => {
    await axios.post("api/MP/DeleteInventoryData?id=" + id)
        .then(res => {
            callback(res.data)
        })
        .catch(err => console.log(err))
}
export const DeletePRule = async (id: number, callback: any) => {
    await axios.post("api/MP/DeletePRule?id=" + id)
        .then(res => {
            callback(res.data)
        })
        .catch(err => console.log(err))
}
export const DeletePRuleNF = async (id: number, callback: any) => {
    await axios.post("api/MP/DeletePRuleNF?id=" + id)
        .then(res => {
            callback(res.data)
        })
        .catch(err => console.log(err))
}

export const GetInventoryentitylist = async (entityName: string, locationname: string, accountname: string, rulevalue: string, startdate: string, enddate: string, validity: string, supplierkeyval: string, prodgrp: string, pricemax1: string, pricemin1: string, manufacture: string, page: number, size: number, callback: any) => {
    await axios.post("api/MP/GetInventoryEntitylist", {
        entityName: entityName,
        locationname: locationname,
        accountname: accountname,
        rulevalue: rulevalue,
        searchdate: startdate.toString(),
        searchenddate: enddate,
        supplierkey: supplierkeyval, productgrp: prodgrp,
        pricemax: pricemax1,
        pricemin: pricemin1,
        mfg: manufacture,
        Validity: validity,
        index: page,
        recordPerPage: size
    }).then(res => {
        callback(res.data)
    })
        .catch(err => console.log(err))
}

export const GetMPEntityDetailsById = async (id: number, sku: string, entityTypeId: number, SupplierKey: string, prodgrp: string, pricemax1: string, pricemin1: string, manufacture: string, page: number, size: number, callback: any) => {
    await axios.post(`api/MP/GetMPEntityDetailsById`, {
        id: id,
        SKU: sku,
        supplierid: SupplierKey,
        productgrp: prodgrp,
        pricemax: pricemax1,
        pricemin: pricemin1,
        mfg: manufacture,
        entityTypeId: entityTypeId,
        index: page,
        recordPerPage: size
    }).then(res => {
        callback(res.data)

    }).catch(err => console.log(err))
}

export const SaveWebhook = async (entityName: string, ruleValue: string, entityId: number, accountname: string, callback: any) => {
    await axios.post(`api/MP/SaveWebhook`, {
        EntityId: entityId.toString(),
        EntityName: entityName,
        RuleValue: ruleValue,
        accountname: accountname
    }).then(res => {
        callback(res.data)

    }).catch(err => console.log(err))
}
export const SaveEntityDetailsById = async (entityList: any, entities: any, mimimumQty: string, typeValue: string, ruleValue: string, inType: string, ruleid: number, fixedInv: string, IsallMP: boolean, IsMarkdown: boolean, ismporloc: boolean, utcstartdate: string, utcenddate: string, ischeckall: boolean, filterobj: any, callback: any) => {

    await axios.post(`api/MP/SaveEntityDetailsById`, {
        Entitylist: entityList,
        entities: entities,
        MimimumQty: mimimumQty,
        TypeValue: typeValue,
        RuleValue: ruleValue,
        InType: inType,
        Ruleid: ruleid,
        Fixedinv: fixedInv,
        IsALLMP: IsallMP,
        IsMarkDown: IsMarkdown,
        ismporloc: ismporloc,
        utcstartdate: utcstartdate,
        utcenddate: utcenddate,
        ischeckall: ischeckall,
        filterobj: filterobj
    }).then(res => {
        callback(res.data)

    }).catch(err => console.log(err))
}

export const GetWebhookList = async (page: number, size: number, callback: any) => {
    await axios.post("api/MP/GetWebhookList", {
        index: page,
        recordPerPage: size
    }).then(res => {
        callback(res.data)
    })
        .catch(err => console.log(err))
}
export const DeleteWH = async (id: number, callback: any) => {
    await axios.post("api/MP/DeleteWH?id=" + id)
        .then(res => {
            callback(res.data)
        })
        .catch(err => console.log(err))
}
export const GetAllMarketplaces = async (callback: any) => {
    await axios.post("api/MP/GetAllMarketplaces")
        .then(res => {
            callback(res.data)
        })
        .catch(err => console.log(err))
}

export const GetMarketplaceAccountNumberForRelationshipType = async (type: string, callback: any) => {
    await axios.post("api/MP/GetMarketplaceAccountNumberForRelationshipType?type=" + type)
        .then(res => {
            callback(res.data)
        })
        .catch(err => console.log(err))
}

export const GetMpNfCustomerList = async (maid: string, callback: any) => {
    await axios.post("api/MP/GetMpNfCustomerList?maid=" + maid)
        .then(res => {
            callback(res.data)
        })
        .catch(err => console.log(err))
}


export const GetMarketplaceAccountNumbers = async (callback: any) => {
    await axios.post("api/MP/GetMarketplaceAccountNumbers")
        .then(res => {
            callback(res.data)
        })
        .catch(err => console.log(err))
}


export const GetMPJobList = async (page: number, size: number, accountid: string, callback: any) => {
    await axios.post("api/MP/GetMPJobList", {
        index: page,
        recordPerPage: size,
        accountid: accountid
    }).then(res => {
        callback(res.data)

    })
        .catch(err => console.log(err))
}

export const GetMPTaskDetailsbyID = (entityTypeId: number, jobId: number, page: number, size: number, statusId: number, transactionId: string, locationId: number, callback: any) => {
    if (jobId != null) {
        axios.post(`api/MP/GetMPTaskDetailsbyID`, {
            entityTypeId: entityTypeId,
            Id: jobId,
            index: page,
            recordPerPage: size,
            statusId: statusId,
            transactionId: transactionId,
            locationId: locationId
        }).then(result => {
            return callback(result.data);
        }).catch(err => {
            console.log(err)
        })
    }
}
export const GetagilityJobList = async (page: number, size: number, callback: any) => {
    await axios.post("api/MP/GetagilityJobList", {
        index: page,
        recordPerPage: size
    }).then(res => {
        callback(res.data)

    })
        .catch(err => console.log(err))
}

export const GetagilityTaskDetailsbyID = (jobId: number, page: number, size: number, statusId: number, locationname: string, callback: any) => {
    if (jobId != null) {
        axios.post(`api/MP/GetagilityTaskDetailsbyID`, {
            Id: jobId,
            index: page,
            recordPerPage: size,
            statusId: statusId,
            locationname: locationname
        }).then(result => {
            return callback(result.data);
        }).catch(err => {
            console.log(err)
        })
    }
}
export const GetMPLatestSyncDate = async (callback: any) => {
    await axios.get("api/MP/GetMPLatestSyncDate").then(result => {
        callback(result.data)
    }).catch(err => {
        console.log(err)
    })
}
export const SyncMPDate = async (jobType: number, deltaDate: string, selectedTime: string, storeId: string, callback: any) => {
    await axios.post("api/MP/SyncMPDate", {
        jobType: jobType,
        deltaDate: deltaDate,
        deltaTime: selectedTime,
        storeId: Number(storeId)
    }).then(result => {
        callback(result.data)
    }).catch(err => {
        console.log(err)
    })
}
export const GetmpLocationsANDagilityBranch = async (id: number, callback: any) => {
    await axios.post("api/MP/GetmpLocationsANDagilityBranch?id=" + id)
        .then(res => {
            callback(res.data)
        })
        .catch(err => console.log(err))
}
export const GetMpLoad = async (accountid: string, page: number, size: number, callback: any) => {
    await axios.post("api/MP/GetMpLoad", {
        accountid: accountid,
        index: page,
        recordPerPage: size
    }).then(res => {
        callback(res.data)
    })
        .catch(err => console.log(err))
}
export const Inactivempproduct = async (index: number, IsActive: boolean, callback: any) => {
    await axios.post("api/MP/Inactiveproduct", {
        index: index,
        IsActive: IsActive
    })
        .then(res => {
            callback(res.data)
        })
        .catch(err => console.log(err))
}
export const SaveAndUpdateMPScheduleSync = async (sync: scheduledSync, callback: any) => {
    await axios.post("api/MP/SaveAndUpdateMPSync", {
        id: sync.id,
        phaseid: sync.phaseid,
        nextsyncdate: sync.nextsyncdate,
        ismagentoSync: sync.ismagentoSync,
        days: sync.days,
        month: sync.month,
        name: "",
        time: sync.time,
        isactive: sync.isActive,
        minutes: sync.minutes,
        hours: sync.hours
    }).then(res => {
        callback(res.data)
    })
        .catch(err => console.log(err))
}
export const GetmpscheduledSync = (ismagentoApi: boolean, callback: any) => {
    axios.get(`api/MP/GetmpscheduledSync`).then(result => {
        return callback(result.data);
    }).catch(err => {
        console.log(err)
    })

}

export const SaveWarehouseSettings = async (info: moduleinfo, callback: any) => {
    await axios.post("api/MP/SaveWarehouseMapping", info)
        .then(res => {
            callback(res.data)
        })
        .catch(err => console.log(err))
}
export const GetMpSyncPauseval = (id: number, callback: any) => {
    axios.get(`api/MP/MpSyncPauseval?id=${id}`).then(result => {
        return callback(result.data);
    }).catch(err => {
        console.log(err)
    })

}

export const PauseMPSync = async (callback: any) => {
    await axios.get("api/MP/MPPauseSync").then(result => {
        callback(result.data)
    }).catch(err => {
        console.log(err)
    })
}
export const UpdateActiveProduct = async (accountid: string, sku: string, location: number, branch: number, activestatus: string, isselectall: boolean, page: number, size: number, callback: any) => {
    await axios.post("api/MP/UpdateActiveProduct", {
        sku: sku,
        location: location.toString(),
        branch: branch.toString(),
        accountid: accountid.toString(),
        activestatus: activestatus,
        isselectall: isselectall,
        index: page,
        recordPerPage: size
    }).then(res => {
        callback(res.data)
    })
        .catch(err => console.log(err))
}

export const UpdateActiveProductsSelectAll = async (accountid: string, activestatus: string, isselectall: boolean, filterobjs: any, page: number, size: number, callback: any) => {
    await axios.post("api/MP/UpdateActiveProduct", {

        accountid: accountid.toString(),
        activestatus: activestatus,
        isselectall: isselectall,
        filterobj: filterobjs,
        index: page,
        recordPerPage: size
    }).then(res => {
        callback(res.data)
    })
        .catch(err => console.log(err))
}


export const SaveUOMSettings = async (info: moduleinfo, callback: any) => {
    await axios.post("api/MP/SaveUOMSettings", info)
        .then(res => {
            callback(res.data)
        })
        .catch(err => console.log(err))
}

export const GetUOMLoad = async (accountid: string, page: number, size: number, callback: any) => {
    await axios.post("api/MP/GetUOMLoad", {
        accountid: accountid,
        index: page,
        recordPerPage: size
    }).then(res => {
        callback(res.data)
    })
        .catch(err => console.log(err))
}
export const GetNFInventoryData = async (page: number, size: number, callback: any) => {
    await axios.post("api/MP/GetNFInventoryData", {
        index: page,
        recordPerPage: size
    }).then(res => {
        callback(res.data)
    })
        .catch(err => console.log(err))
}

export const DeleteUOM = async (id: number, callback: any) => {
    await axios.post("api/MP/DeleteUOM?id=" + id)
        .then(res => {
            callback(res.data)
        })
        .catch(err => console.log(err))
}

export const GetAProductsLoad = async (accountid: string, sku: string, location: number, branch: number, activestatus: string, supplierkey: string, prodgrp: string, pricemax1: string, pricemin1: string, manufacture: string, page: number, size: number, callback: any) => {
    await axios.post("api/MP/GetAProductsLoad", {
        sku: sku,
        location: location.toString(),
        branch: branch.toString(),
        accountid: accountid.toString(),
        activestatus: activestatus,
        productgrp: prodgrp,
        pricemax: pricemax1,
        pricemin: pricemin1,
        mfg: manufacture,
        supplierid: supplierkey,
        index: page,
        recordPerPage: size
    }).then(res => {
        callback(res.data)
    })
        .catch(err => console.log(err))
}

export const GetstatusandtransbyID = async (info: moduleinfo, callback: any) => {
    await axios.post("api/MP/GetstatusandtransbyID", info)
        .then(res => {
            callback(res.data)
        })
        .catch(err => console.log(err))
}
export const GetAgilityLatestSyncDate = async (callback: any) => {
    await axios.get("api/MP/GetAgilityLatestSyncDate").then(result => {
        callback(result.data)
    }).catch(err => {
        console.log(err)
    })
}
export const AgilitySyncDate = async (jobType: number, deltaDate: string, selectedTime: string, storeId: string, callback: any) => {
    await axios.post("api/MP/AgilitySyncDate", {
        jobType: jobType,
        deltaDate: deltaDate,
        deltaTime: selectedTime,
        storeId: Number(storeId)
    }).then(result => {
        callback(result.data)
    }).catch(err => {
        console.log(err)
    })
}
export const GetAgilityscheduledSync = (ismagentoApi: boolean, callback: any) => {
    axios.get(`api/MP/GetAgilityscheduledSync`).then(result => {
        return callback(result.data);
    }).catch(err => {
        console.log(err)
    })

}
export const AgilitySaveAndUpdateSync = async (sync: scheduledSync, callback: any) => {
    await axios.post("api/MP/AgilitySaveAndUpdateSync", {
        id: sync.id,
        phaseid: sync.phaseid,
        nextsyncdate: sync.nextsyncdate,
        ismagentoSync: sync.ismagentoSync,
        days: sync.days,
        month: sync.month,
        name: "",
        time: sync.time,
        isactive: sync.isActive,
        minutes: sync.minutes,
        hours: sync.hours
    }).then(res => {
        callback(res.data)
    })
        .catch(err => console.log(err))
}
export const PauseagilitySync = async (callback: any) => {
    await axios.get("api/MP/PauseagilitySync").then(result => {
        callback(result.data)
    }).catch(err => {
        console.log(err)
    })
}
export const GetAgilitySyncPauseval = (id: number, callback: any) => {
    axios.get(`api/MP/GetAgilitySyncPauseval?id=${id}`).then(result => {
        return callback(result.data);
    }).catch(err => {
        console.log(err)
    })

}
export const GetAllStatus = async (callback: any) => {
    await axios.post("api/MP/GetAllStatus")
        .then(res => {
            callback(res.data)
        })
        .catch(err => console.log(err))
}

export const GetstatusandBranchbyAJID = async (info: moduleinfo, callback: any) => {
    await axios.post("api/MP/GetstatusandBranchbyAJID", info)
        .then(res => {
            callback(res.data)
        })
        .catch(err => console.log(err))
}
export const CancellMPJobandTaskService = (id: number, callback: any) => {
    if (id != null) {
        axios.get(`api/MP/CancellMPJobandTaskService?jobId=${id}`).then(result => {
            return callback(result.data);
        }).catch(err => {
            console.log(err)
        })
    }
}
export const CancellAgilityJobandTaskService = (id: number, callback: any) => {
    if (id != null) {
        axios.get(`api/MP/CancellAgilityJobandTaskService?jobId=${id}`).then(result => {
            return callback(result.data);
        }).catch(err => {
            console.log(err)
        })
    }
}
export const FailedMPjobRetry = (id: number, callback: any) => {
    if (id != null) {
        axios.get(`api/MP/FailedMPjobRetry?jobId=${id}`).then(result => {
            return callback(result.data);
        }).catch(err => {
            console.log(err)
        })
    }
}
export const FailedAgilityjobRetry = (id: number, callback: any) => {
    if (id != null) {
        axios.get(`api/MP/FailedAgilityjobRetry?jobId=${id}`).then(result => {
            return callback(result.data);
        }).catch(err => {
            console.log(err)
        })
    }
}
export const GetNewApiKeybyUserID = async (id: number, callback: any) => {
    await axios.get("api/MP/GetNewApiKeybyUserID?userId=" + id)
        .then(res => {
            callback(res.data)
        })
        .catch(err => console.log(err))
}
export const GetuserbyAdmin = async (callback: any) => {
    await axios.get("api/MP/GetuserbyAdmin")
        .then(res => {
            callback(res.data)
        })
        .catch(err => console.log(err))
}
export const GetSuppliers = async (callback: any) => {
    await axios.get("api/MP/GetSuppliers").then(result => {
        callback(result.data)
    }).catch(err => {
        console.log(err)
    })
}

export const Getrolepermissionbyuserid = async (id: number, callback: any) => {
    await axios.post("api/MP/Getrolepermissionbyuserid?permissionid=" + id)
        .then(res => {
            callback(res.data)
        })
        .catch(err => console.log(err))
}
export const DownloadOrderLists = async (orderlistingAPIfun: string, page: number, customerCode: any, warehouse: any, customerOrderId: any, transactionDate: any, statusCode: any, token: any, callback: any) => {
    await axios.post("api/MP/DownloadOrderLists", {
        orderlistingAPIfun: orderlistingAPIfun,
        pageNum: page,
        customerCode: customerCode,
        wareHouseId: warehouse,
        customerOrderId: customerOrderId,
        DateRange: transactionDate,
        OrderStatusCode: statusCode,
        token: token


    })
        .then(res => {
            callback(res.data)
        })
        .catch(err => console.log(err))
}

// export const GetFilters = async (id: number, callback: any) => {
//     await axios.get("api/MP/GetFilters?moduleid=" + id)
//         .then(res => {
//             callback(res.data)
//         })
//         .catch(err => console.log(err))
// }
export const GetFilters = async (id: number, callback: any) => {
    await axios.get("api/MP/GetAllFilters?moduleid=" + id)
        .then(res => {
            callback(res.data)
        })
        .catch(err => console.log(err))
}

export const GetFilterDatabyModuleIdandFilterId = async (moduld: number, filterId: number, callback: any) => {
    await axios.post(`api/MP/GetFilterDatabyModuleIdandFilterId?moduleid=${moduld}&filterid=${filterId}`).then(res => {
        callback(res.data)
    })
        .catch(err => console.log(err))
}
export const GetMPProductsByFilters = async (id: number, callback: any) => {
    await axios.get("api/MP/GetMPProductsByFilters?moduleid=" + id)
        .then(res => {
            callback(res.data)
        })
        .catch(err => console.log(err))
}

export const GetFiltersjson = async (id: number, callback: any) => {
    await axios.get("api/MP/GetFiltersjson?moduleid=" + id)
        .then(res => {
            callback(res.data)
        })
        .catch(err => console.log(err))
}

export const SaveEntitybyAPID = async (accountid: string, eid: number, sku: string, uom: string, desc: string, mskuval: string, asinval: string, touomval: string, brandval: string, pvgroup: string, pvmax: string, pvmin: string, pvmfg: string, callback: any) => {
    await axios.post("api/MP/SaveEntitybyAPID", {
        id: eid,
        sku: sku,
        uom: uom,
        displayDescription: desc,
        accountid: accountid.toString(),
        msku: mskuval,
        asin: asinval,
        touom: touomval,
        brand: brandval,
        productgrp: pvgroup,
        pricemax: pvmax,
        pricemin: pvmin,
        mfg: pvmfg
    }).then(res => {
        callback(res.data)
    })
        .catch(err => console.log(err))
}

export const GetProductsLoadbyFilter = async (accountid: string, filterobjs: any, page: number, size: number, callback: any) => {
    await axios.post("api/MP/GetProductsLoadbyFilter", {
        accountid: accountid,
        filterobj: filterobjs,
        index: page,
        recordPerPage: size
    }).then(res => {
        callback(res.data)
    })
        .catch(err => console.log(err))
}
export const GetAProductsLoadByFilters = async (accountid: string, filterobjs: any, page: number, size: number, callback: any) => {
    await axios.post("api/MP/GetAProductsLoadByFilters", {
        accountid: accountid.toString(),
        filterobj: filterobjs,
        index: page,
        recordPerPage: size
    }).then(res => {
        callback(res.data)
    })
        .catch(err => console.log(err))
}

export const GetInventoryLoadbyFilter = async (filterobjs: any, page: number, size: number, callback: any) => {
    await axios.post("api/MP/GetInventoryLoadbyFilter", {
        accountid: 0,
        filterobj: filterobjs,
        index: page,
        recordPerPage: size
    }).then(res => {
        callback(res.data)
    })
        .catch(err => console.log(err))
}

export const GetMPEntityDetailsByFIlter = async (filterobjs: any, page: number, size: number, ischangefilter: boolean, callback: any) => {
    await axios.post("api/MP/GetMPEntityDetailsByFilter", {
        filterobj: filterobjs,
        index: page,
        recordPerPage: size,
        ischangefilter: ischangefilter
    }).then(res => {
        callback(res.data)
    })
        .catch(err => console.log(err))
}
export const GetProductsLoadbyRules = async (accountid: string, filterobjs: any, page: number, size: number, filterentities: any, ischangefilter: boolean, callback: any) => {


    await axios.post("api/MP/GetProductsLoadbyRules", {
        accountid: accountid,
        filterobj: filterobjs,
        index: page,
        recordPerPage: size,
        // filterentities: filterentities,
        ischangefilter: ischangefilter
    }).then(res => {
        callback(res.data)
    })
        .catch(err => console.log(err))
}
export const SaveProductRules = async (ruleTypeId: number, fulfillmentId: number, accountid: string, filterobjs: any, rtype: number, callback: any) => {
    await axios.post("api/MP/SaveProductRules", {
        ruleTypeId: ruleTypeId,
        fulfillmentId: fulfillmentId,
        accountid: accountid,
        filterobj: filterobjs,
        rtype: rtype
    }).then(res => {
        callback(res.data)
    })
        .catch(err => console.log(err))
}
// export const SaveProductRulesNF = async (accountid: string, filterobjs: any, rtype: number,fulfillmentId:number, callback: any) => {
//     await axios.post("api/MP/SaveProductRulesNF").then(res => {
//         callback(res.data)
//     })
//         .catch(err => console.log(err))
// }

export const SaveProductRulesNF = async (customerId: string, filterobjs: any, rtype: number, fulfillmentId: number, callback: any) => {
    await axios.post("api/MP/SaveProductRulesNF", {

        customerId: customerId,
        filterobj: filterobjs,
        rtype: rtype,
        fulfillmentId: fulfillmentId.toString(),

    }).then(res => {
        callback(res.data)
    })
        .catch(err => console.log(err))
}

export const GetProductRulesLoad = async (page: number, size: number, rtype: string, fulfillmentId: string, rcustomers: string, accountnumber: string, callback: any) => {
    await axios.post("api/MP/GetProductRulesLoad", {
        index: page,
        recordPerPage: size,
        rtype: rtype,
        fulfillmentId: fulfillmentId,
        rcustomers: rcustomers,
        accountnumber: accountnumber
    }).then(res => {
        callback(res.data)
    })
        .catch(err => console.log(err))
}
export const GetProductRulesLoadNF = async (rtype: string, fulfillmentId: string, accountnumber: number, size: number, callback: any) => {
    await axios.post("api/MP/GetProductRulesLoadNF", {
        rtype: rtype,
        fulfillmentId: fulfillmentId,
        accountnumber: accountnumber.toString(),
        recordPerPage: size,

    }).then(res => {
        callback(res.data)
    })
        .catch(err => console.log(err))
}

export const GetAllCustomerGroup = async (callback: any) => {
    await axios.post("api/MP/GetAllCustomerGroup")
        .then(res => {
            callback(res.data)
        })
        .catch(err => console.log(err))
}


export const GetAllMarketplacebyAccountId = async (id: number, callback: any) => {
    await axios.post("api/MP/GetAllMarketplacebyAccountId?gid=" + id)
        .then(res => {
            callback(res.data)
        })
        .catch(err => console.log(err))
}
export const GetAllcustomersbyCusGroupId = async (id: number, callback: any) => {
    await axios.post("api/MP/GetAllcustomersbyCusGroupId?gid=" + id)
        .then(res => {
            callback(res.data)
        })
        .catch(err => console.log(err))
}
export const GetAllRuleType = async (cType: string, callback: any) => {
    await axios.post("api/MP/GetAllRuleTypeNF?cType=" + cType)
        .then(res => {
            callback(res.data)
        })
        .catch(err => console.log(err))
}
export const GetFilfillmentTypebyRuleType = async (id: number, callback: any) => {
    await axios.post("api/MP/GetFulfillmentTypeByRuleTypeId?typeId=" + id)
        .then(res => {
            callback(res.data)
        })
        .catch(err => console.log(err))
}
export const GetValuesFromMpProducts = async (id: number, callback: any) => {
    await axios.post("api/MP/GetValuesFromMpProducts?typeid=" + id)
        .then(res => {
            callback(res.data)
        })
        .catch(err => console.log(err))
}

export const SaveNFinventoryById = async (reservedQuantity: number, value: string, callback: any) => {

    await axios.post(`api/MP/SaveNFinventoryData`, {
        reservedQuantity: reservedQuantity,
        value: value
        // utcstartdate: utcstartdate,
        // utcenddate: utcenddate
    }).then(res => {
        callback(res.data)

    }).catch(err => console.log(err))
}
export const GetNFProductsLoadbyFilter = async (accountid: string, filterobjs: any, page: number, size: number, callback: any) => {
    await axios.post("api/MP/GetNFProductsLoadbyFilter", {
        accountid: accountid,
        filterobj: filterobjs,
        index: page,
        recordPerPage: size
    }).then(res => {
        callback(res.data)
    })
        .catch(err => console.log(err))
}
export const GetRefreshedApiKey = async (callback: any) => {
    await axios.get("api/MP/GetRefreshedApiKey").then(result => {
        callback(result.request.responseText)
    }).catch(err => {
        console.log(err)
    })
}
export const SaveNfCustomerDetailsById = async (id: number, apikey: string, properties: any, callback: any) => {

    await axios.post(`api/MP/SaveNfCustomerDetailsById`, {
        id: id,
        apikey: apikey,
        properties: properties
    }).then(res => {
        callback(res.data)

    }).catch(err => console.log(err));
}

export const AddNfCustomer=async(maId:number,accId:string,custName:string,apiKey:string,callback:any)=>{
    await axios.post(`api/MP/AddNfCustomer`,{
        maid:maId,
        accountid:accId,
        customername:custName,
        apikey:apiKey
    }).then(res=>{
        callback(res.data)

    }).catch(err => console.log(err));
}

//export const GetNFInventoryData = async ( page: number, size: number, callback: any) => {
//    await axios.post("api/MP/GetNFInventoryData", {
//        index: page,
//        recordPerPage: size
//    }).then(res => {
//        callback(res.data)
//    })
//        .catch(err => console.log(err))
//}
//export const GetMPEntityDetailsById = async (entityDetails: jobDetails, entityType: number, callback: any) => {

//    await axios.post("api/MP/GetMPEntityDetailsById", {
//        id:

//    }).then(result => {
//            return callback(result.data);
//        }).catch(err => {
//            console.log(err)
//        })
// }
